import { styled } from '@mui/material/styles';
import { Button, useTheme } from '@mui/material';
import { tokens } from "../theme";
  
const ColorButton = styled(Button)(() => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return ({
    m:"0 auto",
    p:"5px",
    display:"flex",
    justifyContent:"center",
    backgroundColor: colors.greenAccent[600],
    borderRadius: "4px",
    width: "100%",
    '&:hover': {
      backgroundColor: colors.greenAccent[600],
    },
  })
}
  );

  export default ColorButton;