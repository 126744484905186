import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  useTheme
} from "@mui/material";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
import ProtocolDetails from "../protocolMap/protocolDetails";
import { useUserStore } from "../../stores/userStore";
import getProtocolInfo from "../../Api/Protocols/getProtocolInfo";
import getListProtocols from "../../Api/Protocols/getListProtocols";
import setIsValidProtocols from "../../Api/Protocols/setIsValidProtocols";
import { dataGridStyles } from "../../styleGrid";
import { tokens } from "../../theme";
import { formatDate } from "../../data/Utils/timeZones";
import { useNavigate } from "react-router-dom";
import getInfoUser from "../../Api/Users/getInfoUser";
//import DataGridFilterCustom from "../../components/DataGridFilterCustom";

const invalidProtocols = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [protocols, setProtocols] = useState([]);
  const [selectedProtocol, setSelectedProtocol] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [force, setForce] = useState(true);
  const token = useUserStore((state) => state.token);
  const [page, setPage] = useState(0);
  //const [filteredRows, setFilteredRows] = useState([]);
  let totalPages = 100000;

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "ElectionName",
      headerName: "Название выборов",
      width: 250,
      cellClassName: "name-column--cell",
    },
    {
      field: "typeElection",
      headerName: "Тип выборов",
      width: 120,
      cellClassName: "name-column--cell",
    },
    {
      field: "date",
      headerName: "Дата отправки",
      width: 150,
      renderCell: (params) => (
        formatDate(params.row.date)
      ),
    },
    {
      field: "sender",
      headerName: "Отправитель",
      width: 250,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleClick(params.row.user_id)}>
          {params.row.sender}
        </div>
      )
    },
    {
      field: "protocolType",
      headerName: "Тип протокола",
      width: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "protocolFROM",
      headerName: "ИК",
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "view",
      headerName: "Действия",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            style={{ color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
            size="small"
            onClick={() => handleViewProtocol(params.row)}
          >
            Посмотреть
          </Button>

          <Button
            variant="outlined"
            style={{marginLeft: "10px", color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
            size="small"
            onClick={() => handleMarkValid(params.row)}
          >
            Признать достоверным
          </Button>
        </>
      ),
    },
  ];

  const getDataProtocols = (newPage = page) => {
    getListProtocols({
      "user_initiator":{
          "usr_token":token
      },
      "protocol": {
        "is_valid": false
      },
      "page": newPage
  }).then((response) => {
    if(response.success){
      setProtocols(response.data.protocols);
    } else {
      setProtocols([])
      alert(response.message)
    }
    setForce(false);
    setPage(newPage)
  });
  }

  useEffect(() => {
    getDataProtocols();
    const interval = setInterval(getDataProtocols, 60 * 1000); 
    return () => clearInterval(interval);
  }, [force]);


  const handleViewProtocol = async (protocol) => {
    await getProtocolInfo({
      "user_initiator":{
        "usr_token":token
      },
      "protocol":{
        "protocol_id":protocol.id
       }
    }).then((response)=>{
      if (response.success){
        
        const schemaParsed = JSON.parse(response.data.protocol.data);

        const dataToView = {
            ...response.data.protocol,
            election_data: {
              ...response.data.protocol.election_data,
              protocol_schema: {id: protocol.election_id,...schemaParsed },
            },
        }
        setSelectedProtocol(dataToView);
        setDialogOpen(true);
      } else {
        alert(response.message)
      }
    })
  };

  const handleMarkValid = (protocol) => {

    setIsValidProtocols({
       "user_initiator":{
         "usr_token":token
       },
       "protocol":{
          "protocol_id":protocol.protocol_id,
          "is_valid": true
       }
     }).then((response) => {
       if(response.success){
          setForce(true)
       } else {
          alert(response.message)
       }
     }).catch((error) => {
           // Обработка ошибки при удалении протокола
            console.error("Ошибка при назначении протокола:", error);
            alert(error.message)
        });
   };

    // ФУНКЦИОНАЛ ДЛЯ ПЕРЕХОДА К ПОЛЬЗОВАТЕЛЮ В ПРОФИЛЬ:

 const handleClick = async (userId) => {
  try {
      // Ваша логика обработки клика
      const resp = await getUserInfoFromId(userId);
      navigate('/me', { state: { userData: resp , nav: 'any'} });
  } catch (error) {
      alert(error);
  }
 };

 //получаем id информацию по id пользователя
 const getUserInfoFromId = async (id) => {
  let payload = {
      "user_initiator": {
          "usr_token": token
      },
      "user":{
          "user_id": id
      }
  };

  // Возвращаем результат выполнения асинхронного запроса
  return getInfoUser(payload).then((resp) => {
      if (resp.success) {
          return resp.data.user; // Возвращаем значение из .then()
      } else {
          throw new Error(resp.message); // Вызываем ошибку, чтобы обработать ее в handleClick
      }
  });
};


  const dialogProtocol = () => {
    return (
    <>
    <Dialog
    open={dialogOpen}
    onClose={() => setDialogOpen(false)}
    maxWidth="lg"
    fullWidth
  >
    <DialogTitle>Детали протокола</DialogTitle>
    <DialogContent>
      {selectedProtocol && (
        <ProtocolDetails protocol={selectedProtocol} />
      )}
    </DialogContent>
  </Dialog>
  </>
    );
  }

  const rows = protocols.map(protocols => {
    return {
      id: protocols.protocol_id,
      ElectionName: protocols.election_data.election_name,
      typeElection: protocols.election_data.election_type,
      date: protocols.created_at,
      sender: protocols.user_data.usr_name,
      protocolType: protocols.is_dupe ? "Повторный" : "Первичный",
      protocolFROM: protocols.ik_data.name,
      reason: protocols.dupe_reason ? protocols.dupe_reason : "",
      ...protocols
    };
  });

  const pageChange = (newPage) => {
    getDataProtocols(newPage)
  }

  // const filteredColumns = (columnsToFilter) => {
  //   // Исключаем колонки access_id и control
  //   return columnsToFilter.filter(column => column.field !== "access_id" && column.field !== "view");
  // };

  return (
        <Box m="20px">
          <Header title="Просмотр присланных протоколов" />
          <Box sx={{mt: 2}}>

            {/* <DataGridFilterCustom columns={filteredColumns(columns)} rows={rows} setFilteredRows={setFilteredRows} /> */}

          </Box>
          <Box
            m="20px 0 0 0"
            height="80vh"
            sx={dataGridStyles}
          >
            {dialogProtocol()}
            <DataGrid
              rows={rows}
              columns={columns}
              localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
              pageSize={100}
              paginationMode={'server'}
              rowCount={totalPages}
              onPageChange={pageChange}
            />
          </Box>
        </Box>
  );
};

export default invalidProtocols;