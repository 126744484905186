//import { useState } from 'react';
import { 
  //Tabs, 
  //Tab, 
  Box, 
  //Typography 
} from '@mui/material';
//import StatVotes from './statCharts/StatsVotes';
//import DashCardHeader from './statCharts/DashCardHeader';
//import StatViolations from './statCharts/StatsViolations';

const StatisticsPage = () => {
  // const [selectedTab, setSelectedTab] = useState(0);

  // const handleTabChange = (event, newValue) => {
  //   setSelectedTab(newValue);
  // };

  return (
    <Box sx={{ position: 'relative', backgroundColor: 'white', margin: 5, justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', width:'100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        {/* <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="transparent" sx={{
          color: 'black',
          backgroundColor: 'white',
          boxShadow: "rgb(145 158 171 / 50%) 0px 0px 2px 0px, rgb(145 158 171 / 50%) 0px 16px 32px -4px",
          borderRadius: 10,
          width: '40em', // изменено на 100%
          mt: 5
        }}>
          <Tab style={{ color: selectedTab === 0 ? 'white' : 'black' }} label="Статистика" sx={{
            fontSize: 14,
            fontWeight: '600',
            backgroundColor: selectedTab === 0 ? 'red' : 'white',
            minWidth: 0, // Установка минимальной ширины вкладки
            flexGrow: 1
          }} />
          <Tab style={{ color: selectedTab === 1 ? 'white' : 'black' }} label="Карта нарушений" sx={{
            fontSize: 14,
            fontWeight: '600',
            backgroundColor: selectedTab === 1 ? 'red' : 'white',
            minWidth: 0, // Установка минимальной ширины вкладки
            flexGrow: 1
          }} />
        </Tabs> */}
      </Box>
      {/* {selectedTab === 0 && <StatVotes selectedTab={selectedTab} />}
      {selectedTab === 1 && <StatViolations selectedTab={selectedTab}/>} */}
    </Box>
  );
};

export default StatisticsPage;