import { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import PropTypes from "prop-types";
import getElections from "../../../Api/Elections/getElections";
import getCantidates from "../../../Api/Candidates/getCandidates";
//import getPfields from "../../../Api/Protocols/getPfields";
import enumPfields from "../../../Api/Pfields/enumPfields";

//TODO: стрингой пишется Number в базу
const EditProtocolForm = ({ protocolData, onSave }) => {
  EditProtocolForm.propTypes = {
    protocolData: PropTypes.any,
    onSave: PropTypes.func,
  };
  const [editedProtocol, setEditedProtocol] = useState(protocolData);
  const [elections, setElections] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [pfields, setPfields] = useState([]);

  const [electionHome, setElectionHome] = useState(null);

  console.log('REPLANZ TEST TEST', editedProtocol)

  const getDataElections = async () => {
    await getElections({}).then((response) => {
      if(response.success){
        setElections(response.data.elections);
        setElectionHome(response.data.elections.find(
          (election) => election.election_id === protocolData.election_id
        )?.home_ik)
      } else {
        setElections([]);
        alert(response.message)
      }
    });
  }

  const getDataCandidates = async () => {

    let payload = {
      "ik":{
        "ik_id": electionHome
      }
    }

   await getCantidates(payload).then((response) => {
      if(response.success){
        setCandidates(response.data.candidates);
      } else {
        setCandidates([]);
        alert(response.message)
      }
    });
  }

  const getDataPfields = async () => {
    let payload ={
      "pfield": {
        "type_id": protocolData.protocol_type,
        "election_system": protocolData.election_system,
        "ik_id": protocolData.election_home,
      }
    }
    await enumPfields(payload).then((response) => {
      if(response.success){
        setPfields(response.data.pfields);
      } else {
        setPfields([]);
        alert(response.message)
      }
    });
  }

  useEffect(() => {
    getDataElections();
  }, []);

  useEffect(() => {
    if(electionHome){
      getDataCandidates();
    }
  }, [electionHome]);

  useEffect(()=>{
   if (protocolData.protocol_type){
    getDataPfields(protocolData.protocol_type)
  }

  },[protocolData.protocol_type])

  const handleInputChange = (event, field) => {
    const { value } = event.target;
    setEditedProtocol((prevProtocol) => ({
      ...prevProtocol,
      [field]: value,
    }));
  };

  const handleUpperPartFieldChange = (event, fieldIndex, subField, title) => {
    const { value } = event.target;
    setEditedProtocol((prevProtocol) => {
        const updatedUpperPart = [...prevProtocol.upperPartFields];
        updatedUpperPart[fieldIndex] = {
            ...updatedUpperPart[fieldIndex],
            [subField]: value,
            ...(title && { Title: title }) // Conditional inclusion of Title
        };
        return {
            ...prevProtocol,
            upperPartFields: updatedUpperPart,
        };
    });
};

  const handleLowerPartFieldChange = (event, fieldIndex, subField) => {
    const { value } = event.target;
    setEditedProtocol((prevProtocol) => {
      const updatedLowerPart = [...prevProtocol.lowerPartFields];
      updatedLowerPart[fieldIndex] = {
        ...updatedLowerPart[fieldIndex],
        [subField]: value,
      };
      return {
        ...prevProtocol,
        lowerPartFields: updatedLowerPart,
      };
    });
  };

  const handleSave = () => {
      onSave(editedProtocol)
  };

  return (
    <Paper elevation={3} style={{ padding: "20px" }}>
      <Typography variant="h6">Редактирование протокола</Typography>
      <TextField
        label="Название протокола"
        fullWidth
        value={editedProtocol.protocol_name}
        onChange={(e) => handleInputChange(e, "protocol_name")}
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Выберите выборы</InputLabel>
        <Select
          value={editedProtocol.election_id}
          onChange={(e) => handleInputChange(e, "election_id")}
        >
          {elections.map((election) => (
            <MenuItem key={election.election_id} value={election.election_id}>
              {election.election_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" marginBottom={2}>Верхняя часть протокола:</Typography>
          {editedProtocol.upperPartFields.map((field, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={2}>
                <TextField
                  label={`Номер поля`}
                  fullWidth
                  margin="normal"
                  value={field.Number}
                  onChange={(e) =>
                    handleUpperPartFieldChange(e, index, "Number")
                  }
                />
              </Grid>
              <Grid item xs>
                {/* <TextField
                  label={`Заголовок поля`}
                  fullWidth
                  value={field.Title}
                  onChange={(e) =>
                    handleUpperPartFieldChange(e, index, "Title")
                  }
                /> */}
                 <FormControl fullWidth margin="normal">
              <InputLabel>Название поля</InputLabel>
              <Select
                name="Id"
                value={field.Id || ''}
                onChange={(e) =>
                  handleUpperPartFieldChange(
                    e,
                    index,
                    "Id",
                    pfields.find((pField) => pField.id === e.target.value)?.name || ''
                  )
                }
              >
                {pfields.map((pField) => (
                  <MenuItem key={pField.id} value={pField.id}>
                    {pField.name}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={2}>
              <FormControl fullWidth margin="normal">
                <Button
                  variant="outlined"
                  color="error"
          
                  onClick={() => {
                    const updatedFields = [...editedProtocol.upperPartFields];
            updatedFields.splice(index, 1);
            setEditedProtocol({
              ...editedProtocol,
              upperPartFields: updatedFields,
            });
          }}
                >
                  Удалить поле
                </Button>
                </FormControl>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="outlined"
            color="success"
            style={{marginTop: 10}}
            onClick={() => {
                setEditedProtocol((prevProtocol) => ({
                  ...prevProtocol,
                  upperPartFields: [
                    ...prevProtocol.upperPartFields,
                    { Number: '', Title: '', Type: 'textInput' },
                  ],
                }));
              }}
          >
            Добавить поле
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" marginBottom={2}>Нижняя часть протокола:</Typography>
          {editedProtocol.lowerPartFields.map((field, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={2}>
                <TextField
                  label={`Номер поля`}
                  fullWidth
                  value={field.Number}
                  onChange={(e) =>
                    handleLowerPartFieldChange(e, index, "Number")
                  }
                />
              </Grid>
              <Grid item xs>
                <Select
                  label={"Кандидат"}
                  value={field.candidate_id}
                  onChange={(e) =>
                    handleLowerPartFieldChange(e, index, "candidate_id")
                  }
                >
                  {/* Render the list of candidates */}
                  {candidates.map((candidate) => (
                    <MenuItem key={candidate.candidate_id} value={candidate.candidate_id}>
                      {candidate.candidate_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    const updatedFields = [...editedProtocol.lowerPartFields];
                    updatedFields.splice(index, 1);
                    setEditedProtocol({
                      ...editedProtocol,
                      lowerPartFields: updatedFields,
                    });
                  }}
                >
                  Удалить поле
                </Button>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Button
          variant="outlined"
          color="success"
          style={{marginTop: 10, marginLeft: 15}}
          onClick={() => {
            setEditedProtocol((prevProtocol) => ({
              ...prevProtocol,
              lowerPartFields: [
                ...prevProtocol.lowerPartFields,
                { Number: '', candidate_id: '', Type: 'textInput' },
              ],
            }));
          }}
        >
          Добавить поле
        </Button>
      </Grid>
      <Button style={{marginTop: 20}} variant="contained" color="primary" onClick={handleSave}>
        Сохранить изменения
      </Button>
    </Paper>
  );
};

export default EditProtocolForm;
