import ApiCall from '../Api';


const createResources = async (payload) => {
  const endpoint = 'api/v2/resource/create';
  const res = await ApiCall(endpoint, payload, 'POST')
  if (!res.data.Error && res.status === 200) {
      return { success: true, data: res.data }
  } else if (res.status === 200 && res.data.Error) {
      return {
      success: false,
      data: undefined,
      message: 'Что-то пошло не так: ' + res.data.Message,
      code: res.data.Code
  };
  } else {
    return { success: false, data: undefined, message: res.data.Message, code: res.data.Code }
  }
};
  
  // Экспортируем функцию отправки запроса
  export default createResources;