import { useEffect, useState } from 'react';
import { Box, useTheme, } from '@mui/material';
//import axios from 'axios';
import { tokens } from '../../../theme';
import Header from "../../../components/Header";
import SendProtocol from './sendProtocol';
import getElections from '../../../Api/Elections/getElections';

const ProtocolList = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [protocols, setProtocols] = useState([]);
    const [selectedProtocol, setSelectedProtocol] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getElections({ "election": { "election_type":"Федеральный" ,"home_ik": 15 } }).then((response)=>{
          if(response.success){
            setProtocols(response.data.elections)
          } else {
            setProtocols([])
            alert(response.message)
          }
         })
      }, []);

      const handleSelectProtocol = (protocol) => {
        setSelectedProtocol(protocol);
        setOpen(true);
      };

      const handleProtocolSubmit = (updatedProtocol) => {
        // Логика для отправки обновленного протокола на сервер
        console.log(updatedProtocol);
      };

      const handleClose = () => {
        setOpen(false);
        setSelectedProtocol(null);
      };

  return (
    <>
      {open ? (
       <SendProtocol onClose={handleClose} protocol={selectedProtocol} onSubmit={handleProtocolSubmit} />
      ) : (
    <Box m="20px">
    <Header title="Список доступных протоколов:" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {protocols.map((item) => {
          const protocol = JSON.parse(item.protocol_schema);
          return (
          <li key={protocol.id} onClick={() => handleSelectProtocol(protocol)}>
            {protocol.ProtocolName}
          </li>
          )
          })
      }
      </Box>
    </Box>
    )}
    </>
  );
};

export default ProtocolList;