import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

import { Button, FormControl, TextField, InputLabel, Select, MenuItem } from '@mui/material';

const addStatusesForm = ({ addStatus }) => {
  addStatusesForm.propTypes = {
    addStatus: PropTypes.func
  };
  const [status, setStatus] = React.useState({
    name: '',
    type: ''
  });

  const handleChange = (e) => {
    console.log(e)
    setStatus({ ...status, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addStatus(status);
  };


  return (
    <form onSubmit={handleSubmit}>
      <TextField
            //autoFocus
            margin="dense"
            name="name"
            label="Название статуса"
            value={status.name}
            onChange={handleChange}
            fullWidth
            //variant="standard"
          />
        <FormControl fullWidth margin="normal">
        <InputLabel>Тип статуса</InputLabel>
        <Select name="type" value={status.type} onChange={handleChange}>
        <MenuItem key={1} value={"admin"}>admins </MenuItem>
        <MenuItem key={2} value={"users"}>users </MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
      </FormControl>
      <Button type="submit" variant="contained" color="primary" margin="normal">
        Добавить
      </Button>
    </form>
  );
};

export default addStatusesForm;