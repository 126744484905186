import {
  TextField,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import ShortTextSharpIcon from "@mui/icons-material/ShortTextSharp";
import NotesSharpIcon from "@mui/icons-material/NotesSharp";
import RadioButtonUncheckedSharpIcon from "@mui/icons-material/RadioButtonUncheckedSharp";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { useCallback } from "react";
import ButtonToolTip from "./ButtonToolTip";
import ButtonFormDefault from "./ButtonFormDefault";

const FieldInput = ({
  field,
  blockIndex,
  fieldIndex,
  handleInputChange,
  formData,
  setFormData
}) => {
  FieldInput.propTypes = {
    field: PropTypes.object.isRequired,
    blockIndex: PropTypes.number.isRequired,
    fieldIndex: PropTypes.number.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    setFormData:PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
  };

  const handleFieldTypeChange = useCallback((e, blockIndex, fieldIndex) => {
    const { value } = e.target;
  
    const newBlocks = [...formData.blocks];
    newBlocks[blockIndex].BlockParams[fieldIndex].type = value;
  
    if (value === "QuestionOnlyOne" || value === "QuestionsMore") {
        // Если не содержится тип TextInput или TextInputArea, просто обновляем типы в существующих вариантах
        newBlocks[blockIndex].BlockParams[fieldIndex].variants.forEach(variant => {
          if(variant.type !== "TextInputAreaAny"){
            variant.type = value;
          }
        });

    } else {
      // Очищаем массив variants
      newBlocks[blockIndex].BlockParams[fieldIndex].variants = [];
      // Добавляем новый вариант
      newBlocks[blockIndex].BlockParams[fieldIndex].variants.push({
        title: value === "TextInput" ? 'Краткий ответ' : "Развернутый ответ",
        type: value,
      });
    }
  
    setFormData({ ...formData, blocks: newBlocks });
  },[formData]);

  const removeFieldFromBlock = useCallback((blockIndex, fieldIndex) => {
    const newBlocks = [...formData.blocks];
    newBlocks[blockIndex].BlockParams.splice(fieldIndex, 1);
    setFormData({ ...formData, blocks: newBlocks });
  },[formData]);

  const removeVariantFromField = useCallback((blockIndex, fieldIndex, variantIndex) => {
    const newBlocks = [...formData.blocks];
    newBlocks[blockIndex].BlockParams[fieldIndex].variants.splice(variantIndex, 1);
    setFormData({ ...formData, blocks: newBlocks });
  },[formData]);

  const handleVariantChange = useCallback((value, blockIndex, fieldIndex, variantIndex) => {
    const newBlocks = [...formData.blocks];
    newBlocks[blockIndex].BlockParams[fieldIndex].variants[variantIndex].title = value;
    setFormData({ ...formData, blocks: newBlocks });
  },[formData]);

  const addVariantToField = useCallback((blockIndex, fieldIndex) => {
    const newBlocks = [...formData.blocks];
    const fieldType = newBlocks[blockIndex].BlockParams[fieldIndex].type;
    const hasAnotherVariant = newBlocks[blockIndex].BlockParams[fieldIndex].variants.some(
      variant => variant.title === "Другое"
    );

    if (hasAnotherVariant) {
      newBlocks[blockIndex].BlockParams[fieldIndex].variants.splice(-1, 0, {
        title: "",
        type: fieldType,
      });
    } else {
      newBlocks[blockIndex].BlockParams[fieldIndex].variants.push({
        title: "",
        type: fieldType,
      });
    }

    setFormData({ ...formData, blocks: newBlocks });
  },[formData]);

  const addAnotherVariant = useCallback((blockIndex, fieldIndex) => {
    const newBlocks = [...formData.blocks];
    // Проверяем, есть ли уже вариант "Другое"
    const hasAnotherVariant = newBlocks[blockIndex].BlockParams[fieldIndex].variants.some(
      variant => variant.title === "Другое"
    );
    // Если нет, добавляем вариант "Другое"
    if (!hasAnotherVariant) {
      newBlocks[blockIndex].BlockParams[fieldIndex].variants.push({
        title: 'Другое',
        type: 'TextInputAreaAny', // Устанавливаем тип "TextInputArea" для поля "Другое"
      });
      setFormData({ ...formData, blocks: newBlocks });
    }
  },[formData]);

  return (
    <Box key={fieldIndex} mt={2} sx={{ position: "relative", width: "100%" }}>
      <Box sx={{alignItems: "center",justifyContent: "flex-start",flexDirection: "row", display: "flex", marginLeft: 4,}}>
        <TextField
          label="Вопрос"
          variant="outlined"
          value={field.title}
          name="title"
          onChange={(event) => handleInputChange(event.target.name, event.target.value, blockIndex, fieldIndex)}
          margin="normal"
          multiline
          maxRows={3}
          sx={{ width: "50%" }}
          InputProps={{ style: { fontSize: "14px", fontWeight: "600" } }}
          InputLabelProps={{ style: { fontSize: "14px", fontWeight: "bold" } }}
        />
        <Box sx={{ width: "35%", marginLeft: 1 }}>
          <Select
            value={field.type || ""}
            onChange={(e) => handleFieldTypeChange(e, blockIndex, fieldIndex)}
            sx={{
              alignContent: "center",
              justifyContent: "center",
              width: "100%",
              fontWeight: "600",
              fontSize: "14px !important",
            }}
          >
            <MenuItem value="TextInput" sx={{ "& .MuiListItemIcon-root": { minWidth: "auto" } }} style={{ fontWeight: "600", fontSize: "14px !important" }} >
              <ShortTextSharpIcon sx={{ mr: 1 }} />
              Текст (строка)
            </MenuItem>
            <MenuItem  value="TextInputArea" sx={{ "& .MuiListItemIcon-root": { minWidth: "auto" } }} style={{ fontWeight: "600", fontSize: "14px !important" }} >
              <NotesSharpIcon sx={{ mr: 1 }} />
              Текст (абзац)
            </MenuItem>
            <MenuItem  value="QuestionOnlyOne" sx={{ "& .MuiListItemIcon-root": { minWidth: "auto" } }} style={{ fontWeight: "600", fontSize: "14px !important" }} >
              <RadioButtonUncheckedSharpIcon sx={{ mr: 1 }} />
              Один из списка
            </MenuItem>
            <MenuItem value="QuestionsMore" sx={{ "& .MuiListItemIcon-root": { minWidth: "auto" } }} style={{ fontWeight: "600", fontSize: "14px !important" }}  >
              <CheckBoxOutlineBlankSharpIcon sx={{ mr: 1 }} />
              Несколько из списка
            </MenuItem>
          </Select>
        </Box>


        <ButtonToolTip 
            title = "Удалить вопрос"
            onClick={() => removeFieldFromBlock(blockIndex, fieldIndex)}
            icon={<DeleteIcon />}
            tooltipStyle={{ fontSize: 12, fontWeight: 'bold' }}
            buttonStyle={{ ml: 2, color: '#970D0D', border: 'none' }}
        />

      </Box>

      {/* Additional content for variants */}
      {field.variants.map((variant, variantIndex) => (
        <Box key={variantIndex} sx={{ display: 'flex', alignItems: 'center', marginLeft: 4 }}>
          {variant.type === "QuestionOnlyOne" && (<RadioButtonUncheckedSharpIcon sx={{ mr: 1 }} /> )}
          {variant.type === "QuestionsMore" && (<CheckBoxOutlineBlankSharpIcon sx={{ mr: 1 }} />)}
          <TextField
            label="Вариант ответа"
            variant="outlined"
            value={variant.title}
            onChange={(event) => handleVariantChange(event.target.value, blockIndex, fieldIndex, variantIndex)}
            margin="normal"
            disabled={
              variant.type === "TextInput" ||
              variant.type === "TextInputArea" ||
              variant.type === "TextInputAreaAny"
            }
            sx={{ width: "50%" }}
            InputProps={{ style: { fontSize: "14px", fontWeight: "600" } }}
            InputLabelProps={{
              style: { fontSize: "14px", fontWeight: "bold" },
            }}
          />

          {variant.type !== "TextInput" && variant.type !== "TextInputArea" && (

             <ButtonToolTip 
                title = "Удалить вариант"
                onClick={() => removeVariantFromField(blockIndex, fieldIndex, variantIndex)}
                icon={<DeleteIcon />}
                disabled={field.variants.length <= 1}
                tooltipStyle={{ fontSize: 12, fontWeight: 'bold' }}
                buttonStyle={{ ml: 2, color: '#970D0D', border: 'none' }}
             />
          )}
        </Box>
      ))}
      {field.type !== "TextInput" && field.type !== "TextInputArea" && (
            <>
            <ButtonFormDefault 
               text='Добавить вариант ответа'
               onClick={() => addVariantToField(blockIndex, fieldIndex)}
               startIcon={<AddSharpIcon />}
            />

              {field.variants.every((variant) => variant.type !== "TextInputAreaAny") && ( // Добавлено условие
                  <ButtonFormDefault 
                       text='Добавить вариант &quot;Другое&quot;'
                       onClick={() => addAnotherVariant(blockIndex, fieldIndex)}
                       startIcon={<AddSharpIcon />}
                  />
              )}
            </>
          )}
    </Box>
  );
};

export default FieldInput;
