//import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const Square = ({ data, onClick }) => {
  Square.propTypes = {
    onClick: PropTypes.func,
    data: PropTypes.object,
  };
  return (
    <Box
      onClick={() => onClick(data.id, data.is_subs_nav, data.sub_id)}
      sx={{
        width: "30em",
        margin: "0 5px 10px 0",
        height: "200px", // Высота квадрата
        backgroundColor: "white", // Цвет фона 
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        boxShadow: "rgb(145 158 171 / 50%) 0px 0px 2px 0px, rgb(145 158 171 / 50%) 0px 16px 32px -4px",
      }}
    >
      <Box style={{flex: 1 , height: '100%', maxWidth: 'auto', maxHeight: 'auto', justifyContent: 'center'}}>
      <Typography variant="h6" color={{color: 'black'}} fontSize={19} fontWeight={'700'} marginLeft={2} marginTop={1}>{data.title}</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 2 }}>
        <Typography variant="h6" color="black" fontSize={19} fontWeight={700} marginLeft={2}>
            Явка составляет:
        </Typography>

        <Typography variant="h6" color="red" fontSize={25} fontWeight={600} marginLeft={2}>
           {data.percent}
        </Typography>
      </Box>

      <Typography variant="h6" color={{color: 'black'}} fontSize={17} fontWeight={'600'} marginLeft={2} marginTop={2}>Проголосовало: {data.count} из {data.total} </Typography>
      
      </Box>
    </Box>
  );
};

export default Square;