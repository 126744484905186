import React, { useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

import { Button, FormControl, TextField } from '@mui/material';
import { useUserDataStore } from '../../../../stores/userStore';

const addMemosForm = ({ addMaterial, editData, handleUpdate }) => {
  addMemosForm.propTypes = {
    addMaterial: PropTypes.func,
    editData: PropTypes.object,
    handleUpdate: PropTypes.func,
  };

  const userInfo = useUserDataStore((state) => state.user);

  const [material, setMaterial] = React.useState({
    title: '',
    subtitle: '',
    desc: '',
    data: '',
    meta: '',
    home_ik: userInfo.home_ik,
    recom_in_iks: [],
    file_links: [],
    type_id: 2,
  });
  
  const handleChange = (e) => {
    setMaterial({ ...material, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editData) {
      handleUpdate(material);
    } else {
      addMaterial(material);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMaterial({ ...material, file });
    }
  };

  useEffect(() => {
    if (editData) {
      setMaterial(editData);
    }
  }, [editData]);

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        margin="dense"
        name="title"
        label="Название материала"
        value={material.title}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        margin="dense"
        name="subtitle"
        label="Краткое описание"
        value={material.subtitle}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        margin="dense"
        name="desc"
        label="Полное описание"
        value={material.desc}
        onChange={handleChange}
        fullWidth
      />
      {material.file_links.length > 0 ? (
        <div style={{flex: 1, flexDirection: 'row'}}>
          <p>Добавленный файл: {material.file_links[0]}</p>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setMaterial({ ...material, file_links: [] })}
          >
            Удалить
          </Button>
        </div>
      ) : (
        <FormControl fullWidth margin="normal">
          <input type="file" onChange={handleFileChange} />
        </FormControl>
      )}
        <Button type="submit" variant="contained" color="primary" margin="normal" style={{marginTop: 10}}>
          {editData ? 'Обновить' : 'Добавить'}
        </Button>
    </form>
  );
};

export default addMemosForm;