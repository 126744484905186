import { useEffect, useState } from 'react';
import { Box, Button, Dialog ,DialogContent ,DialogTitle, useTheme, DialogActions, Typography } from '@mui/material';
//import axios from 'axios';
import { tokens } from '../../theme';
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
import AddRegionForm from "./addRegionForm"
import enumRegions from "../../Api/Regions/enumRegions"
import createRegions from '../../Api/Regions/createRegions';
import { useUserStore } from "../../stores/userStore";
import deleteRegions from '../../Api/Regions/deleteRegions';
import { dataGridStyles } from '../../styleGrid';
import importData from '../../Api/ImportData/importData';

const addRegions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [regions, setRegions] = useState([]);
  const [open, setOpen] = useState(false);
  const [force, setForce] = useState(true);
  const token = useUserStore((state) => state.token);

  const columns = [
    { field: "id", headerName: "ID", width: 75 },
    {
      field: "name",
      headerName: "Регион",
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "actions",
      headerName: "Управление",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          style={{color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
          onClick={() => handleDeleteRegion(params.row.id)}
        >
          Удалить
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if(force){
      enumRegions({}).then((response) => {
        if(response.success){
          setRegions(response.data.regions);
          setForce(false)
        } else {
          setRegions([])
          alert(response.message)
          setForce(false)
        }
      });
    }
  }, [force]);

  const rows = regions.map(district => ({
    id: district.region_id,
    name: district.name,
  }));

  const addRegion = async (region) => {
    await createRegions({
      "user_initiator":{
          "usr_token": token
      },
      "region":{
          "name":region.name
      }
  }).then(response => {
    if (response.data.status === 'ok'){
      setForce(true);
      handleClose();
    }
   })
  };

  const handleDeleteRegion = async (roadMapId) => {
    await deleteRegions({
      "user_initiator":{
          "usr_token": token
      },
      "region":{
          "region_id":roadMapId
      }
    }).then(response => {
        if (response.data.status === 'ok'){
          setForce(true);
        }
    })
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAddDialog = () => {
    return(
      <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Укажите название региона"}
        </DialogTitle>
        <DialogContent>
           <AddRegionForm addRegion={addRegion} />
        </DialogContent>
      </Dialog>
    </div>
    )
  }

  //БЛОК С ИМПОРТОМ

  const [openImport, setOpenImport] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  let exampleImport = 'https://redcontrol.kprf.ru/files/ExamplesImport/testDataRegions.xlsx'

  const handleOpenImport = () => {
    setOpenImport(true);
  };

  const handleCloseImport = () => {
    setOpenImport(false);
    setSelectedFile(null);
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  const downloadFile = (url) => {
    // Создаем ссылку на скачивание
    const link = document.createElement('a');
    link.href = url;
    // Устанавливаем атрибуты для скачивания файла
    link.setAttribute('download', '');
    // Добавляем ссылку на страницу (это необходимо для некоторых браузеров)
    document.body.appendChild(link);
    // Нажимаем на ссылку, чтобы начать скачивание файла
    link.click();
    // Удаляем ссылку после скачивания
    document.body.removeChild(link);
};

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith('.xlsx')) {
      setSelectedFile(file);
    } else {
      alert('Please select a valid .xlsx file', { variant: 'error' });
    }
  };

  const handleFileUpload = () => {
    importData(selectedFile, token, "regions").then((resp) => {
      if(resp.success){
        alert('Данные успешно импортированы');
      } else {
        alert(`Ошибка при импорте данных: ${resp.message}`)
      }
    })
  };

  const handleOpenDialogImport = () => {
    return (
      <div>
      <Dialog open={openImport} onClose={handleCloseImport}>
        <DialogTitle>Импорт файла</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" justifyContent="center">
          {selectedFile ? (
            <Box display="flex" alignItems="center">
              <Typography>{selectedFile.name}</Typography>
              <Button variant="outlined" color="secondary" onClick={handleFileRemove} style={{ marginLeft: 16 }}>
                Отменить
              </Button>
            </Box>
          ) : (
            <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} component="label">
              Добавить файл
              <input type="file" accept=".xlsx" hidden onChange={handleFileChange} />
            </Button>
          )}
          </Box>
        </DialogContent>
        <DialogActions>
          {selectedFile && (
            <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleFileUpload}>
              Загрузить
            </Button>
          )}
          <>
          <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={() => downloadFile(exampleImport) }>
             Скачать пример
          </Button>
          <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleCloseImport}>
            Закрыть
          </Button>
          </>
        </DialogActions>
      </Dialog>
    </div>
    )
  }

//БЛОК КНОПОК В ФИЛЬТРЕ
  const buttonsElements = () => {
    return (
      <>

     <Button 
      variant="contained" 
      style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} 
      onClick={handleClickOpen}
      >
        Добавить регион
      </Button>

      <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold', marginLeft: 10}}  onClick={handleOpenImport}>
         Импорт
      </Button>
      </>
    )
  }

  return (
    <Box m="20px">
    <Header title="Добавление регионов" subtitle="Добавьте регионы в систему" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={dataGridStyles}
      >
        {handleOpenAddDialog()}
        {handleOpenDialogImport()}
       <DataGrid rows={rows} columns={columns} components={{ Toolbar: buttonsElements  }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}/>
    </Box>
    </Box>
  );
};

export default addRegions;