import { Button, Tooltip } from "@mui/material";
import PropTypes from 'prop-types';

const ButtonToolTip = ({ title, icon, onClick, disabled = false, buttonStyle, tooltipStyle }) => {
    return (
        <>
            <Tooltip
                title={title}
                style={{ ...tooltipStyle }}
                placement="top"
            >
                <span>
                    <Button
                        variant="outlined"
                        onClick={onClick}
                        sx={{ ...buttonStyle }}
                        disabled={disabled}
                    >
                        {icon}
                    </Button>
                </span>
            </Tooltip>
        </>
    )
}

ButtonToolTip.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    buttonStyle: PropTypes.object,
    tooltipStyle: PropTypes.object,
};

export default ButtonToolTip;