import { Paper, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const AddRoadMapHeader = ({ anket_title = '', anket_sub_title = '', handleInputChange }) => {

    return (
      <Paper sx={{ backgroundColor: '#727171', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', display: 'flex', width: '80%', height: 200, marginTop: 5 }}>
        <TextField
          label="Заголовок анкеты"
          variant="outlined"
          value={anket_title}
          name="anket_title"
          onChange={(event) => handleInputChange(event.target.name, event.target.value)}
          margin="normal"
          sx={{ width: '90%' }}
          InputProps={{ style: { fontSize: '16px', fontWeight: '600' } }}
          InputLabelProps={{ style: { fontSize: '16px', fontWeight: 'bold' } }}
        />
        <TextField
          label="Краткое описание"
          variant="outlined"
          value={anket_sub_title}
          name="anket_sub_title"
          onChange={(event) => handleInputChange(event.target.name, event.target.value)}
          margin="normal"
          multiline
          maxRows={2}
          sx={{ width: '90%' }}
          InputProps={{ style: { fontSize: '16px', fontWeight: '600' } }}
          InputLabelProps={{ style: { fontSize: '16px', fontWeight: 'bold' } }}
        />
      </Paper>
    );
  };

AddRoadMapHeader.propTypes = {
  anket_title: PropTypes.any,
  anket_sub_title: PropTypes.any,
  handleInputChange: PropTypes.func.isRequired,
};

export default AddRoadMapHeader;