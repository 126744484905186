import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Typography,
  Box,
  Chip,
  Autocomplete,
  InputLabel,
  FormControl
} from '@mui/material';
import getIks from '../../Api/HomeIk/getIks';
import { useUserStore } from '../../stores/userStore';
import getInfoIk from '../../Api/HomeIk/getInfoIk';

const countyModalCreate = ({ onCreateCounty, selectedElection, selectedSub, onEdit}) => {
    countyModalCreate.propTypes = {
        onCreateCounty: PropTypes.func.isRequired,
        selectedElection: PropTypes.any.isRequired,
        selectedSub: PropTypes.any.isRequired,
        onEdit: PropTypes.func.isRequired,
      };

  const [selectedIk, setSelectedIk] = useState(null);
  const [selectedIks, setSelectedIks] = useState([]);
  const [filteredYiks, setFilteredYiks] = useState([]);

  const token = useUserStore((state) => state.token);
  const [searchLoading, setSearchLoading] = useState(false);

  const [dataSub, setDataSub] = useState({
    sel_id: null,
    name: '',
    sel_iks: [],
    election_id: selectedElection.election_id,
  });

  useEffect(() => {
    if (selectedSub) {
      setDataSub((prev) => ({ ...prev, ...selectedSub}));
      if (
        selectedSub.sel_iks &&
        selectedSub.sel_iks.length > 0
      ) {
        Promise.all(
          selectedSub.sel_iks.map((value) =>
            getInfoIk({ ik: { ik_id: value } })
          )
        ).then((responses) => {
          const newData = responses.reduce((acc, resp) => {
            if (resp.success) {
              acc.push(resp.data.ik);
            } else {
              alert(resp.message);
            }
            return acc;
          }, []);
          setSelectedIks(newData);
        });
      }
    }
  }, [selectedSub]);

  const handleCreateCounty = () => {
    if (selectedSub){
      onEdit(dataSub);
    }else {
      onCreateCounty(dataSub);
    }
  };

  const handleAddIksChip = () => {
    setDataSub({ ...dataSub, sel_iks: [...dataSub.sel_iks, selectedIk.ik_id] });
    setSelectedIks((prev)=> [...prev, selectedIk])
    setSelectedIk(null);
    setFilteredYiks([]); // Очищаем результаты поиска
  };

  const handleRemoveIkChip = (ikIdToRemove) => {
    // Удаляем выбранный ИК dataSub
    setDataSub(prevData => ({
        ...prevData,
        sel_iks: prevData.sel_iks.filter(ikId => ikId !== ikIdToRemove)
    }));

    // Удаляем выбранный ИК из selectedIks
    setSelectedIks(prevSelectedIks => prevSelectedIks.filter(ik => ik.ik_id !== ikIdToRemove));
};

   // Обработчик выбора значения в Autocomplete
const handleAutocompleteChange = (event, newValue) => {
  setSelectedIk(newValue);
 // Записываем значение УИКа в user.home_ik
}

const handleSearch = async (event, newValue, reason) => {

  if (reason === 'reset') {
    setFilteredYiks([]);
    return
  }

  if (event?.type === 'click' || event === null || event?.target === null) {
    return;
   }

   const searchTerm = event.target.value;

  // Проверка, если строка поиска пустая
  if (searchTerm.length === 0) {
    setFilteredYiks([]); // Очищаем результаты поиска
    return;
  }

  if (searchTerm.length <= 2) {
    return;
  }

setSearchLoading(true);

 //Временное решение проблемы для быстрого поиска ЦИКа
 try {
   // Запрос списка yiks с сервера для текущей страницы
   const response = await getIks({
     "user_initiator": {
       "usr_token": token
     },
     "ik": {
       "name": searchTerm,
     },
     //"page": currentPage
   });
   
   if(response.success){
     const yiks = response.data.iks;
     setFilteredYiks(yiks);
     setSearchLoading(false);
   }
 } catch (error) {
   console.error("Ошибка при получении списка yiks:", error);
   // Обработка ошибки, например, отображение сообщения об ошибке пользователю
   setSearchLoading(false);
 }
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDataSub({ ...dataSub, [name]: value });
  };

  return (
    <>
       <InputLabel id="label-sub">Название округа</InputLabel>
        <TextField
          id="label-sub"
          name="name"
          fullWidth
          value={dataSub.name}
          onChange={handleInputChange}
          InputLabelProps={{
            shrink: false,
          }}
        />

        <Box>

       <Typography variant="h6" gutterBottom sx={{mt: 5}}>Выбранные избирательные комиссии:</Typography>
            <Box border="1px solid black" borderRadius="4px" p="10px" maxHeight="300px" overflow="auto">
              {selectedIks.length > 0 && selectedIks.map((ik) => (
                <Chip key={ik.ik_id} label={ik.name} onDelete={() => handleRemoveIkChip(ik.ik_id)} style={{ margin: '5px' }} />
              ))}
            </Box>
        <Box sx={{marginTop: 5}}>
        <InputLabel id="Autocomplete-label">Поиск избирательных комиссии</InputLabel>
        <FormControl fullWidth margin="none">
          <Autocomplete
              //defaultValue={selectedYik}
              value={selectedIk}
              options={filteredYiks}
              loading={searchLoading}
              onChange={handleAutocompleteChange}
              onInputChange={(event, newValue, reason) => handleSearch(event, newValue, reason)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} labelId="Autocomplete-label" fullWidth  />}
              noOptionsText="Ничего не найдено"
              loadingText="Идет поиск. Пожалуйста, подождите!"
           />
           </FormControl>
        </Box>

        <Button variant="contained" color="primary" onClick={handleAddIksChip} disabled={!selectedIk} sx={{marginTop: 2}}>
          Добавить
        </Button>
        </Box>
        {/* Add your TransferList component or other selection mechanism here */}
        {/* <TransferListUniversal
           leftTitle="Доступные данные"
           rightTitle="Выбранные данные"
           data={dataApi}
           selectedData={selectedDatas}
           onChange={handleTransferListChange}
      /> */}
        <Button variant="contained" color="primary" margin="normal" onClick={handleCreateCounty} sx={{marginTop: 5}}>
          {selectedSub !== null ? "Сохранить" : "Создать"}
        </Button>
        </>
  );
};

export default countyModalCreate;