import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  useTheme,
  Divider,
} from "@mui/material";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
//import ProtocolDetails from "./protocolDetails";
//import { useUserStore } from "../../stores/userStore";
import { dataGridStyles } from "../../styleGrid";
import { tokens } from "../../theme";
import enumPfields from "../../Api/Pfields/enumPfields";
import AddPfieldsForm from "./addPfieldsForm";
import getInfoPfield from "../../Api/Pfields/getInfoPfield";
import createPfield from "../../Api/Pfields/createPfield";
import deletePfield from "../../Api/Pfields/deletePfield";
import updatePfield from "../../Api/Pfields/updatePfield";

const Pfields = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pfields, setPfields] = useState([]);
  const [selectedPfield, setSelectedPfield] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [force, setForce] = useState(true);
//   const token = useUserStore((state) => state.token);
   const [page, setPage] = useState(0);
  //const [filteredRows, setFilteredRows] = useState([]);
  let totalPages = 100000;

  const electionSys = [
    { value: 1, label: "Единый округ" },
    { value: 2, label: "Мажоритарная" },
    { value: 3, label: "Смешанная" },
  ];

  const typeId = [
    { value: 1, label: "ЦИК" },
    { value: 2, label: "РИК" },
    { value: 3, label: "ТИК" },
    { value: 4, label: "УИК" },
  ];

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "name",
      headerName: "Название поля",
      width: 400,
      cellClassName: "name-column--cell",
    },
    {
      field: "number",
      headerName: "Номер поля",
      width: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "type_id",
      headerName: "Тип протокола",
      width: 120,
      renderCell:(params) => (
        params.row.type_id && params.row.type_id !== null 
            ? typeId.find((type) => type.value === params.row.type_id).label
            : ""
      )
    },
    {
      field: "ik_data",
      headerName: "ИК",
      width: 250,
      renderCell:(params) => (
           params.row.ik_data.name && params.row.ik_data.name !== null 
               ? params.row.ik_data.name
               : ""
      )
    },
    {
      field: "election_system",
      headerName: "Система выборов",
      width: 200,
      renderCell:(params) => (
        params.row.election_system && params.row.election_system !== null 
            ? electionSys.find((type) => type.value === params.row.election_system).label
            : ""
      )
    },
    {
      field: "view",
      headerName: "Действия",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
              variant="outlined"
              style={{color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
              onClick={() => handleEditPfield(params.row.id)}
            >
              Редактировать
           </Button>

           <Button
               variant="outlined"
               style={{marginLeft: "10px", color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
               onClick={() => handleDeletePfield(params.row.id)}
          >
            Удалить
        </Button>
        </>
      ),
    },
  ];

  const getDataPfields = async (newPage = page) => {

    let payload = {
        "page": newPage
    }

   await enumPfields(payload).then((resp) => {
    setForce(false)
    if (resp.success){
        setPfields(resp.data.pfields)
    } else {
        setPfields([])
        alert(resp.message)
    }
     setPage(newPage)
   })
  }


  useEffect(()=> {
    if(force) getDataPfields();

  },[force])

  const handleAddPfield = async (pfieldData) => {

    let payload = {
        "pfield": pfieldData
    }

    await createPfield(payload).then((resp) => {
        if (resp.success){
           setForce(true);
           handleCloseDialog();
        } else {
            alert("Произошла ошибка при получении добавлении нового поля")
        }
    })
  }

  const handleUpdatePfield = async (pfieldData) => {

    let payload = {
        "pfield": pfieldData
    }

    await updatePfield(payload).then((resp) => {
        if (resp.success){
           setForce(true);
           handleCloseDialog();
        } else {
            alert("Произошла ошибка при получении информации о поле")
        }
    })
  }

  const handleEditPfield = async (id) => {

    let payload = {
        "pfield": {
            "id": id
        }
    }

    await getInfoPfield(payload).then((resp) => {
        if (resp.success){
           setSelectedPfield(resp.data.pfield)
           handleOpenDialog();
        } else {
            alert("Произошла ошибка при получении информации о поле")
        }
    })

  }

  const handleDeletePfield = async (id) => {

    let payload = {
        "pfield": {
            "id": id
        }
    }

    await deletePfield(payload).then((resp) => {
        if (resp.success){
            setForce(true);
            handleCloseDialog();
        } else {
            alert("Произошла ошибка при получении информации о поле")
        }
    })

  }

  const handleOpenDialog = () => {
      setDialogOpen(true);
  }

  const handleCloseDialog = () => {
      setDialogOpen(false);
      setSelectedPfield(null);
  }

  
  const dialogPField = () => {
    return (
      <>
         <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleOpenDialog}>
             Добавить поле
        </Button>
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle style={{fontWeight:'700', fontSize: 20}}>Поле верхней части протокола</DialogTitle>
          <Divider />
          <DialogContent>
               <AddPfieldsForm  addPfield={handleAddPfield} selectedPfield={selectedPfield} updatePfield={handleUpdatePfield} />
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const pageChange = (newPage) => {
    getDataPfields(newPage)
  }


  return (
    <Box m="20px">
      <Header title="Поля верхней части протоколов" />
      <Box sx={{mt: 2}}>

       {/* <DataGridFilterCustom columns={filteredColumns(columns)} rows={rows} setFilteredRows={setFilteredRows} /> */}

       </Box>
      <Box
        m="20px 0 0 0"
        height="80vh"
        sx={dataGridStyles}
      >

        <DataGrid 
        components={{ Toolbar: dialogPField }}
        rows={pfields}
        columns={columns} 
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        pageSize={100}
        paginationMode={'server'}
        rowCount={totalPages}
        onPageChange={pageChange}
        />
      </Box>
    </Box>
  );
};

export default Pfields;
