import { Button } from "@mui/material";
import PropTypes from 'prop-types';

const ButtonFormDefault = ({text, icon, onClick}) => {
    return (
        <>
             <Button
                  variant="outlined"
                  color="primary"
                  startIcon={icon}
                  onClick={onClick}
                  sx={[
                    {
                      "&:hover": {
                        color: "#970D0D",
                        backgroundColor: "white",
                      },
                    },
                    {
                      mt: 1,
                      ml: 4,
                      color: "white",
                      backgroundColor: "#970D0D",
                      marginBottom: 5,
                      fontSize: 12,
                      fontWeight: "700",
                    },
                  ]}
                >
                  {text}
                </Button>
        </>
    )
}

ButtonFormDefault.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.any,
    onClick: PropTypes.func,
  };

export default ButtonFormDefault;