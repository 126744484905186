import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";

import { Button, FormControl, Select, MenuItem, TextField, InputLabel, Box, Paper, IconButton, InputAdornment, Autocomplete } from '@mui/material';
import { useUserDataStore, useUserStore } from '../../../stores/userStore';
import resetPass from '../../../Api/Users/resetPass';
import setHeadIk from '../../../Api/HomeIk/setHeadIk';
import deleteHeadIk from '../../../Api/HomeIk/deleteHeadIk';
import getIks from '../../../Api/HomeIk/getIks';
import getInfoIk from '../../../Api/HomeIk/getInfoIk';
import { Roles, determineRoleCTYPE } from '../../../data/Utils/accessControlConst';
import enumDistricts from '../../../Api/Districts/enumDistricts';
import enumRegions from '../../../Api/Regions/enumRegions';
//import { Lock } from '@mui/icons-material/';


const addUserForm = ({ addUser, currentUser, statuses, updateUser  }) => {
  addUserForm.propTypes = {
    addUser: PropTypes.func,
    statuses: PropTypes.array,
  };
  const [user, setUser] = useState({
    usr_password: '',
    usr_name: '',
    usr_phone: '',
    usr_email: '',
    status_id: null,
    home_ik: null, // Selected yik object
    isuserverified: true,
    is_banned: false,
    checkins: [],
    access_id: 46,
    head_in_iks:[],
    tag: false,
    has_access_to_ids: [],
    access_data: {
      access_id: null,
      access_level_major: null,
      access_level_minor: null
    },
    region: null,
    district: null
  });

  const token = useUserStore((state) => state.token);
  const userInfo = useUserDataStore((state) => state.user);

  const [userPwReset, setUserPwReset] =useState(false);

  const [selectedYik, setSelectedYik] = useState(null); // Состояние для выбранного УИКа
  const [filteredYiks, setFilteredYiks] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedHead, setSelectedHead] = useState(null);
  const [regions, setRegions] = useState([])
  const [districts, setDistricts] = useState([])
  const [typeId, setTypeId] = useState(null)

  //console.log(currentUser)

  useEffect(() => {
    if (currentUser) {
      setUser((prev) => ({...prev, ...currentUser}));
      setSelectedYik(currentUser.ik_data)

      if(currentUser.head_in_iks && currentUser.head_in_iks.length > 0){
        getInfoIk({
          "ik":{
            "ik_id": currentUser.head_in_iks[0]
           }
        }).then((resp)=>{
           if(resp.success){
            setSelectedHead(resp.data.ik)
           } else {
             setSelectedHead([])
             alert(resp.message)
           }
        })
      }
    }
  }, [currentUser]);

  const handleChange = (e) => {
    setUser((prevUser) => ({
      ...prevUser,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePasswordReset = async () => {

    let payload = {
      "user_initiator":{
        "usr_token": token
       },
      "user":{
        "user_id":currentUser.user_id
      }
    }
    await resetPass(payload).then((resp)=> {
      if (resp.success){
        setUser((prev) => ({...prev, usr_password: resp.data.user.usr_password }))
        setUserPwReset(true)
      } else {
        alert(resp.message)
      }
    })
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentUser) {
      // Handle update
      updateUser(user);
      setUserPwReset(false);
    } else {
      // Handle add
      await addUser(user);
      setUserPwReset(false);
    }
  };

  const handleChangeIk = async (event, newValue) => {
    if (newValue === null) {
      return;
    }

      let payload = {
      "user_initiator":{
        "usr_token": token
      },
      "ik":{
         "ik_id":newValue.ik_id
       },
      "user":{
         "user_id":user.user_id
      }
    }
    try {
     await setHeadIk(payload).then((resp) => {
       if(resp.success){
        setUser((prevUser) => {
          const updatedHeadInIks = [...prevUser.head_in_iks];
          updatedHeadInIks[0] = newValue.ik_id;
          return {
            ...prevUser,
            head_in_iks: updatedHeadInIks,
          };
        })
          getInfoIk({
            "ik":{
              "ik_id": newValue.ik_id
             }
          }).then((resp)=>{
             setSelectedHead(resp.data.ik)
          })
       } else {
          alert(resp.message)
       }
     })
      } catch (error) {
       console.error("Error fetching head_in_iks:", error);
       alert(error)
     }
  }

  const handleAddHeadInIk = () => {
    setUser((prevUser) => {
      if (!prevUser.head_in_iks) {
        // If head_in_iks doesn't exist, create a new array
        return {
          ...prevUser,
          head_in_iks: [0],
        };
      }
  
      // If head_in_iks exists, append the new value
      return {
        ...prevUser,
        head_in_iks: [...prevUser.head_in_iks, 0],
      };
    });
  };

  const handleRemoveHeadInIk = async () => {
    let payload = {
      "user_initiator":{
        "usr_token": token
      },
      "ik":{
         "ik_id": user.head_in_iks[0]
       },
      "user":{
      }
    }
    try {
     await deleteHeadIk(payload).then((resp) => {
       if(resp.success){
        setUser((prevUser) => {
          const updatedHeadInIks = [...prevUser.head_in_iks];
          updatedHeadInIks.splice(0, 1);
          return {
            ...prevUser,
            head_in_iks: updatedHeadInIks,
          };
        });
        setSelectedHead(null)
       } else {
         alert(resp.message)
       }
     })
      } catch (error) {
       console.error("Error fetching head_in_iks:", error);
     }
  };

  //console.log('TEST DATA', user)

  // Обработчик выбора значения в Autocomplete
const handleAutocompleteChange = (event, newValue) => {
  setSelectedYik(newValue);
  // Записываем значение УИКа в user.home_ik
  setUser({ ...user, home_ik: newValue ? newValue.ik_id : 0 });
}

const handleSearch = async (event, newValue, reason) => {

  if (reason === 'reset') {
    setFilteredYiks([]);
    return
  }

  if (event?.type === 'click' || event === null || event?.target === null) {
    return;
   }

   const searchTerm = event.target.value;

  // Проверка, если строка поиска пустая
  if (searchTerm.length === 0) {
    setFilteredYiks([]); // Очищаем результаты поиска
    return;
  }

  if (searchTerm.length <= 2) {
    return;
  }

setSearchLoading(true);

  //Временное решение проблемы для быстрого поиска ЦИКа
  try {
    // Запрос списка yiks с сервера для текущей страницы
    const response = await getIks({
      "user_initiator": {
        "usr_token": token
      },
      "ik": {
        "type_id": typeId,
        "name": searchTerm,
      },
      //"page": currentPage
    });
    
    if(response.success){
      const yiks = response.data.iks;
      setFilteredYiks(yiks);
      setSearchLoading(false);
    }
  } catch (error) {
    console.error("Ошибка при получении списка yiks:", error);
    // Обработка ошибки, например, отображение сообщения об ошибке пользователю
    setSearchLoading(false);
  }
};

const handleChangeRole = (event) => {
  //TODO: Заставить дать информацию по одному пользователю
  const { value } = event.target;
  let newAccessID;

  setTypeId()

  newAccessID = determineRoleCTYPE(value)

  if(newAccessID){
   setTypeId(newAccessID[3])
    // Обновляем accessData в user
   setUser((prevUserData) => ({
    ...prevUserData,
    access_id: newAccessID[0],
    access_data: {
     access_id: newAccessID[0] ,
     access_level_major: newAccessID[1],
     access_level_minor: newAccessID[2],
   },
   }));
  }

};

const filteredRoles = Roles.filter((role) => {
  const allowedAccess = determineRoleCTYPE(userInfo.access_data.access_id)?.[0];
  if (allowedAccess) {
    if (!currentUser && [35, 36, 37, 38, 39, 40, 41, 42, 43, 44].includes(role.value)) {
      return false; // Exclude roles 45, 46, 47, 48 if !currentUser
    }
    if (allowedAccess === 35){
      return role.value
    }else if (allowedAccess === 36 || allowedAccess === 37) {
      return role.value !== 36; // Exclude "Центральный супер админ"
    } else if (allowedAccess === 38) {
      return role.value === 38; // Only include "Центральный модератор"
    } else if (allowedAccess === 39 || allowedAccess === 40) {
      return role.value > 39; // Include roles starting from "Региональныq админ"
    } else if (allowedAccess === 41) {
      return role.value === 41; // Only include "Региональный модератор"
    } else if (allowedAccess === 42 || allowedAccess === 43) {
      return role.value > 42; // Include roles starting from "Районный админ"
    } else if (allowedAccess === 44) {
      return role.value === 44; // Only include "Районный модератор"
    }
  }
  return false;
});

// БЛОК ОТВЕЧАЮЩИЙ ЗА НАЗНАЧЕНИЕ ПОЛЬЗОВАТЕЛЯ ОПРЕДЕЛЕННЫМ УРОВНЕМ ДОСТУПА

useEffect(()=> {
  if (user.access_id){
  enumRegions({}).then((response) => {
    if (response.success){
      setRegions(response.data.regions)
    } else {
      setRegions([])
      alert(response.message)
    }
  })
   }
}, [user.access_id])

useEffect(()=> {
  if (user.region !== null) {
  enumDistricts({"region":{"region_id":user.region}}).then((response) => {
    if (response.success){
      setDistricts(response.data.districts)
    } else {
      setDistricts([])
      alert(response.message)
    }
  });
  }
},[user.region])

  return (
    <form onSubmit={handleSubmit}>
       <TextField
        label="Почта"
        name="usr_email"
        value={user.usr_email}
        onChange={handleChange}
        margin="normal"
        //required
        fullWidth
      />
      <TextField
        label={currentUser ? "Новый пароль" : "Пароль"}
        name="usr_password"
        value={user.usr_password}
        onChange={handleChange}
        margin="normal"
        //required={}
        fullWidth
        type={currentUser && user.usr_password && !userPwReset ? "password" : "text"}
        InputProps={{
          endAdornment: (
            <>
            {currentUser && !userPwReset &&
            <InputAdornment position="end">
              <IconButton onClick={handlePasswordReset} edge="end">
                <LockOpenOutlinedIcon style={{ color: 'green' }} />
                <span style={{ marginLeft: '5px', color: 'green', fontSize: 14 }}>Сбросить пароль</span>
              </IconButton>
            </InputAdornment>
             }
            </>
          ),
        }}
      />
      
      <TextField
        label="ФИО"
        name="usr_name"
        value={user.usr_name}
        onChange={handleChange}
        margin="normal"
        required
        fullWidth
      />
      <TextField
        label="Номер телефона"
        name="usr_phone"
        value={user.usr_phone}
        onChange={handleChange}
        margin="normal"
        //required
        fullWidth
      />
      <FormControl fullWidth margin="normal">
        {!currentUser && <InputLabel>Статус</InputLabel> }
        <Select name="status_id" value={user.status_id} onChange={handleChange}>
          {statuses.map((status) => (
          <MenuItem key={status.id} value={status.id}>
            {status.name}
          </MenuItem>
           ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
      <InputLabel shrink={user.access_data.access_id ? true : false}>Роль</InputLabel>
        <Select
          name="access_id"
          label="Роль"
          value={user.access_data.access_id}
          onChange={handleChangeRole}
        >
          {filteredRoles.map((role) => (
            <MenuItem key={role.value} value={role.value}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>


      {regions && [39, 42].includes(determineRoleCTYPE(user.access_data.access_id)?.[0]) &&
      <>
      <FormControl fullWidth margin="normal">
        <InputLabel>Выберите регион назначения супер админа</InputLabel>
        <Select
          name="region"
          value={user.region}
          onChange={e => 
            handleChange(e)
          }
        >
          {regions.map(region => (
            <MenuItem key={region.region_id} value={region.region_id}>{region.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      
      {user.access_data.access_id === 42 &&
      <FormControl fullWidth margin="normal">
        <InputLabel>Выберите район назначения супер админа</InputLabel>
        <Select
          name="district"
          value={user.district}
          onChange={e => 
            handleChange(e)
          }
        >
          {districts.map(district => (
            <MenuItem key={district.district_id} value={district.district_id}>{district.district_name}</MenuItem>
          ))}
        </Select>
      </FormControl>
         }
      </>
       }



      <FormControl fullWidth margin="normal">
      <Autocomplete
        value={selectedYik}
        options={filteredYiks}
        loading={searchLoading}
        onChange={handleAutocompleteChange}
        onInputChange={(event, newValue, reason) => handleSearch(event, newValue, reason)}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label='УИК' />}
        noOptionsText="Ничего не найдено"
      />
      </FormControl>
      {currentUser && (
  <div>
    <InputLabel> Добавьте или выберите пользователю ИК для назначения чтобы сделать старшим </InputLabel>
    <Paper>
      <Box mt={2}>
        {user.head_in_iks && user.head_in_iks.length > 0 &&
          user.head_in_iks.map((item, index) => (
            <Box key={index} display="flex" alignItems="center" mt={1}>
              <FormControl sx={{ m: 1, minWidth: 150 }}>
                <Autocomplete
                  value={selectedHead}
                  options={filteredYiks}
                  onChange={handleChangeIk}
                  loading={searchLoading}
                  onInputChange={(event, newValue, reason) => handleSearch(event, newValue, reason)}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label='УИК' />}
                  noOptionsText="Ничего не найдено"
                />
              </FormControl>
              <Button 
                onClick={handleRemoveHeadInIk}
                style={{ border: '2px solid white', color: 'white' }}
              >
                - Разжаловать
              </Button>
            </Box>
          ))}
          {user.head_in_iks && user.head_in_iks.length < 1 &&
          <Box mt={2}>
            <Button
              onClick={handleAddHeadInIk}
              style={{ border: '2px solid white', color: 'white' }}
            >
              + Сделать старшим по ИК
            </Button>
          </Box>
           } 
      </Box>
    </Paper>    
  </div>
)}

      <Button type="submit" variant="contained" color="primary" margin="normal" style={{marginTop: 10}}>
      {currentUser ? "Обновить" : "Добавить"}
      </Button>
    </form>
  );
};

export default addUserForm;