//import { useState } from 'react';
import { TextField, Autocomplete, InputLabel, Box } from '@mui/material';
import PropTypes from "prop-types";

// Тип 1: Фильтр с выбором из множества данных с встроенным поиском
const MultiSelectWithSearch = ({ options, selectedOptions, setSelectedOptions, label, onSearch }) => {
  MultiSelectWithSearch.propTypes = {
    options: PropTypes.array,
    selectedOptions: PropTypes.any,
    setSelectedOptions: PropTypes.func,
    label: PropTypes.string,
    onSearch: PropTypes.func,
  };

  // Функция для добавления выбранной опции
  const handleSelect = (event, newValue) => {
    setSelectedOptions(newValue);
  };

  return (
    <Autocomplete
      value={selectedOptions}
      options={options}
      onChange={handleSelect}
      getOptionLabel={(option) => option.name}
      onInputChange={(event, newValue, reason) => onSearch(event, newValue, reason)}
      noOptionsText="Ничего не найдено"
      popupIcon={null} // Убираем стрелку
      renderInput={(params) => (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <InputLabel style={{ color: "#970D0D", fontSize: 12, fontWeight: 'bold' }}>{label}</InputLabel>
          <TextField 
            {...params} 
            variant="standard" // Используем 'outlined' для корректного отображения высоты и грани
            sx={{
              border:'1px solid black',
              borderColor: 'black',
              '& .MuiInputBase-root': {
                height: 35, // Задаем высоту
                width: 200,
                paddingLeft: '8px',
                display: 'flex',
                alignItems: 'center', // Центрируем содержимое по вертикали
              },
              '& .MuiInputBase-input': {
                //padding: '0 8px', // Устанавливаем отступы для правильного выравнивания
                color: 'black',
                fontSize: 15,
                height: '100%', // Занимаем всю высоту контейнера
                display: 'flex',
                alignItems: 'center', // Центрируем текст по вертикали
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black', // Цвет границы
              },
              '& .MuiSvgIcon-root': {
                color: 'black', // Цвет иконок, включая крестик
              }
            }}
          />
        </Box>
      )}
    />
  );
};

export default MultiSelectWithSearch;