import { useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Paper,
  } from "@mui/material";
import PropTypes from "prop-types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { formatDate } from "../../../data/Utils/timeZones";
import { getFileType } from '../../../data/Utils/formateConstants';

const IncendentDetail = ({ incendent }) => {
    IncendentDetail.propTypes = {
        incendent: PropTypes.func,
      };

      console.log(incendent)


      const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

      const handleNextMedia = () => {
        setCurrentMediaIndex((prevIndex) =>
          (prevIndex + 1) % incendent.file_links.length
        );
      };
    
      const handlePreviousMedia = () => {
        setCurrentMediaIndex((prevIndex) =>
          prevIndex === 0 ? incendent.file_links.length - 1 : prevIndex - 1
        );
      };

    // Функция для определения типа файла по его URL
    const getMediaType = (url) => {
        const fileType = getFileType(url);
        if (fileType === 'image') {
          return (
            <img
              src={url}
              alt={`Фото ${currentMediaIndex + 1}`}
              style={{ maxHeight: '75vh', justifyContent: 'center'}}
            />
          );
        } else if (fileType === 'video') {
          return (
            <video controls style={{ maxHeight: '75vh', justifyContent: 'center'}}>
              <source src={url} type="video/mp4" /> {/* Замените на тип вашего видео */}
              Ваш браузер не поддерживает видео.
            </video>
          );
        } else {
          return (
            <Typography variant="h6" style={{fontWeight:'500', fontSize: 14,}}> Нет доступных фото или видео</Typography>
          );
        }
      };

    return (
        <Box display="flex">
        <Box flex="1" p={2}>
          <Typography variant="h6" style={{fontWeight:'500', fontSize: 18, marginTop: 10}}>Краткая информация о нарушении: </Typography>
          <Paper>
          <Typography variant="h6" style={{fontWeight:'500', fontSize: 17, marginTop: 5, marginLeft: 10}}>{incendent.incident_title ? incendent.incident_title : "Информация не предоставлена"}</Typography>
          </Paper>

          <Typography variant="h6" style={{fontWeight:'500', fontSize: 18, marginTop: 10}}>Отправитель: </Typography>
          <Paper>
          <Typography variant="h6" style={{fontWeight:'500', fontSize: 17, marginTop: 5, marginLeft: 10}}>{incendent.user_data.usr_name}</Typography>
          </Paper>

          <Typography variant="h6" style={{fontWeight:'500', fontSize: 18, marginTop: 10,}}>Подробная информация о нарушении: </Typography>
          <Paper>
          <Typography variant="h6" style={{fontWeight:'500', fontSize: 17, marginTop: 5, marginLeft: 10}}>{incendent.incident_data ? incendent.incident_data : "Информация не предоставлена"}</Typography>
          </Paper>

          <Typography variant="h6" style={{fontWeight:'500', fontSize: 18, marginTop: 10}}>Время нарушения: </Typography>
          <Paper>
            <Typography variant="h6" style={{fontWeight:'500', fontSize: 17, marginTop: 5, marginLeft: 10}}>{formatDate(incendent.TimeReport)}</Typography>
          </Paper>

          <Typography variant="h6" style={{fontWeight:'500', fontSize: 18, marginTop: 10}}>Разрешена ли публикация в СМИ? </Typography>
          <Paper>
            <Typography variant="h6" style={{fontWeight:'500', fontSize: 17, marginTop: 5, marginLeft: 10}}>{incendent.AcceptMedia ? "Да" : "Нет"}</Typography>
          </Paper>

          <Typography variant="h6" style={{fontWeight:'500', fontSize: 18, marginTop: 10}}>Разрешено ли передать информацию в Избирком для реагирования?</Typography>
          <Paper>
            <Typography variant="h6" style={{fontWeight:'500', fontSize: 17, marginTop: 5, marginLeft: 10}}>{incendent.AcceptElectoral ? "Да" : "Нет"}</Typography>
          </Paper>
        </Box>
        <Box flex="1" p={2} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" style={{fontWeight:'700', fontSize: 20, marginBottom: 10}}>Медиа</Typography>
          {incendent.file_links.length > 0 && (
            <Box display="flex" alignItems="center">
              {currentMediaIndex > 0 && (
                <IconButton onClick={handlePreviousMedia}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Box>
                {getMediaType(incendent.file_links[currentMediaIndex])}
              </Box>
              {currentMediaIndex < incendent.file_links.length - 1 && (
                <IconButton onClick={handleNextMedia}>
                  <ArrowForwardIcon />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
};

export default IncendentDetail;