import { Typography, Box, useTheme, Divider, Grid } from "@mui/material";
import { tokens } from "../theme";
import PropTypes from 'prop-types';

const Header = ({ title, subtitle, backButton, statTitle, data, isVisible = false }) => { // Добавлен параметр backButton
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  Header.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    backButton: PropTypes.element, // Тип параметра backButton изменен на element
    statTitle: PropTypes.string, // Название stat
    data: PropTypes.string, // data stat
    isVisible: PropTypes.bool
  };

  return (
    <>
      <Box mb="10px" display="flex"> 
        {backButton && backButton} {/* Отображение кнопки назад, если она передана */}
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flexGrow: 1 }}>
          <Typography
            variant="h2"
            color={colors.primary[1100]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
          >
            {title}
          </Typography>
          {subtitle &&
          <Typography variant="h5" color={colors.primary[1100]} sx={{flex: 1, width: '100%'}}>
            {subtitle}
          </Typography>
           }
        </Box>
        {isVisible &&
        <Typography variant="h2" color={colors.primary[1100]} sx={{ marginRight: 10 }}>
          <Grid container direction="row" alignItems="center">
            <Grid item sx={{marginRight: 2}}>
              {statTitle}
            </Grid>
            <Grid item >
              <Box sx={{ borderRadius: 5, padding: 1, backgroundColor: colors.redAccent[1000], width: 150, textAlign: 'center', fontWeight: '700'}}>
                {data}
              </Box>
            </Grid>
          </Grid>
        </Typography>
        }
      </Box>
      <Divider sx={{ height: 1, backgroundColor: "rgb(242, 240, 240, 50%)" }} />
    </>
  );
};

export default Header;
