import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  useTheme,
} from "@mui/material";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
import IncendentDetail from "../mapViolations/incendentDetail";
import getListIncidents from "../../Api/incidents/getListIncidents";
import { useUserStore } from "../../stores/userStore";
import setArchiveIncidents from "../../Api/incidents/setArchiveIncidents";
import getIncidentInfo from "../../Api/incidents/getIncidentInfo";
import { dataGridStyles } from "../../styleGrid";
import { tokens } from "../../theme";
import { formatDate } from "../../data/Utils/timeZones";
import getInfoUser from "../../Api/Users/getInfoUser";
import { useNavigate } from "react-router-dom";
//import DataGridFilterCustom from "../../components/DataGridFilterCustom";

const AchiveIncendents = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [incendent, setSelectedIncendent] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [force, setForce] = useState(true);
  const token = useUserStore((state) => state.token);
  // const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(0);
  let totalPages = 100000;

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "user",
      headerName: "Кто отправил?",
      width: 250,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleClick(params.row.user_id)}>
          {params.row.user}
        </div>
      )
    },
    {
      field: "date",
      headerName: "Дата",
      width: 150,
      renderCell: (params) => (
        formatDate(params.row.date)
      ),
    },
    {
      field: "ElectionName",
      headerName: "Выборы",
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "incendentIs",
      headerName: "ИК",
      width: 150,
      cellClassName: "name-column--cell",
    },
    {
      field: "message",
      headerName: "Краткое описание",
      width: 250,
      cellClassName: "name-column--cell",
    },
    {
      field: "view",
      headerName: "Действия",
      flex: 1,
      renderCell: (params) => (
        <div> 
          <Button
            variant="outlined"
            style={{ color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
            size="small"
            onClick={() => handleViewIncendent(params.row)}
          >
            Посмотреть
          </Button>

          <Button
            variant="outlined"
            style={{marginLeft: "10px", color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
            size="small"
            onClick={() => handleMarkArchive(params.row)}
          >
            Вынести из архива
          </Button>
        </div>
      ),
    },
  ];

  const fetchDataIncidents = (newPage = page) => {
    getListIncidents({
      "user_initiator":{
          "usr_token":token
      },
      "incident":{
        "is_archived": true
      },
      "page": newPage
  }).then((response) => {
    if(response.success){
      setData(response.data.incidents);
      setForce(false);
    } else {
      setData([]);
      setForce(false);
      alert(response.message)
    }
    setPage(newPage)
  });
  }

  useEffect(() => {
    fetchDataIncidents();
  }, [force]);

  const handleViewIncendent = async (incendent) => {
    await getIncidentInfo({
      "user_initiator":{
        "usr_token":token
      },
      "incident":{
        "incident_id":incendent.id
       }
    }).then((response)=>{
      if (response.success){
        setSelectedIncendent(response.data.incident);
        setDialogOpen(true);
      } else {
        setDialogOpen(false);
        alert(response.message)
      }
    })
  };

  const handleMarkArchive = (incendent) => {
    setArchiveIncidents({
      "user_initiator":{
        "usr_token":token
      },
      "incident":{
         "incident_id":incendent.incident_id,
         "is_archived": false
      }
    }).then((response) => {
      if(response.success){
         setForce(true)
      } else {
        alert(response.message)
      }
    }).catch((error) => {
          // Обработка ошибки при удалении протокола
          alert(error.message)
           console.error("Ошибка при отправке в архив:", error);
    });
  };

 // ФУНКЦИОНАЛ ДЛЯ ПЕРЕХОДА К ПОЛЬЗОВАТЕЛЮ В ПРОФИЛЬ:

 const handleClick = async (userId) => {
  try {
      // Ваша логика обработки клика
      const resp = await getUserInfoFromId(userId);
      navigate('/me', { state: { userData: resp , nav: 'any'} });
  } catch (error) {
      alert(error);
  }
 };

 //получаем id информацию по id пользователя
 const getUserInfoFromId = async (id) => {
  let payload = {
      "user_initiator": {
          "usr_token": token
      },
      "user":{
          "user_id": id
      }
  };

  // Возвращаем результат выполнения асинхронного запроса
  return getInfoUser(payload).then((resp) => {
      if (resp.success) {
          return resp.data.user; // Возвращаем значение из .then()
      } else {
          throw new Error(resp.message); // Вызываем ошибку, чтобы обработать ее в handleClick
      }
  });
};

  const dialogIncendent = () => {
    return (
      <>
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>Детали нарушения</DialogTitle>
          <DialogContent>
            {incendent && (
              <IncendentDetail incendent={incendent} />
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const rows = data.map(incident => {
    return {
      id: incident.incident_id,
      ElectionName: incident.election_data.election_name,
      typeElection: incident.election_data.election_type,
      date: incident.created_at,
      user: incident.user_data.usr_name,
      //protocolType: incident.is_dupe ? "Повторный" : "Первичный",
      incendentIs: incident.ik_data.name,
      message: incident.incident_title ? incident.incident_title : "",
      ...incident
    };
  });

  const pageChange = (newPage) => {
    fetchDataIncidents(newPage)
  }

  // const filteredColumns = (columnsToFilter) => {
  //   // Исключаем колонки access_id и control
  //   return columnsToFilter.filter(column => column.field !== "access_id" && column.field !== "view");
  // };

  return (
    <Box m="20px">
    <Header title="Архив нарушений" />
    <Box sx={{mt: 2}}>

       {/* <DataGridFilterCustom columns={filteredColumns(columns)} rows={rows} setFilteredRows={setFilteredRows} /> */}

    </Box>
      <Box
        m="20px 0 0 0"
        height="80vh"
        sx={dataGridStyles}
      >
        {dialogIncendent()}
       <DataGrid 
       checkboxSelection 
       rows={rows}
       columns={columns} 
       localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
       pageSize={100}
       paginationMode={'server'}
       rowCount={totalPages}
       onPageChange={pageChange}
       />
    </Box>
    </Box>
  );
};

export default AchiveIncendents;