import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import getElections from "../../../Api/Elections/getElections";
import PropTypes from 'prop-types';

const ElectionSelection = ({ formData, handleChange }) => {
  ElectionSelection.propTypes = {
    formData: PropTypes.any,
    handleChange: PropTypes.func,
  };
  const [elections, setElections] = useState([]);

  useEffect(()=>{
    getElections({}).then((response) => {
      if(response.success){
        setElections(response.data.elections);
      } else {
        setElections([]);
        alert(response.message)
      }
    });
  },[])

    return (
      <Box sx={{ width: '100%', marginLeft: 2, marginTop: 1 }}>
        <InputLabel style={{ color: "#970D0D", fontSize: 20, fontWeight: 'bold' }}>Выберите выборы</InputLabel>
        <Select
          label="Выберите выборы"
          name="election_id"
          value={formData.election_id || ''}
          onChange={handleChange}
          sx={{ alignContent: 'center', justifyContent: 'center', width: '100%', color: 'white', backgroundColor: '#970D0D', fontWeight: 'bold', fontSize: '16px !important' }}
        >
          {elections.map((election) => (
            <MenuItem key={election.election_id} value={election.election_id} style={{ fontWeight: 'bold', fontSize: '16px !important' }}>
              {election.election_name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

export default ElectionSelection;