
export const dataGridStyles = {

    "& .MuiDataGrid-root, .MuiDataGrid-cell, .MuiDataGrid-columnHeader, .MuiDataGrid-footerContainer, .MuiDataGrid-columnHeaderTitle": {
      color: "black",
      fontWeight: 'bold',
      fontSize: 14,
    },
    "& .MuiDataGrid-root": {
      border: "none",
      borderRadius: 21,
      color: `#970D0D !important`,
    },
    "& .MuiDataGrid-cell": {
      borderColor: "white",
      //border: 'none',
      //background: `#D3D1D1 !important`
    },
    "& .name-column--cell": {
      //color: colors.redAccent[1100],
      //color: colors.greenAccent[1000],
      //border: 'none'
    },
    "& .MuiDataGrid-columnHeaders": {
      borderTopLeftRadius: 21,
      borderTopRightRadius: 21,
      background: `#D3D1D1 !important`,
      ///borderColor: "black",
    },
    "& .MuiDataGrid-virtualScroller": {
      background: `#D3D1D1!important`,
      color: "#970D0D",
     // border: 'none'
    },
    "& .MuiDataGrid-footerContainer": {
      borderBottomLeftRadius: 21,
      borderBottomRightRadius: 21,
      border: 'none',
      background: `#D3D1D1 !important`
    },
    "& .MuiCheckbox-root": {
      color: `#970D0D !important`,
    },
    "& .MuiDataGrid-columnHeaderTitleContainerContent" : {
      //backgroundColor: 'red', //область с TITLE колонок
      justifyContent: "center",
    },
    "& .MuiDataGrid-columnSeparator" : {
      position: "relative",
      backgroundColor: "transparent",
      height: "50px",
      // Для создания новой линии
      "&::before": {
          content: '""',
          position: "absolute",
          top: 10,
          left: "50%", // расположение линии посередине
          width: "1px", // ширина линии
          height: "70%", // высота линии, равная высоте родителя
          backgroundColor: "black",
      },
    },
    "& .MuiDataGrid-iconButtonContainer" : {
      //backgroundColor: 'red'
    },
    "& .MuiDataGrid-iconSeparator": {
      visibility: 'hidden'
    },
    "& .MuiDataGrid-selectedRowCount" : {
      color: 'black',
    },
    "& .MuiDataGrid-sortIcon" : {
      color: 'black',
      opacity: 1,
      ///visibility: 'visible !important',
    },
    "& .MuiDataGrid-withBorderColor" : {
      backgroundColor: 'red',
      width: '90%'
    },
    "& .MuiDataGrid-menuIconButton" : {
        color:'black'
    },
  
  };
  