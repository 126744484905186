import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  useTheme,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { tokens } from "../../theme";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
import AddAdminForm from "./addAdminForm";
import BlockUserModal from "../../modals/BlockUserModal";
import getAdmins from "../../Api/Users/getAdmins";
import { useUserStore } from "../../stores/userStore";
import addUser from "../../Api/Users/addUser";
import blockUser from "../../Api/Users/blockUser";
import updateUser from "../../Api/Users/updateUser";
import { determineRole } from "../../data/Utils/accessControlConst";
import getInfoUser from "../../Api/Users/getInfoUser";
import getStatuses from "../../Api/Statuses/getStatuses";
import { dataGridStyles } from "../../styleGrid";
import setSuperAdminSystem from "../../Api/AccessEdit/setSuperAdminSystem";
import setSuperAdminRegion from "../../Api/AccessEdit/setSuperAdminRegion";
import setSuperAdminDistrict from "../../Api/AccessEdit/setSuperAdminDistrict";
import { useNavigate } from "react-router-dom";
import DataGridFilterCustom from "../../components/DataGridFilterCustom";
import SearchEngine from "../../Api/SearchSystem/SearchUniversal";
import enumDistricts from "../../Api/Districts/enumDistricts";
import enumRegions from "../../Api/Regions/enumRegions";
import getIks from "../../Api/HomeIk/getIks";
import SimplePicker from "../../shared/CustomFilters/SimplePicker";
import MultiSelectWithSearch from "../../shared/CustomFilters/MultiSelectWithSearch";

const AddAdmins = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  //const userInfo = useUserDataStore((state) => state.user);
  const [force, setForce] = useState(true);
  const token = useUserStore((state) => state.token);
  const [statuses, setStatuses] = useState([]);
  const [isLoadingData, setIsLoadingData]= useState(false);

   //Для фильтров
   const [regions, setRegions]= useState([]);
   const [districts, setDistricts]= useState([])

  // Состояния для диалоговых окон блокировки и деактивации
  const [blockDialogOpen, setBlockDialogOpen] = useState(false);
  //const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false);
  const totalPages = 10000;

     //Поисковые функционал
     const [searchText, setSearchText] = useState('');
     const [debouncedText, setDebouncedText] = useState(''); // текст после задержки
     const [filters, setFilters] = useState({
       ik_id: null,
       reg_id: null,
       district_id: null,
     });

  const columns = [
    { field: "user_id", headerName: "ID", width: 50, },
    {
      field: "usr_name",
      headerName: "ФИО",
      width: 250,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleClick(params.row.user_id)}>
          {params.row.usr_name}
        </div>
      )
    },
    {
      field: "ik",
      headerName: "ИК",
      width: 200,
    },
    {
      field: "access_id",
      headerName: "Уровень доступа",
      flex: 1,
      renderCell: ({ row: { access_id, status_id } }) => {
        let statusName = statuses.find((item)=> item.id === status_id)?.name
        return (
          <>
            <Box
              width="50%"
              m="0 auto"
              p="5px"
              display="flex"
              //justifyContent="center"
              backgroundColor={colors.greenAccent[600]}
              borderRadius="4px"
            >
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                { determineRole(access_id) }
              </Typography>
            </Box>

            <Box
  width="50%"
  m="0 auto"
  p="5px"
  display="flex"
  marginLeft={"10px"}
  //justifyContent="center"
  backgroundColor={colors.greenAccent[600]}
  borderRadius="4px"
>
  <Typography color={colors.grey[100]}  sx={{ ml: "5px", maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
    {statusName}
  </Typography>
</Box>
          </>
        );
      },
    },
    {
      field: "control",
      headerName: "Управление",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div>
            <Button
               variant="outlined"
               className="custom-button"
               style={{ color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
               onClick={() => handleEditUser(row.user_id)}
            >
            Редактировать
          </Button>

            {row.is_banned ? (
              <Button
                variant="outlined"
                color="success"
                onClick={() => unblockUser(row.user_id)}
                style={{ marginLeft: "10px", color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
              >
                Разблокировать
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleBlock(row.user_id)}
                style={{ marginLeft: "10px", color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
              >
                Блокировать
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const getUsersList = async (newPage = 0) => {
    setIsLoadingData(true)
    let payload = {
      "user_initiator": {
        "usr_token": token
       },
       "user":{
        "home_ik":filters.ik_id,
       },
       "region":{
        "reg_id":filters.reg_id
        },
       "district":{
        "district_id":filters.district_id
      },
       "page": newPage,
    }
    await getAdmins(payload).then((response) => {
      if(response.success){
        //console.log('TEST TEST REPLANZ',response.data.users.length )
        const dataWithIds = response.data.users.filter(user => user.tag !== true).map((user) => ({
          id: user.user_id,
          usr_name: user.user, 
          ik: user.ik_data.name,
          ...user
        }));
        setUsers(dataWithIds);
        setIsLoadingData(false)
      } else {
        setUsers([]);
        setIsLoadingData(false)
        alert(response.message)
      }
      setForce(false)
    });
  }

  const getStatusList = async () => {
    await getStatuses({ "status_user":{
      "type":"admin"
    }}).then((resp) => {
      if(resp.success){
        setStatuses(resp.data.statuses_user)
      } else {
        setStatuses([])
        alert(resp.message)
      }
    })
 }

 const getRegions = async () => {
  await enumRegions({}).then((resp) => {
     if (resp.success) {
       const dataFormat = resp.data.regions.map((region) => ({
         value: region.region_id,
         label: region.name,
       }));
       setRegions(dataFormat)
     } else {
       setRegions([])
       alert(resp.message)
     }
   })
 }

 const getDistricts = async () => {
   await enumDistricts({
     "region":{
         "region_id":filters.reg_id
     }
   }).then((resp) => {
      if (resp.success) {
        const dataFormat = resp.data.districts.map((region) => ({
          value: region.district_id,
          label: region.district_name,
        }));
        setDistricts(dataFormat)
      } else {
       setDistricts([])
        alert(resp.message)
      }
    })
  }

//  useEffect(() => {
//    if(force){
//      getRegions();
//      getStatusList();
//      getUsersList();
//    }

//    if(filters.reg_id || filters.ik_id || filters.district_id){
//      getUsersList();
//    }
//  }, [force, filters]);

 useEffect(() => {
  if(force){
    getRegions();
    getStatusList();
    getUsersList();
  }

}, [force]);

useEffect(()=> {
  if(filters.reg_id || filters.district_id || filters.ik_id){
    getUsersList();
  } else if (filters.reg_id === null && filters.district_id === null && filters.ik_id === null){
    getUsersList();
  }

},[filters.reg_id, filters.ik_id, filters.district_id ])

 useEffect(() => {
   if(filters.reg_id){
     getDistricts();
   }
 }, [filters.reg_id]);

  // Используем useEffect для debounce
  useEffect(() => {
    // Создаем таймер для debounce
    const handler = setTimeout(() => {
      setDebouncedText(searchText); // Устанавливаем значение после задержки
    }, 300); // Устанавливаем задержку в миллисекундах (например, 300 мс)

    // Очищаем таймер при каждом изменении searchText
    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  useEffect(() => {
    if (debouncedText !== '') {
      handleSearch();
    }
  }, [debouncedText]);

  const addNewUser = async (user) => {
    await addUser({"user_initiator":{ "usr_token": token }, "user":user}).then((response) => {
        if (response.success) {
            if(user.access_data === 36){
               let payload = {
                "user_initiator":{
                  "usr_token": token
                 },
                 "user":{
                    "user_id":response.data.user.user_id
                 }
               }
               setSuperAdminSystem(payload).then((resp) => {
                  if (!resp.success){
                    alert(resp.message)
                  }
               })
            }
            if(!user.district && user.region){
              let payload = {
                "user_initiator":{
                  "usr_token": token
                 },
                 "region":{
                  "region_id":user.region
                 },
                 "user":{
                    "user_id":response.data.user.user_id
                 }
               }
               setSuperAdminRegion(payload).then((resp) => {
                if (!resp.success){
                  alert(resp.message)
                }
               })
            }
            if(user.district && user.region){
              let payload = {
                "user_initiator":{
                  "usr_token": token
                 },
                 "district":{
                  "district_id":user.district
                 },
                 "user":{
                    "user_id":response.data.user.user_id
                 }
               }
              setSuperAdminDistrict(payload).then((resp) => {
                if (!resp.success){
                  alert(resp.message)
                }
               })
          }
            setForce(true);
            setOpen(false);
        } else {
            console.log('Что-то не так', response)
            alert(response.message)
        }
    });
 };

  const handleBlockUser = async (userId, blockReason, blockTime) => {
    const updatedUsers = users.map((user) => {
        if (user.user_id === userId) {
            const updatedUser = { ...user, is_banned: !user.is_banned };
            blockUser({
                "user_initiator": { "usr_token": token },
                "user": {
                    "user_id": userId,
                    "is_banned": updatedUser.is_banned,
                    "ban_reason": blockReason,
                    "ban_until": blockTime
                }
            }).then((response) => {
                if (response.success) {
                    setUsers(updatedUsers);
                    setBlockDialogOpen(false);
                    setCurrentUser(null);
                } else {
                    alert(response.message)
                }
            });
            return updatedUser;
        } else {
            return user;
        }
    });
};

  // Функция для разблокировки пользователя
  const unblockUser = async (userId) => {
    const updatedUsers = users.map((user) => {
        if (user.user_id === userId) {
            const updatedUser = { ...user, is_banned: !user.is_banned };
            blockUser({
                "user_initiator": { "usr_token": token },
                "user": {
                    "user_id": userId,
                    "is_banned": updatedUser.is_banned
                }
            }).then((response) => {
                if (response.success) {
                    setUsers(updatedUsers);
                    setBlockDialogOpen(false);
                    setCurrentUser(null);
                } else {
                    alert(response.message)
                }
            });
            return updatedUser;
        } else {
            return user;
        }
    });
};

  const updateUserfunc = async (updatedUser) => {
    await updateUser({"user_initiator":{ "usr_token": token }, "user":updatedUser}).then((response) => {
      if (response.success) {
        if(updatedUser.access_data.access_id === 36){
          let payload = {
           "user_initiator":{
             "usr_token": token
            },
            "user":{
               "user_id":updatedUser.user_id
            }
          }
          setSuperAdminSystem(payload).then((resp) => {
             if (!resp.success){
               alert(resp.message)
             }
          })
       }
       if(!updatedUser.district && updatedUser.region){
         let payload = {
           "user_initiator":{
             "usr_token": token
            },
            "region":{
             "region_id":updatedUser.region
            },
            "user":{
               "user_id":updatedUser.user_id
            }
          }
          setSuperAdminRegion(payload).then((resp) => {
           if (!resp.success){
             alert(resp.message)
           }
          })
       }
       if(updatedUser.district && updatedUser.region){
         let payload = {
           "user_initiator":{
             "usr_token": token
            },
            "district":{
             "district_id":updatedUser.district
            },
            "user":{
               "user_id":updatedUser.user_id
            }
          }
         setSuperAdminDistrict(payload).then((resp) => {
           if (!resp.success){
             alert(resp.message)
           }
          })
       }
          setForce(true);
          setOpen(false);
          setCurrentUser(null) // Clear edit mode
      } else {
          console.log('Что-то не так', response)
          alert(response.message)
      }
  });
  };

  const handleEditUser = (userId) => {
    //console.log('TEST ADMIN DATA', userId)
    let payload = {
      "user_initiator": {
        "usr_token": token
      },
      "user":{
        "user_id":userId
      }
    }
    getInfoUser(payload).then((resp) =>{
      if (resp.success){
        setCurrentUser(resp.data.user);
        setOpen(true); // Open the addUserForm dialog
      }
    })
  };

  // ФУНКЦИОНАЛ ДЛЯ ПЕРЕХОДА К ПОЛЬЗОВАТЕЛЮ В ПРОФИЛЬ:

  const handleClick = async (userId) => {
    try {
        // Ваша логика обработки клика
        const resp = await getUserInfoFromId(userId);
        navigate('/me', { state: { userData: resp , nav: 'any'} });
    } catch (error) {
        alert(error);
    }
   };

   //получаем id информацию по id пользователя
   const getUserInfoFromId = async (id) => {
    let payload = {
        "user_initiator": {
            "usr_token": token
        },
        "user":{
            "user_id": id
        }
    };

    // Возвращаем результат выполнения асинхронного запроса
    return getInfoUser(payload).then((resp) => {
        if (resp.success) {
            return resp.data.user; // Возвращаем значение из .then()
        } else {
            throw new Error(resp.message); // Вызываем ошибку, чтобы обработать ее в handleClick
        }
    });
};
  

  const handleBlock = (userId) => {
    setBlockDialogOpen(true);
    setCurrentUser(userId);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentUser(null);
  };

  const handleOpenAddDialog = () => {
    return (
      <div>
        <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , fontWeight: 'bold', marginRight: 10}} onClick={handleClickOpen}>
          Добавить админа
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Укажите данные для нового админа"}
          </DialogTitle>
          <DialogContent>
            <AddAdminForm
              addUser={addNewUser}
              currentUser={currentUser}
              statuses={statuses}
              updateUser={updateUserfunc}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  // Диалоговое окно для блокировки пользователя
  const renderBlockDialog = (
    <Dialog
      open={blockDialogOpen}
      onClose={() => setBlockDialogOpen(false)}
      aria-labelledby="block-dialog-title"
    >
      <DialogTitle id="block-dialog-title">
        Блокировать пользователя
      </DialogTitle>
      <DialogContent>
        <BlockUserModal onBlockUser={handleBlockUser} userID={currentUser} />
      </DialogContent>
    </Dialog>
  );

  const pageChange = (newPage)=>{
    getUsersList(newPage);
  } 

  // useEffect(() => {
  //   if (searchText !== '') {
  //       handleSearch();
  //   } 
  // }, [searchText]);



  // Поисковый функционал
  const onClearSearch = () => {
    setSearchText('')
    setUsers([]);
    setForce(true);
}

const handleSearch = async () => {

  let payload = {
    "user_initiator": {
        "usr_token": token
    },
    "user":{
      "usr_name": debouncedText
    },
    "region":{
       "reg_id": filters.reg_id
    },
    "district":{
      "district_id": filters.district_id
    }
}

  if (debouncedText.length > 2){
    setIsLoadingData(true);

    await SearchEngine(payload, "admins").then((resp) => {
      if (resp.success){
        const dataWithIds = resp.data.users.filter(user => user.tag !== true).map((user) => ({
          id: user.user_id,
          usr_name: user.user, 
          ik: user.ik_data.name,
          ...user
        }));
        setUsers(dataWithIds);
        setIsLoadingData(false);
      } else {
        alert(resp.message)
        setIsLoadingData(false);
      }
    })
  } else if (debouncedText.length == 0 && debouncedText.length == ""){
     setUsers([]);
     setForce(true)
  }
}


// ФИЛЬТРЫ

const [multiSelectOptions, setMultiSelectOptions] = useState([]); // Опции для многовыборного фильтра
  const [selectedMultiOptions, setSelectedMultiOptions] = useState(null); // Выбранные опции для многовыборного фильтра

  const handleSearchIk = async (event, newValue, reason) => {

      if (reason === 'reset') {
        setMultiSelectOptions([]);
        setSelectedMultiOptions(null);
        return
      }
    
      if (event?.type === 'click' || event === null || event?.target === null) {
        return;
       }
    
       const searchTerm = event.target.value;
    
      if (searchTerm.length <= 2) {
        return;
      }
    
    //setSearchLoading(true);
    
     //Временное решение проблемы для быстрого поиска ЦИКа
     try {
       // Запрос списка yiks с сервера для текущей страницы
       const response = await getIks({
         "user_initiator": {
           "usr_token": token
         },
         "ik": {
           "name": searchTerm,
         },
         "region":{
            "region_id":filters.reg_id
         },
         "district":{
            "district_id":filters.district_id
         },
         //"page": currentPage
       });
       
       if(response.success){
         const yiks = response.data.iks;
         setMultiSelectOptions(yiks);
         //setSearchLoading(false);
       }
     } catch (error) {
       console.error("Ошибка при получении списка yiks:", error);
       // Обработка ошибки, например, отображение сообщения об ошибке пользователю
       //setSearchLoading(false);
     }
    };

  const multiSetFilters = (newValue) => {
    setSelectedMultiOptions(newValue)
    if(newValue !== null){
      setFilters({ ...filters, ik_id: newValue.ik_id })
    } else {
      setFilters({ ...filters, ik_id: null})
    }
  }


  const FiltersElements = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', ml: 2, mr: 2, mb: 2, justifyContent: 'center', gap: 2 }}> {/* Добавлен отступ с помощью gap */}
            {/* Фильтр Типа 1: Выбор из множества данных с встроенным поиском */}
            <SimplePicker
                options={regions}
                selectedOption={filters.reg_id}
                setSelectedOption={(value) => setFilters({ ...filters, reg_id: value })}
                label="Фильтр по региону"
            />

           <SimplePicker
                options={districts}
                selectedOption={filters.district_id}
                setSelectedOption={(value) => setFilters({ ...filters, district_id: value })}
                label="Фильтр по району"
            />

            <MultiSelectWithSearch
                options={multiSelectOptions}
                selectedOptions={selectedMultiOptions}
                setSelectedOptions={multiSetFilters}
                label="Фильтр по ИК"
                onSearch={handleSearchIk}
            />
            
        </Box>
    );
}
  return (
    <Box m="20px">
      <Header
        title="Добавление администраторов"
        subtitle="Добавьте пользователей админ панели"
      />
      <Box sx={{mt: 2}}>

      <DataGridFilterCustom additionalButton={handleOpenAddDialog()} searchText={searchText} setSearchText={setSearchText} onClearSearch={onClearSearch} filtersButtons={FiltersElements()}/>

      </Box>
      <Box
        m="20px 0 0 0"
        height="80vh"
        sx={dataGridStyles}
      >
        {/* {renderDeactivateDialog} */}
        {renderBlockDialog}
        <DataGrid
          rows={users}
          columns={columns}
          loading={isLoadingData}
          //components={{ Toolbar: () => <DataGridFilterCustom columns={columns} additionalButton={handleOpenAddDialog()} rows={rows} setFilteredRows={setFilteredRows} /> }}
          checkboxSelection
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          pageSize={100}
          paginationMode={'server'}
          rowCount={totalPages}
          onPageChange={pageChange}
        />
      </Box>
    </Box>
  );
};

export default AddAdmins;
