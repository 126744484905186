import React from "react";
import { css, keyframes } from 'goober';

const spin = keyframes`
  to { 
    transform: translate(-50%, -50%) rotate(360deg); 
  }
`;

export interface LoaderProps {
  className?: string;
  //color?: string;
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  duration?: string;
  textLoader?: string;
}

const LoaderSpin: React.FC<LoaderProps & React.HTMLProps<HTMLDivElement>> = ({
  className = "",
  //color = "#0d6efd",
  width = "2rem",
  height = "2rem",
  style = {},
  duration = "2s",
  textLoader = "",
  ...others
}) => {
  let resolvedWidth = typeof width === "number" ? `${width}px` : width;
  let resolvedHeight = typeof height === "number" ? `${height}px` : height;

  return (
    <div
      {...others}
      style={style}
      className={
        css`
          width: ${resolvedWidth};
          height: ${resolvedHeight};

          & div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(0);
          }
        ` + ` ${className}`
      }
    >
      <div
        className={css`
          width: ${resolvedWidth};
          height: ${resolvedHeight};
          animation: ${spin} ${duration} linear infinite;
          border: 3px solid transparent;
          border-radius: 50%;
          border-top-color: #B50000;
          border-right-color: #B50000;
        `}
      ></div>
      <div
        className={css`
          width: calc(${resolvedWidth} * 0.6);
          height: calc(${resolvedHeight} * 0.6);
          animation: ${spin} calc(${duration} * 7 / 8) linear reverse infinite;
          border: 3px solid transparent;
          border-radius: 50%;
          border-top-color: #e0e0e0;
          border-right-color: #e0e0e0;
        `}
      ></div>
      <div
        className={css`
          width: calc(${resolvedWidth} * 0.8 / 3.5);
          height: calc(${resolvedHeight} * 0.8 / 3.5);
          animation: ${spin} calc(${duration} * 3 / 4) linear infinite;
          border: 3px solid transparent;
          border-radius: 50%;
          border-top-color: black;
          border-right-color: black;
        `}
      ></div>
      {textLoader &&
      <div style={{marginTop: 80}}>
        <p>{textLoader}</p>
      </div>
      }
    </div>
  );
};

export default LoaderSpin;