export const typesIds = [
    { value: 1, name: 'ЦИК' },
    { value: 2, name: 'РИК' },
    { value: 3, name: 'ТИК' },
    { value: 4, name: 'УИК' },
  ];

  export const typesEl = [
    { value: 1, label: "Федеральный" },
    { value: 2, label: "Региональный" },
    { value: 3, label: "Муниципальный" },
    { value: 4, label: "Сельсовет" },
  ];

  export const electionSysTypes = [
    { value: 1, label: "Партсписок" },
    { value: 2, label: "Мажоритарная" },
    { value: 3, label: "Смешанная" },
  ];