import { useEffect, useState } from 'react';
import { Button, Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import Header from '../../components/Header';
import { useUserStore } from '../../stores/userStore';
import { dataGridStyles } from '../../styleGrid';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import AddNotificationForm from './addNotificationForm';
import getMySends from '../../Api/NotifResolve/getMySends';
import sendNotifications from '../../Api/NotifResolve/sendNotifications';

const NotificationPage = () => {
  const token = useUserStore((state) => state.token);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [force, setForce] = useState(true);
  const [page, setPage] = useState(0);
  let totalPages = 100000;

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchData = async (newPage = page) => {

    let payload = {
      "user_initiator": {
        "usr_token": token,
    },
    // "notification":{
    //     "is_completed":true,
    //     "is_in_progress":false,
    //     "is_failed":false,
    //     "is_scheduled":false,
    //     "type":"alert",
    //     "iks":[15],
    //     "tree_levels":[1,2]
    // },
    "page":newPage
    }

    const resp = await getMySends(payload);

    if(resp.success){
      setData(resp.data.notifications)
    } else {
      alert(resp.message)
    }
    setPage(newPage);
    setForce(false);
  }

  useEffect(() =>{ 
    if(force) fetchData() 

    // ВРЕМЕННО ОТКЛЮЧИЛ, до исправления бага
    //const interval = setInterval(fetchData, 60 * 1000); // Обновление данных каждую минуту
    //return () => clearInterval(interval);
  }, [force])

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "title",
      headerName: "Заголовок",
      width: 200,
    },
    {
      field: "short_content",
      headerName: "Краткий текст",
      width: 200,
    },
    {
      field: "tree_set",
      headerName: "Рассылка по дереву",
      width: 150,
    },
    {
      field: "status",
      headerName: "Статус рассылки",
      flex: 1,
    },
  ];

  const handleSendNotification = (notification) => {
     let payload = {
      "user_initiator": {
        "usr_token": token
      },
      "notification": notification
     }

     sendNotifications(payload).then((resp) => {
       if (resp.success){
           setOpen(false);
           setForce(true);
       } else {
         alert(resp.message)
       }
     })

     console.log('REPLANZ TEST PAYLOAD', payload)
  }


  const handleOpenNotifDialog = () => {
    return (
      <div>
        <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}}  onClick={handleClickOpen}>
           Создать рассылку
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {"Заполните данные для создания новой рассылки"}
          </DialogTitle>
          <DialogContent>
            <AddNotificationForm onSubmit={handleSendNotification}/>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  const rows = data.map(notification => {
    //console.log('TEST REPLANZ', incident)
    //const meta = incident.meta ? JSON.parse(incident.meta) : incident.created_at; //TODO: напомнить отдать мету в getList
    return {
      id: notification.notification_id,
      tree_set: notification.tree ? "Да" : "Нет",
      status: notification.fail_msg ? notification.fail_msg : 'В процессе...',
      ...notification
    };
  });

  const pageChange = (newPage) => {
    fetchData(newPage)
  }


  return (
    <Box m="20px">
    <Header title="Список рассылок" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={dataGridStyles}
      >

       <DataGrid 
          checkboxSelection 
          components={{ Toolbar: handleOpenNotifDialog }}
          rows={rows} 
          columns={columns} 
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText} 
          pageSize={100}
          paginationMode={'server'}
          rowCount={totalPages}
          onPageChange={pageChange}
       />
    </Box>
    </Box>
  );
};

export default NotificationPage;