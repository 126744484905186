import { useEffect, useState } from 'react';
import { 
  TextField, 
  Box,
  Paper,
  Typography,
  Divider,
} from '@mui/material';
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp';
import RadioButtonCheckedSharpIcon from '@mui/icons-material/RadioButtonCheckedSharp';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import PropTypes from "prop-types";

const roadmapDetails = ({ roadmap }) => {
      roadmapDetails.propTypes = {
        roadmap: PropTypes.func,
      };

      const [formData, setFormData] = useState({
        AnketTitle: '',
        AnketSubTitle: '',
        blocks: [],
        election_id: null,
      });
    
      useEffect(() => {
        if (roadmap) {
          setFormData((prev) => ({...prev, ...roadmap}));
        }
      }, [roadmap]);   
    
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Paper sx={{ backgroundColor: '#727171', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', display: 'flex', width: '80%', height: 200, marginTop: 5 }}>
          <Typography style={{fontSize: '25px', fontWeight: '600', alignSelf: 'flex-start', marginLeft: 20, marginTop: 10}}> {formData.AnketTitle}</Typography>
          <Typography style={{fontSize: '20px', fontWeight: '600', alignSelf: 'flex-start', marginLeft: 20, marginTop: 10, color: '#D9D9D9'}}> {formData.AnketSubTitle}</Typography>
          </Paper>
          {formData.blocks.map((block, blockIndex) => (
            <Box key={blockIndex} mt={3} sx={{ position: 'relative', width: '80%' }}>
              <Paper sx={{ backgroundColor: "#727171", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
              <Typography style={{fontSize: '22px', fontWeight: '600', alignSelf: 'flex-start', marginLeft: 15, marginTop: 10}}> {block.BlockTitle}</Typography>
              <Divider sx={{ height: 2, backgroundColor: "black", width: '100%', marginTop: 2 }} />
      
                {block.BlockParams.map((field, fieldIndex) => (
                <Box key={fieldIndex} mt={2} sx={{ position: 'relative', width: '100%'}}>
                  <Box sx={{alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', display: 'flex', marginLeft: 4}}>
                  <Typography style={{fontSize: '18px', fontWeight: '600', marginBottom: 10}}> {field.title}</Typography>
                  </Box>
                  {field.variants.map((variant, variantIndex) => (
                    <Box key={variantIndex} sx={{ display: 'flex', alignItems: 'center', marginLeft: 4 }}>
                      {variant.type === "QuestionOnlyOne" && 
                      <>
                      {variant.data ? (
                        <RadioButtonCheckedSharpIcon sx={{mr: 1}}/>
                      ):(
                        <RadioButtonUncheckedSharpIcon sx={{mr: 1}}/>
                      )}
                      </>
                      }

                    {variant.type === "QuestionsMore" && 
                      <>
                      {variant.data ? (
                        <CheckBoxSharpIcon sx={{mr: 1}}/>
                      ):(
                        <CheckBoxOutlineBlankSharpIcon sx={{mr: 1}}/>
                      )}
                      </>
                      }

                      {(field.type === "QuestionsMore" && variant.type === "TextInputAreaAny") && 
                      <>
                      {variant.data ? (
                        <CheckBoxSharpIcon sx={{mr: 1}}/>
                      ):(
                        <CheckBoxOutlineBlankSharpIcon sx={{mr: 1}}/>
                      )}
                      </>
                      }

                      {(field.type === "QuestionOnlyOne" && variant.type === "TextInputAreaAny") && 
                      <>
                      {variant.data ? (
                        <RadioButtonCheckedSharpIcon sx={{mr: 1}}/>
                      ):(
                        <RadioButtonUncheckedSharpIcon sx={{mr: 1}}/>
                      )}
                      </>
                      }

                      {(variant.type === "QuestionsMore" || variant.type === "QuestionOnlyOne") &&
                        <Typography style={{fontSize: '18px', fontWeight: '600', marginBottom: 10}}> {variant.title}</Typography>
                      }

                      {(variant.type === "TextInputAreaAny" || variant.type === "TextInputArea") &&
                        <TextField
                          label="Ответ"
                          variant="outlined"
                          value={variant.type === "TextInputAreaAny" ? variant.dataText : variant.data}
                          disabled
                          onChange={() => (0)}
                          margin="normal"
                          marginBottom={10}
                          multiline
                          sx={{ width: '50%' }}
                          InputProps={{ style: { fontSize: '14px', fontWeight: '600', color: 'white', borderColor: 'white' } }}
                          InputLabelProps={{ style: { fontSize: '14px', fontWeight: 'bold' } }}
                      />

                       }
                      {variant.type === "TextInput" &&
                      <TextField
                        label="Ответ"
                        variant="outlined"
                        value={variant.data}
                        disabled
                        onChange={() => (0)}
                        margin="normal"
                        sx={{ width: '50%' }}
                        InputProps={{ style: { fontSize: '14px', fontWeight: '600', color: 'white', borderColor: 'white' } }}
                        InputLabelProps={{ style: { fontSize: '14px', fontWeight: 'bold' } }}
                      />
                       }

                    </Box>
                    
                  ))}

                  {fieldIndex !== (block.BlockParams.length - 1) && <Divider sx={{ height: 2, backgroundColor: "black", width: '100%', marginTop: 3 }} /> }

                </Box>
                ))}   


                <Box width={'100%'} height={40} />

              </Paper>
            </Box>
          ))}
        </Box>
      );
    };
  
  export default roadmapDetails;