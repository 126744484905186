import React, { 
  useCallback, 
  useEffect, 
  useState 
} from 'react';
import { 
  Button, 
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import AddRoadMapHeader from './AddRoadMapHeader';
import ElectionSelection from './ElectionSelection';
import ButtonFormDefault from './ButtonFormDefault';
import RoadMapBlock from './RoadMapBlock';

const AddRoadMapForm = ({ onSubmit, roadmapData, onSave }) => {
  AddRoadMapForm.propTypes = {
    onSubmit: PropTypes.func,
    roadmapData: PropTypes.any,
    onSave: PropTypes.func,
  };

  const [formData, setFormData] = useState({
    anket_title: '',
    anket_sub_title: '',
    blocks: [],
    election_id: null,
  });

  useEffect(() => {
    if (roadmapData) {
      console.log("TEST TEST", roadmapData)
      setFormData((prev) => ({...prev, ...roadmapData}));
    }
  }, []);

  const handleInputChange = useCallback(
    (name, value, index = null, fieldIndex = null) => {
      if (index !== null && fieldIndex !== null) {
        const newBlocks = [...formData.blocks];
        newBlocks[index].BlockParams[fieldIndex][name] = value;
        setFormData({ ...formData, blocks: newBlocks });
      } else if (index !== null) {
        const newBlocks = [...formData.blocks];
        newBlocks[index][name] = value;
        setFormData({ ...formData, blocks: newBlocks });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    },
    [formData]
  );


  const addBlock = () => {
    setFormData({
      ...formData,
      blocks: [
        ...formData.blocks,
        {
          BlockTitle: '',
          BlockParams: [],
        },
      ],
    });
  };

  const addFieldToBlock = useCallback((blockIndex, fieldType) => {
    const newBlocks = [...formData.blocks];
    newBlocks[blockIndex].BlockParams.push({
      title: '',
      type: fieldType,
      variants: [{
        title: 'Вариант 1',
        type: fieldType,
      }],
    });
    setFormData({ ...formData, blocks: newBlocks });
  },[formData]);

  const removeBlock = useCallback((blockIndex) => {
    const newBlocks = [...formData.blocks];
    newBlocks.splice(blockIndex, 1);
    setFormData({ ...formData, blocks: newBlocks });
  },[formData]);


  const handleChange = useCallback(
    (e) => {
      setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    },
    [setFormData]
  );

  const handleSubmit = useCallback(() => {
    if (roadmapData) {
      onSave(formData);
    } else {
      onSubmit(formData);
    }
  }, [onSubmit, onSave, roadmapData, formData]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <AddRoadMapHeader anket_title={formData.anket_title} anket_sub_title={formData.anket_sub_title} handleInputChange={handleInputChange} />

        {formData.blocks.map((block, blockIndex) => (
          <Box key={blockIndex} mt={3} sx={{ position: 'relative', width: '80%' }} >
          <RoadMapBlock // Рендерим компонент RoadMapBlock
            key={blockIndex}
            formData={formData}
            setFormData={setFormData}
            block={block}
            blockIndex={blockIndex}
            handleInputChange={handleInputChange}
            removeBlock={removeBlock}
            addFieldToBlock={addFieldToBlock}
          />
          </Box>
        ))}
          
      <Box mt={3}>
        <ButtonFormDefault 
           icon={<AddSharpIcon />}
           onClick={addBlock}
           text='Добавить раздел'
        />
      </Box>

     <ElectionSelection formData={formData} handleChange={handleChange} />

      <Box mt={3}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          fullWidth
          sx={[
            {
              '&:hover': {
                color: '#970D0D',
                backgroundColor: 'white',
              },
            },
            { color: 'white', backgroundColor: '#970D0D', marginBottom: 5, fontSize: 16, fontWeight: '700' },
          ]}
        >
          Сохранить
        </Button>
      </Box>

    </Box>
  );
};


export default React.memo(AddRoadMapForm);