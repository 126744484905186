import {useState} from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

import { Button, TextField } from '@mui/material';

const addPartiesForm = ({ addPartie }) => {
  addPartiesForm.propTypes = {
    addPartie: PropTypes.func
  };
  const [partie, setPartie] = useState({
    name: '',
    short_name: '',
  });

  const handleChange = (e) => {
    console.log(e)
    setPartie({ ...partie, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addPartie(partie);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
            //autoFocus
            margin="dense"
            name="name"
            label="Полное название партии"
            value={partie.name}
            onChange={handleChange}
            fullWidth
            //variant="standard"
          />
          <TextField
            //autoFocus
            margin="dense"
            name="short_name"
            label="Сокращенное название партии"
            value={partie.short_name}
            onChange={handleChange}
            fullWidth
            //variant="standard"
          />
      <Button type="submit" variant="contained" color="primary" margin="normal">
        Добавить
      </Button>
    </form>
  );
};

export default addPartiesForm;