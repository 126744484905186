import { useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, IconButton, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import { useUserStore } from "../../stores/userStore";
import updatePasswordSelf from "../../Api/Users/updatePasswordSelf";
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';

const ChangePasswordModal = ({ open, onClose }) => {
  ChangePasswordModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
  };
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const token = useUserStore((state) => state.token);
  const [editMode, setEditMode] = useState({ oldPassword: true, newPassword: true, confirmPassword: true});

  const toggleEdit = (field) => {
    setEditMode(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const handleSave = () => {
    if (newPassword !== confirmPassword) {
      alert("Новый пароль введен неверно");
      return;
    }
     changePassword();
  };

  const changePassword = async () => {

    let payload = {
      "user_initiator":{
        "usr_token":token
      },
      "user":{
         "old_password": oldPassword,
         "usr_password": newPassword
      }
    }

    await updatePasswordSelf(payload).then((res) => {
      if (res.success){
        onClose();
      } else {
        alert("Старый пароль неверный!");
        return;
      }
    })
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Сменить пароль</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Старый пароль"
          fullWidth
          variant="outlined"
          type={editMode.oldPassword  ? "password" : "text"}
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <>
              <InputAdornment position="end">
                <IconButton onClick={() => toggleEdit('oldPassword')} edge="end">
                {editMode.oldPassword ?
                  <VisibilitySharpIcon style={{ color: 'red' }} />
                  :
                  <VisibilityOffSharpIcon style={{ color: 'green' }} />
                  }
                </IconButton>
              </InputAdornment>
              </>
            ),
          }}
        />
        <TextField
          margin="dense"
          label="Новый пароль"
          fullWidth
          variant="outlined"
          type={ editMode.newPassword ? "password" : "text"}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <>
              <InputAdornment position="end">
                <IconButton onClick={() => toggleEdit('newPassword')} edge="end">
                {editMode.newPassword ?
                  <VisibilitySharpIcon style={{ color: 'red' }} />
                  :
                  <VisibilityOffSharpIcon style={{ color: 'green' }} />
                  }
                </IconButton>
              </InputAdornment>
              </>
            ),
          }}
        />
        <TextField
          margin="dense"
          label="Подтверждение нового пароля"
          fullWidth
          variant="outlined"
          type={editMode.confirmPassword ? "password" : "text"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <>
              <InputAdornment position="end">
                <IconButton onClick={() => toggleEdit('confirmPassword')} edge="end">
                  {editMode.confirmPassword ?
                  <VisibilitySharpIcon style={{ color: 'red' }} />
                  :
                  <VisibilityOffSharpIcon style={{ color: 'green' }} />
                  }
                </IconButton>
              </InputAdornment>
              </>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">Отмена</Button>
        <Button onClick={handleSave} variant="contained" color="primary">Сохранить</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordModal;
