import { Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonToolTip from './ButtonToolTip';

const FormFooter = ({ blockIndex, removeBlock }) => {
  return (
    <>
      {/* FOOTER */}
      <Box sx={{ width: '100%', height: '50px', }}>
        <Divider sx={{ height: "1px", backgroundColor: 'black' }} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignSelf: 'center', height: '100%'}}>
          <ButtonToolTip 
             text= 'Удалить раздел'
             onClick={() => removeBlock(blockIndex)}
             tooltipStyle={{ fontSize: 14, fontWeight: 'bold' }}
             buttonStyle={{mt: 1, color: "#970D0D", border: "none" }}
             icon={<DeleteIcon />}
          />
        </Box>
      </Box>
    </>
  );
};

FormFooter.propTypes = {
  blockIndex: PropTypes.number.isRequired,
  removeBlock: PropTypes.func.isRequired,
};

export default FormFooter;