import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import login from '../../Api/Login/login';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import { useUserDataStore, useUserStore } from '../../stores/userStore';
import { tokens } from '../../theme';
import getMyElections from '../../Api/Users/myElections';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {' Разработано отделом ЦК КПРФ по контролю за выборами © '}
      <Link color="inherit" href="https://kprf.ru/">
        RedControl
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
///const defaultTheme = createTheme();

export default function Login() {
   const theme = useTheme();
   const colors = tokens(theme.palette.mode);
   const [error, setError] = useState("");
   const setToken = useUserStore((state) => state.setToken);
   const setUser = useUserDataStore((state) => state.setUser);
   const navigate = useNavigate();


  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const bodyResponse = {
        user_initiator: {
          usr_email: data.get('email'),
          usr_password: data.get('password'),
        },
      }

    await login(bodyResponse).then((response)=>{
        if (response.success) {
          const user = response.data.user;
          if (user.access_data.access_id >= 45) {
            setError('Вы не являетесь администратором системы. Вход запрещен');
            return
          } else {
          getMyElections({ "user_initiator": { "usr_token": user.usr_token } }).then( (resp) => {
            if (resp.success) {
              const election = {
                  "election_user": {
                      ...resp.data.elections[0],
                  }
              };
  
              const userData = { ...user, ...election };
              setToken(response.data.user.usr_token);
              setUser(userData);
              // You can also update other user-related data in the store if needed
              navigate('/dashboard');
             } 
            })
          }
        } else {
          setToken(undefined);
          setError(response.message);
        }
      })
    } catch (error) {
      console.error("An error occurred during authentication:", error);
      setToken(undefined);
      setError("Произошла ошибка во время аутентификации");
    }
  };

  return (
    <Box >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: colors.redAccent[1000] }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{textAlign: 'center'}}>
            Вход в админ-панель Красного контроля
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Почта"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Пароль"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Запомнить"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              //color="error"
              sx={{ mt: 3, mb: 2, bgcolor:  colors.redAccent[1000] }}
            >
              Войти
            </Button>

            <>
            {error && (
            <Alert variant="standard" color="error" style={{marginTop: 10, marginBottom: 10}}>
              {error}
            </Alert>
             )}
             </>

          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </Box>
  );
}


// import { useState } from "react";
// import { Box, TextField, Button, Alert } from "@mui/material";
// import CheckIcon from "@mui/icons-material/Check";
// import { useUserStore, useUserDataStore } from '../../stores/userStore';
// import { useNavigate } from "react-router-dom";
// import login from "../../Api/Login/login";

// const Login = () => {
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState("");
//   const setToken = useUserStore((state) => state.setToken);
//   const setUser = useUserDataStore((state) => state.setUser);
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const bodyResponse = {
//         user_initiator: {
//           usr_email: username,
//           usr_password: password,
//         },
//       }

//     await login(bodyResponse).then((response)=>{
//         if (response.success) {
//           setToken(response.data.user.usr_token);
//           setUser(response.data.user);
  
//           // You can also update other user-related data in the store if needed
//           navigate('/');
//         } else {
//           setToken(undefined);
//           setError(response.message);
//         }
//       })
//     } catch (error) {
//       console.error("An error occurred during authentication:", error);
//       setToken(undefined);
//       setError("Произошла ошибка во время аутентификации");
//     }
//   };

//   return (
//     <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//       <Box>
//         <h2>Вход</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="form-group">
//             <TextField
//               variant="outlined"
//               type="text"
//               margin="normal"
//               style={{botderColor: 'black', backgroundColor: 'black', color: 'white'}}
//               label="Username"
//               value={username}
//               onChange={(e) => setUsername(e.target.value)}
//             />
//           </div>
//           <div className="form-group">
//             <TextField
//               variant="outlined"
//               style={{botderColor: 'black', backgroundColor: 'black', color: 'white'}}
//               margin="normal"
//               type="password"
//               label="Password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />
//           </div>
//           {error && (
//             <Alert variant="standard" color="error" style={{marginTop: 10, marginBottom: 10}}>
//               {error}
//             </Alert>
//           )}
//           <Button
//             variant="contained"
//             type="submit"
//             startIcon={<CheckIcon />}
//           >
//             Войти
//           </Button>
//         </form>
//       </Box>
//     </Box>
//   );
// };

// export default Login;
