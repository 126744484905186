import { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useTheme,
  MenuItem,
  Select,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Header from "../../../../components/Header";
import PropTypes from "prop-types";
import { tokens } from "../../../../theme";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import getCantidates from "../../../../Api/Candidates/getCandidates";
import { useUserDataStore } from "../../../../stores/userStore";

const SendProtocol = ({ protocol, onSubmit, onClose }) => {
  SendProtocol.propTypes = {
    protocol: PropTypes.any,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isDupe, setIsDupe] = useState("");
  const [dupeReason, setDupeReason] = useState("");
  const [upperPartData, setUpperPartData] = useState({});
  const [lowerPartData, setLowerPartData] = useState({});
  const [media, setMedia] = useState([]);
  const userInfo = useUserDataStore((state) => state.user);

  const [candidates, setCandidates] = useState('')

  const handleFileChange = (event) => {
    const files = event.target.files;
    setMedia((prevMedia) => [...prevMedia, ...files]);
  };

  const handleAttachPhoto = () => {
    console.log("handleAttachPhoto called");
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.multiple = true;
    fileInput.accept = "image/*"; // Позволяет выбирать только изображения
    fileInput.addEventListener("change", handleFileChange);
    fileInput.click(); // Вот здесь вызывается метод click() для открытия диалогового окна выбора файлов
  };

  const handleSubmit = () => {
    const updatedProtocol = {
      ...protocol,
      isDupe,
      dupeReason,
      home_ik: userInfo.home_ik,
      upperPartFields: upperPartData,
      lowerPartFields: lowerPartData,
      media,
    };

    onSubmit(updatedProtocol);
  };

  useEffect(() => {
    getCantidates({}).then((resp)=>{
      if (resp.success){
         setCandidates(resp.data.candidates)
      } else {
        setCandidates([])
        alert(resp.message)
     }
    })

  },[])

  const handleUpperPartChange = (fieldData, value) => {
    setUpperPartData((prevData) => ({
      ...prevData,
      [fieldData]: value,
    }));
  };

  const handleLowerPartChange = (fieldData, value) => {
    setLowerPartData((prevData) => ({
      ...prevData,
      [fieldData]: value,
    }));
  };

  return (
    <Box m="20px">
      <Header
        title={`${protocol?.ProtocolName}`}
        backButton={
          <IconButton color="inherit" onClick={onClose} aria-label="close">
            <ArrowBackRoundedIcon />
          </IconButton>
        }
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Typography
          variant="h6"
          color="textPrimary"
          fontWeight="bold"
          sx={{ marginTop: "5px", marginBottom: "5px", marginLeft: "10px" }}
        >
          Данные протокола
        </Typography>
        <Box>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              {/* <Typography
                sx={{ fontWeight: "bold", color: "white", marginRight: "10px" }}
              >
                Откуда протокол:
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                label=""
                fullWidth
                value={protocolIs}
                onChange={(e) => setProtocolIs(e.target.value)}
                style={{ marginBottom: "10px" }}
              /> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", color: "white", marginRight: "10px" }}
              >
                Тип протокола:
              </Typography>
              <Select
                value={isDupe}
                onChange={(e) => setIsDupe(e.target.value)}
                variant="outlined"
                size="small"
                style={{ width: "150px" }}
              >
                <MenuItem key ={1} value={false} >Первичный</MenuItem>
                <MenuItem key ={2} value={true} >Повторный</MenuItem>
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              {isDupe && (
                <TextField
                  variant="outlined"
                  size="small"
                  label="Укажите причину повторного протокола"
                  fullWidth
                  value={dupeReason}
                  onChange={(e) => setDupeReason(e.target.value)}
                  style={{ marginBottom: "10px" }}
                />
              )}
            </Box>
          </Box>
          <Typography
            variant="h6"
            color="textPrimary"
            fontWeight="bold"
            sx={{ marginTop: "5px", marginBottom: "5px", marginLeft: "10px" }}
          >
            Верхняя часть протокола
          </Typography>
          {protocol.upperPartFields.map((field, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextField
                key={index}
                style={{ width: "80px", marginRight: "10px" }}
                variant="outlined"
                size="small"
                placeholder={""}
                disabled
                value={field.Number}
              />
              <TextField
                key={index}
                style={{ flex: 1, width: "80px", marginRight: "10px" }}
                variant="outlined"
                size="small"
                placeholder={""}
                disabled
                value={field.Title}
              />
              <TextField
                key={index}
                style={{ width: "80px", marginRight: "10px" }}
                variant="outlined"
                size="small"
                placeholder={""}
                onChange={(e) =>
                  handleUpperPartChange(field.Data, e.target.value)
                }
                value={field.Data}
              />
            </Box>
          ))}

          <Typography
            variant="h6"
            color="textPrimary"
            fontWeight="bold"
            sx={{ marginTop: "5px", marginBottom: "5px", marginLeft: "10px" }}
          >
            Нижняя часть протокола
          </Typography>

          {protocol.lowerPartFields.map((field, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextField
                key={index}
                style={{ width: "80px", marginRight: "10px" }}
                variant="outlined"
                size="small"
                placeholder={""}
                disabled
                value={field.Number}
              />
              <TextField
                key={index}
                style={{ flex: 1, width: "80px", marginRight: "10px" }}
                variant="outlined"
                size="small"
                placeholder={""}
                disabled
                value={candidates.length > 0 ? candidates.find((item) => item.candidate_id === field.candidate_id).candidate_name : field.candidate_id}
              />
              <TextField
                key={index}
                style={{ width: "80px", marginRight: "10px" }}
                variant="outlined"
                size="small"
                placeholder={""}
                onChange={(e) =>
                  handleLowerPartChange(field.Data, e.target.value)
                }
                value={field.Data}
              />
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px",
            paddingBottom: "30px",
          }}
        >
          <Box>
         {media.map((file, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <Typography variant="body1" sx={{ marginRight: "10px" }}>
                  {file.name}
                </Typography>
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => {
                    const updatedFiles = media.filter((_, i) => i !== index);
                    setMedia(updatedFiles);
                  }}
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleAttachPhoto}
          >
            Добавить фото протокола
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmit}
            sx={{ marginTop: "10px" }}
          >
            Отправить
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SendProtocol;
