// Функция для определения типа файла по его URL
export const getFileType = (url: any) => {
    const extension = url.split('.').pop().toLowerCase(); // Получаем расширение файла и приводим его к нижнему регистру
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']; // Расширения изображений
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv']; // Расширения видео
  
    // Если расширение файла входит в список расширений изображений, возвращаем 'image'
    if (imageExtensions.includes(extension)) {
      return 'image';
    }
    // Если расширение файла входит в список расширений видео, возвращаем 'video'
    else if (videoExtensions.includes(extension)) {
      return 'video';
    }
    // Если расширение файла не соответствует ни одному известному типу, возвращаем null
    else {
      return null;
    }
  };