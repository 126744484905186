export const CTYPE = {
    0:  [35, 5, 4, 1], // root
    1:  [36, 4, 3, 1], // central super
    2:  [37, 4, 2, 1], // central admin
    3:  [38, 4, 1, 1], // central mod
    4:  [39, 3, 3, 2], // reg super
    5:  [40, 3, 2, 2], // regional admin
    6:  [41, 3, 1, 2], // regional mod
    7:  [42, 2, 3, 3], // district super
    8:  [43, 2, 2, 3], // district admin
    9:  [44, 2, 1, 3], // district mod
    10: [45, 1, 3, 4], // ik head
    11: [46, 1, 2, 4], // observer
    12: [47, 1, 1, 4], // unverified
    13: [48, 0, 0, 4], // restricted
  }

export const determineRole = (accessId) => {
  switch (accessId) {
    case 35: // ROOT
      return "Технический специалист (ROOT)"
    case 36: // Центральный super
       return "Центральный супер админ"
    case 37: // Центральный admin
       return "Центральный админ"
    case 38: // Центральный mod
       return "Центральный модератор"
    case 39: // Региональный super
       return "Региональный супер админ"
    case 40: // Региональный admin
       return "Региональный админ"
    case 41: // Региональный mod
       return "Региональный модератор"
    case 42: // Районный super
      return "Районный супер админ";
    case 43: // Районный admin
      return "Районный админ";
    case 44: // Районный mod
      return "Районный модератор";
    case 45: // Старший по УИК
      return "Старший по УИК";
    case 46: // Районный mod
      return "Участник наблюдения";
    case 47: // Районный mod
      return "Неверифицированный пользователь";
    default:
      return null;
  }
};

export const determineRoleCTYPE = (accessId) => {
  switch (accessId) {
    case 35: // ROOT
       return CTYPE[0]
    case 36: // Центральный super
       return CTYPE[1]
    case 37: // Центральный admin
       return CTYPE[2]
    case 38: // Центральный mod
       return CTYPE[3]
    case 39: // Региональный super
       return CTYPE[4]
    case 40: // Региональный admin
       return CTYPE[5]
    case 41: // Региональный mod
       return CTYPE[6]
    case 42: // Районный super
       return CTYPE[7]
    case 43: // Районный admin
      return  CTYPE[8]
    case 44: // Районный mod
      return  CTYPE[9]
    case 45: // Старший по УИК
      return  CTYPE[10]
    case 46: // Участник наблюдение
      return  CTYPE[11]
    case 47: // Неверифицированный пользователь
      return  CTYPE[12]
    default:
      return null;
  }
};

export const Roles = [
   { value: 36 , name: "Центральный супер админ"},
   { value: 37 , name: "Центральный админ"},
   { value: 38 , name: "Центральный модератор"},
   { value: 39 , name: "Региональный супер админ"},
   { value: 40 , name: "Региональный админ"},
   { value: 41 , name: "Региональный модератор"},
   { value: 42 , name: "Районный супер админ"},
   { value: 43 , name: "Районный админ"},
   { value: 44 , name: "Районный модератор"},
   { value: 45 , name: "Старший по УИК"},
   { value: 46 , name: "Участник наблюдения"},
   { value: 47 , name: "Неверифицированный пользователь"},
]