import { Select, MenuItem, InputLabel, Box, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from "prop-types";

const SimplePicker = ({ options, selectedOption, setSelectedOption, label }) => {
    SimplePicker.propTypes = {
        options: PropTypes.array,
        selectedOption: PropTypes.any,
        setSelectedOption: PropTypes.func,
        label: PropTypes.string
      };
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '200px' }}>
          <InputLabel style={{ color: "#970D0D", fontSize: 12, fontWeight: 'bold' }}>{label}</InputLabel>
          <Select
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            displayEmpty
            style={{ height: 35, border: '1px solid black', paddingRight: '30px' }} // добавляем отступ справа для крестика
            sx={{ 
              alignContent: 'center', 
              justifyContent: 'center', 
              fontWeight: '600', 
              fontSize: '14px', 
              color: 'black' 
            }}
          >
            {options && options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {selectedOption && (
            <IconButton 
                onClick={() => setSelectedOption(null)} 
                size="small" 
                sx={{ 
                   position: 'absolute', 
                   right: 5, 
                   top: 21, // Позиционируем крестик рядом с Select
                   color: 'black' // Делаем иконку черной
                }}
            >
              <ClearIcon fontSize="small" sx={{ color: 'black' }} /> {/* Устанавливаем цвет крестика */}
            </IconButton>
          )}
        </Box>
      );
    };

export default SimplePicker;