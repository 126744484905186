import { useState } from "react";
import {
  TextField,
  Button,
} from "@mui/material";
import PropTypes from 'prop-types';

const BlockUserModal = ({ onBlockUser, userID }) => {
  BlockUserModal.propTypes = {
    onBlockUser: PropTypes.func.isRequired,
    userID: PropTypes.number,
  };
  const [blockReason, setBlockReason] = useState("");
  const [blockTime, setBlockTime] = useState("");

  const handleBlockUser = () => {
    onBlockUser(userID, blockReason, blockTime);
  };

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    
    // Добавляем секунды и временную зону к строке времени
    const enhancedTime = `${selectedTime}:00Z`;

    // Создаем объект Date из выбранного времени
    const dateObject = new Date(enhancedTime);

    // Форматируем дату в желаемый формат
    const formattedTime = dateObject.toISOString();

    // Устанавливаем отформатированное значение в state
    setBlockTime(formattedTime);
  };

  return (
    <>
        <TextField
          label="Причина блокировки"
          fullWidth
          value={blockReason}
          onChange={(e) => setBlockReason(e.target.value)}
          margin="normal"
        />
         <TextField
      label="Время блокировки"
      type="datetime-local"
      fullWidth
      value={blockTime.slice(0, 16)}  // Обрезаем секунды и временную зону для отображения в поле ввода
      onChange={handleTimeChange}
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
    />
        <Button variant="contained" color="primary" onClick={handleBlockUser}>
          Блокировать
        </Button>
        </>
  );
};

export default BlockUserModal;