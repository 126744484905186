import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

import { Button, FormControl, TextField } from '@mui/material';

const addRegionForm = ({ addRegion }) => {
  addRegionForm.propTypes = {
    addRegion: PropTypes.func
  };
  const [regions, setRegions] = React.useState({
    id: 0,
    name: '',
  });

  const handleChange = (e) => {
    console.log(e)
    setRegions({ ...regions, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addRegion(regions);
  };


  return (
    <form onSubmit={handleSubmit}>
      <TextField
            //autoFocus
            margin="dense"
            name="name"
            label="Название региона"
            value={regions.name}
            onChange={handleChange}
            fullWidth
            //variant="standard"
          />
      <FormControl fullWidth margin="normal">
      </FormControl>
      <Button type="submit" variant="contained" color="primary" margin="normal">
        Добавить
      </Button>
    </form>
  );
};

export default addRegionForm;