import { useEffect, useState } from 'react';
import { Box, Button, Dialog ,DialogContent ,DialogTitle, useTheme  } from '@mui/material';
//import axios from 'axios';
import { tokens } from '../../theme';
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
import AddStatusesForm from "./addStatusesForm"
//import enumRegions from "../../Api/Regions/enumRegions"
//import createRegions from '../../Api/Regions/createRegions';
import { useUserStore } from "../../stores/userStore";
//import deleteRegions from '../../Api/Regions/deleteRegions';
import getStatuses from '../../Api/Statuses/getStatuses';
import createStatus from '../../Api/Statuses/createStatus';
import deleteStatus from '../../Api/Statuses/deleteStatus';
import { dataGridStyles } from '../../styleGrid';

const addRegions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [statuses, setStatuses] = useState([]);
  const [open, setOpen] = useState(false);
  const [force, setForce] = useState(true);
  const token = useUserStore((state) => state.token);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "name",
      headerName: "Название статуса",
      width: 400,
      cellClassName: "name-column--cell",
    },
    {
      field: "type",
      headerName: "Тип статуса",
      width: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "actions",
      headerName: "Управление",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          style={{color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
          onClick={() => handleDeleteRegion(params.row.id)}
        >
          Удалить
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if(force){
      getStatuses({}).then((response) => {
        if(response.success){
          setStatuses(response.data.statuses_user);
          setForce(false)
        } else {
          setStatuses([]);
          alert(response.message)
          setForce(false)
        }
      });
    }
  }, [force]);

  const rows = statuses.map(status => ({
    id: status.id,
    name: status.name,
    type: status.type,
  }));

  const addStatus = async (status) => {
    await createStatus({
      "user_initiator":{
          "usr_token": token
      },
      "status_user":{
          "name": status.name,
          "type": status.type
      }
  }).then(response => {
    if (response.data.status === 'ok'){
      setForce(true);
      handleClose();
    }
   })
  };

  const handleDeleteRegion = async (statusId) => {
    await deleteStatus({
      "user_initiator":{
          "usr_token": token
      },
      "status_user":{
          "id":statusId
      }
    }).then(response => {
        if (response.data.status === 'ok'){
          setForce(true);
        }
    })
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAddDialog = () => {
    return(
      <div>
      <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleClickOpen}>
        Добавить статус
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Название статуса"}
        </DialogTitle>
        <DialogContent>
           <AddStatusesForm addStatus={addStatus} />
        </DialogContent>
      </Dialog>
    </div>
    )
  }

  return (
    <Box m="20px">
    <Header title="Добавление статусов пользователей" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={dataGridStyles}
      >
       <DataGrid rows={rows} columns={columns} components={{ Toolbar: handleOpenAddDialog  }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText} />
    </Box>
    </Box>
  );
};

export default addRegions;