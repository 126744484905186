import { Box, Paper, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import FieldInput from './FieldInput';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import FormFooter from './FormFooter';
import ButtonFormDefault from './ButtonFormDefault';
import React from 'react';

const RoadMapBlock = ({formData, setFormData, block, blockIndex, handleInputChange, addFieldToBlock, removeBlock }) => {
  
  return (
    <Box key={blockIndex} mt={3} sx={{ position: 'relative', width: '100%' }}>
      <Paper sx={{ backgroundColor: "#727171", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', display: 'flex'}}>
        <TextField
          label="Заголовок раздела"
          variant="outlined"
          value={block.BlockTitle}
          name="BlockTitle"
          onChange={(event) => handleInputChange(event.target.name, event.target.value, blockIndex)}
          margin="normal"
          sx={{ width: '90%' }}
          InputProps={{ style: { fontSize: '16px', fontWeight: '600' } }}
          InputLabelProps={{ style: { fontSize: '16px', fontWeight: 'bold' } }}
        />
       
        {block.BlockParams.map((field, fieldIndex) => (
          <FieldInput
            formData={formData}
            setFormData={setFormData}
            key={fieldIndex}
            field={field}
            blockIndex={blockIndex}
            fieldIndex={fieldIndex}
            handleInputChange={handleInputChange}
          />
        ))}

        <Box mt={2} display="flex">

          <ButtonFormDefault 
             text='Добавить вопрос'
             icon={<AddSharpIcon />}
             onClick={() => addFieldToBlock(blockIndex, 'QuestionOnlyOne')}
          />

        </Box>
        <FormFooter blockIndex={blockIndex} removeBlock={removeBlock} />
      </Paper>
    </Box>
  );
};

RoadMapBlock.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  block: PropTypes.object.isRequired,
  blockIndex: PropTypes.number.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  removeBlock: PropTypes.func.isRequired,
  addFieldToBlock: PropTypes.func.isRequired,
};

export default React.memo(RoadMapBlock);