import {
    Typography,
    Menu,
    MenuItem,
    Divider,
    IconButton,
    useTheme,
  } from '@mui/material';
  import DeleteIcon from '@mui/icons-material/Delete';
  import PropTypes from 'prop-types';
  import { tokens } from '../../theme';
  
  const NotificationsMenu = ({ notifications, onClose, anchorEl }) => {
    NotificationsMenu.propTypes = {
      notifications: PropTypes.array,
      onClose: PropTypes.func,
      anchorEl: PropTypes.any
    };
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    const renderNotifications = () => {
      if (notifications.length === 0) {
        return (
          <MenuItem>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <Typography variant="body1" color="textSecondary" style={{ color: 'black', }} >
                Нет уведомлений
              </Typography>
            </div>
          </MenuItem>
        );
      }
  
      return (
        <div style={{ flex: 1, overflowY: 'auto', scrollbarWidth: 'none', scrollbarColor: 'transparent transparent' }}>
          {notifications.map((notification, index) => (
            <div key={index}>
              <MenuItem style={{ backgroundColor: 'white', color: 'black' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <div>
                    <Typography variant="body1" style={{ fontWeight: '400', fontSize: 14, color: 'black'}}>
                      {notification.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" style={{ fontWeight: '400', fontSize: 13, color: 'black'}}>
                      {notification.time}
                    </Typography>
                  </div>
                  <IconButton>
                    <DeleteIcon style={{ color: colors.redAccent[1000] }} />
                  </IconButton>
                </div>
              </MenuItem>
              <Divider style={{ width: '100%', backgroundColor: 'black' }} />
            </div>
          ))}
        </div>
      );
    };
  
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        PaperProps={{
          style: {
            borderRadius: '15px',
            width: '541px',
            height: '220px',
            //padding: '16px',
            backgroundColor: 'white',
            overflow: 'hidden', // Отключаем прокрутку для всего меню
          },
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: '220px',  width: '541px', }}>
          <div style={{ display: 'flex', marginBottom: '5px',}}>
            <Typography
              variant="h6"
              style={{
                fontWeight: '600',
                fontSize: '20px',
                lineHeight: '24.2px',
                color: 'black',
                marginLeft: 16,
              }}
            >
              Уведомления
            </Typography>
          </div>
          <Divider style={{ backgroundColor: 'black' }} />
            {renderNotifications()}
        </div>
      </Menu>
    );
  };
  
  export default NotificationsMenu;