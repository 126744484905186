import { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  //Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
//import TransferList from '../../../components/TransferList';
import enumRegions from '../../../Api/Regions/enumRegions';
//import enumIkFromTypeUser from '../../../Api/HomeIk/getIkInfo';
import { useUserStore } from '../../../stores/userStore';
import createDistricts from '../../../Api/Districts/createDistricts';

const AddDistrictForm = ({ onDistrictAdded }) => {
  AddDistrictForm.propTypes = {
    onDistrictAdded: PropTypes.func,
  };

  const [districtName, setDistrictName] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [regions, setRegions] = useState([]);
  //const [availableUIKs, setAvailableUIKs] = useState([]);
  //const [selectedUIKs, setSelectedUIKs] = useState([]);
  //const userInfo = useUserDataStore((state) => state.user);
  const token = useUserStore((state) => state.token);

  useEffect(() => {
    enumRegions({})
      .then(response => {
        setRegions(response.data.regions);
      })
      .catch(error => {
        console.error('Error fetching regions:', error);
      });
  }, []);

  const handleAddDistrict = async ()=> {
    if (districtName && selectedRegion) {
      await createDistricts({
        "user_initiator":{
        "usr_token": token
          },
        "district":{
            "reg_id":selectedRegion,
            "district_name": districtName,
             "meta":"{\"pog_mode\":true}"
        }})
        .then((response) => {
          if (response.success){
            onDistrictAdded();
            setSelectedRegion('');
            //setSelectedUIKs([]);
          } else {
            alert(response.message)
          }
        })
        .catch(error => {
          console.error('Error adding district:', error);
          alert(error.message)
        });
    }
  };

  // Выбор и добавление уика в список
  // const handleTransferListChange = (event) => {
  //   setSelectedUIKs(event.target.value);
  // };

  const getDataYik = async (reg_id) => {
    setSelectedRegion(reg_id)

    //Уже готово для использование. Запрашивает список уиков по региону и type_id
    // await enumIkFromTypeUser({
    //   "region":{
    //     "region_id":reg_id
    //   },
    //   "ik_type":{
    //     "type_id":userInfo.ik_data.type_id
    //   }
    // }).then((response) => {
    //   if (response.success){
    //     setAvailableUIKs(response.data.iks);
    //   }
    // })
  }

  // ЛИСТ для выбора уика
  //<Typography variant="subtitle1" gutterBottom>
   //     Выберите УИКи для района:
    //  </Typography>
    //  <TransferList
    //    leftTitle="Доступные УИКи"
     //   rightTitle="Выбранные УИКи"
     //   data={availableUIKs}
      //  selectedData={selectedUIKs}
     //   onChange={handleTransferListChange}
     // />

  return (
    <div>
      <TextField
        label="Название района"
        value={districtName}
        onChange={e => setDistrictName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Выберите регион</InputLabel>
        <Select
          value={selectedRegion}
          onChange={e => 
            getDataYik(e.target.value)
          }
        >
          {regions.map(region => (
            <MenuItem key={region.region_id} value={region.region_id}>{region.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" onClick={handleAddDistrict}>
        Добавить район
      </Button>
    </div>
  );
};

export default AddDistrictForm;