import { Box, InputBase, useTheme, IconButton } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from '../../theme';
import PropTypes from "prop-types";
import ClearSharpIcon from '@mui/icons-material/ClearSharp';

const FilterComponent = ({ searchString, onChange, isSearch = false, placeholderSearch = "Поиск", onClickSearch, onClickSearchClean, styleSearch, styleContainer, filtersButtons, additionalButton }) => {
  FilterComponent.propTypes = {
    searchString: PropTypes.string,
    onChange: PropTypes.func,
    onClickSearch: PropTypes.func,
    onClickSearchClean: PropTypes.func,
    isSearch: PropTypes.bool,
    placeholderSearch: PropTypes.string,
    styleSearch: PropTypes.any,
    styleContainer: PropTypes.any,
    filtersButtons: PropTypes.element,
    additionalButton: PropTypes.element
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ backgroundColor: 'white', p: 3, borderRadius: 5, boxShadow: "rgb(145 158 171 / 50%) 0px 0px 2px 0px, rgb(145 158 171 / 50%) 0px 16px 32px -4px", width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', ...styleContainer }}>
      {/* Верхняя часть: поиск и фильтры */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', mb: 2 }}>
        {isSearch && (
          <Box sx={{ display: 'flex', backgroundColor: colors.primary[1400], borderRadius: '3px', width: '30%', height: 40, mr: 3, ...styleSearch }}>
            <InputBase sx={{ ml: 2, flex: 1 }} placeholder={placeholderSearch} style={{ color: colors.primary[1000] }} onChange={onChange} value={searchString} />
            <IconButton type="button" sx={{ p: 1 }} onClick={searchString ? onClickSearchClean : onClickSearch}>
              {searchString ? <ClearSharpIcon style={{ color: 'black' }} /> : <SearchIcon />}
            </IconButton>
          </Box>
        )}

        {/* Кнопки фильтров */}
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {filtersButtons}
        </Box>
      </Box>

      {/* Нижняя часть: additionalButton */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
        {additionalButton}
      </Box>
    </Box>
  );
};

export default FilterComponent;