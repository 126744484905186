//import { useState } from "react";
import {
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
  } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import getCantidates from "../../../Api/Candidates/getCandidates";

const ProtocolDetails = ({ protocol }) => {
    ProtocolDetails.propTypes = {
        protocol: PropTypes.func,
      };

      const [candidates, setCandidates] = useState([])

      useEffect(()=>{

        getCantidates({}).then((resp) => {
          if(resp.success){
            setCandidates(resp.data.candidates);
          }
        })

      },[])
  
    return (
        <Box display="flex">
        <Box flex="1" p={2}>
          <Typography variant="h6" style={{fontWeight:'700', fontSize: 16}}>
            Верхняя часть протокола:
          </Typography>
          <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{fontSize: 14, fontWeight: '600'}}>Номер</TableCell>
              <TableCell style={{fontSize: 14, fontWeight: '600'}}>Строка</TableCell>
              <TableCell style={{fontSize: 14, fontWeight: '600'}}>Данные</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {protocol.election_data.protocol_schema.upperPartFields.map((field, index) => (
              <TableRow key={index}>
                <TableCell style={{fontSize: 14, fontWeight: '600'}}>{field.Number}</TableCell>
                <TableCell style={{fontSize: 14, fontWeight: '600'}}>{field.Title}</TableCell>
                <TableCell style={{fontSize: 14, fontWeight: '600'}}>{field.data}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6" style={{fontWeight:'700', fontSize: 16, marginTop: 5}}>
        Нижняя часть протокола:
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{fontSize: 14, fontWeight: '600'}}>Номер</TableCell>
              <TableCell style={{fontSize: 14, fontWeight: '600'}}>Кандидат</TableCell>
              <TableCell style={{fontSize: 14, fontWeight: '600'}}>Данные</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {protocol.election_data.protocol_schema.lowerPartFields.map((field, index) => (
              <TableRow key={index}>
                <TableCell style={{fontSize: 16, fontWeight: '700'}}>{field.Number}</TableCell>
                <TableCell style={{fontSize: 14, fontWeight: '600'}}>{candidates.find(
                        (candidate) => candidate.candidate_id === field.candidate_id
                    )?.candidate_name} </TableCell>
                <TableCell style={{fontSize: 14, fontWeight: '600'}}>{field.data}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
        <Box flex="1" p={2} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" style={{fontWeight:'700', fontSize: 20, marginBottom: 10}}>Фотография протокола</Typography>
          {protocol.file_links && protocol.file_links.length > 0 ? (
            <Box display="flex" alignItems="center">
              <Box display="flex">
                <img
                  src={protocol.file_links[0]}
                  style={{ maxHeight: '75vh', justifyContent: 'center'}}
                />
              </Box>
            </Box>
          ): (<Typography variant="h6">Фотографии нет!</Typography>)}
        </Box>
      </Box>
    );
  };
  
  export default ProtocolDetails;