import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, useTheme, DialogActions, Typography, CircularProgress } from '@mui/material';
//import axios from 'axios';
import { tokens } from '../../theme';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import Header from '../../components/Header';
import AddIksForm from './addIksForm';
import getIks from '../../Api/HomeIk/getIks';
import { useUserStore } from '../../stores/userStore';
import { dataGridStyles } from '../../styleGrid';
import getInfoIk from '../../Api/HomeIk/getInfoIk';
import updateIk from '../../Api/HomeIk/updateIk';
//import { determineRoleCTYPE } from '../../data/Utils/accessControlConst';
import createIk from '../../Api/HomeIk/createIk';
import DataGridFilterCustom from '../../components/DataGridFilterCustom';
import importData from '../../Api/ImportData/importData';
import deleteIk from '../../Api/HomeIk/deleteIk';
import SearchEngine from '../../Api/SearchSystem/SearchUniversal';
import enumDistricts from '../../Api/Districts/enumDistricts';
import enumRegions from '../../Api/Regions/enumRegions';
import SimplePicker from '../../shared/CustomFilters/SimplePicker';
import getTypesIds from '../../Api/HomeIk/getTypesIds';

const addIks = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pollingStations, setPollingStations] = useState([]);
  const [open, setOpen] = useState(false);
  const [force, setForce] = useState(true);
  const token = useUserStore((state) => state.token);
  //const [totalPages, setTotalPages] = useState(100);
  const totalPages = 10000;
  const [isLoadingImportData, setIsLoadingImportData]= useState(false);
  const [isLoadingData, setIsLoadingData]= useState(false);
  const [editPolling, setEditPolling] = useState(null);
  //const userInfo = useUserDataStore((state) => state.user);
  const [page, setPage] = useState(0);

  //Для фильтров
  const [regions, setRegions]= useState([]);
  const [districts, setDistricts]= useState([])
  const [typesIk, setTypesIk]= useState([])

  //Поисковые функционал
  const [searchText, setSearchText] = useState('');
  const [debouncedText, setDebouncedText] = useState(''); // текст после задержки
  const [filters, setFilters] = useState({
    ik_type: null,
    reg_id: null,
    district_id: null,
  });


  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'name', headerName: 'Название', width: 250 },
    // { field: 'number', headerName: 'Номер', flex: 1 },
    { field: 'address', headerName: 'Адрес', width: 200 },
    { field: 'lat', headerName: 'Широта', width: 100 },
    { field: 'long', headerName: 'Долгота', width: 100 },
    { field: 'phone', headerName: 'Телефон', width: 150 },
    {
      field: "actions",
      headerName: "Управление",
      flex: 1,
      renderCell: (params) => (
        <>
        <Button
          variant="outlined"
          style={{ color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
          onClick={() => getInfoFromId(params.row.ik_id)}
        >
          Редактировать
        </Button>

        <Button
            variant="outlined"
            style={{marginLeft: "10px", color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13 }}
            onClick={() => handleDeleteIk(params.row.id)}
         >
          Удалить
        </Button>
        </>
      ),
    },
  ];

  const getYiksData = async (newPage = page) => {
    //setIsLoadingData(true);
   try {
   await getIks({
      "user_initiator":{
        "usr_token":token
      },
      "ik":{
        "type_id":filters.ik_type
    },
    "region":{
        "region_id":filters.reg_id
    },
    "district":{
      "district_id":filters.district_id
    },
        "page":newPage
    }).then((response) => {
      if(response.success){
        // Use uik_id as the unique identifier (id) for each row
        const pollingStationsWithIds = response.data.iks.map((station) => ({
        ...station,
        id: station.ik_id,
        }));
        setPollingStations(pollingStationsWithIds);
         setIsLoadingData(false);
      } else {
        setPollingStations([]);
        setIsLoadingData(false);
        alert(response.message)
      }
      setPage(newPage)
      setForce(false)
    });
  }catch (err) {
    console.log('TEST', err)
  }
  }

  const getRegions = async () => {
    await enumRegions({}).then((resp) => {
       if (resp.success) {
         const dataFormat = resp.data.regions.map((region) => ({
           value: region.region_id,
           label: region.name,
         }));
         setRegions(dataFormat)
       } else {
         setRegions([])
         alert(resp.message)
       }
     })
   }
 
   const getDistricts = async () => {
     await enumDistricts({
       "region":{
           "region_id":filters.reg_id
       }
     }).then((resp) => {
        if (resp.success) {
          const dataFormat = resp.data.districts.map((region) => ({
            value: region.district_id,
            label: region.district_name,
          }));
          setDistricts(dataFormat)
        } else {
         setDistricts([])
          alert(resp.message)
        }
      })
    }

    const getTypesIkFilters = async () => {
      await getTypesIds({}).then((resp) => {
         if (resp.success) {
           const dataFormat = resp.data.ik_types.map((region) => ({
             value: region.type_id,
             label: region.name_short,
           }));
           setTypesIk(dataFormat)
         } else {
           setTypesIk([])
           alert(resp.message)
         }
       })
     }

  useEffect(() => {
      if(force){
      setIsLoadingData(true);
      getRegions();
      getTypesIkFilters();
      getYiksData();
      }
    }, [force]); // Запускается один раз при монтировании компонента

    useEffect(()=> {
      if(filters.ik_type || filters.reg_id || filters.district_id){
        setIsLoadingData(true);
        getYiksData();
      } else if (filters.ik_type === null && filters.district_id === null && filters.reg_id === null){
        setIsLoadingData(true);
        getYiksData();
      }
    
    },[filters.ik_type, filters.reg_id, filters.district_id ])

  // useEffect(() => {
  //   if(filters.ik_type || filters.reg_id || filters.district_id ){
  //     setIsLoadingData(true);
  //     getYiksData();
  //   }
  // }, [filters]);

  useEffect(() => {
    if(filters.reg_id !== null){
      getDistricts();
    }
  }, [filters.reg_id]);

   // Используем useEffect для debounce
   useEffect(() => {
    // Создаем таймер для debounce
    const handler = setTimeout(() => {
      setDebouncedText(searchText); // Устанавливаем значение после задержки
    }, 300); // Устанавливаем задержку в миллисекундах (например, 300 мс)

    // Очищаем таймер при каждом изменении searchText
    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  useEffect(() => {
    if (debouncedText !== '') {
      handleSearch();
    }
  }, [debouncedText]);

  // useEffect(() => {
  //   if (searchText !== ''){
  //     handleSearch()
  //   }
  // }, [searchText]);

  // Поисковый функционал
  const onClearSearch = () => {
    setSearchText('')
    setPollingStations([]);
    setForce(true);
}

const handleSearch = async () => {

  let payload = {
    "user_initiator": {
        "usr_token": token
    },
    "ik":{
        "name": debouncedText,
        "reg_id": filters.reg_id,
        "district_id": filters.district_id,
        "type_id": filters.ik_type,
    }
}

  if (debouncedText.length > 2){
    setIsLoadingData(true);

    await SearchEngine(payload, "ik").then((resp) => {
      if (resp.success){
        const pollingStationsWithIds = resp.data.iks.map((station) => ({
          ...station,
          id: station.ik_id,
          }));
        setPollingStations(pollingStationsWithIds);
        setIsLoadingData(false);
      } else {
        alert(resp.message)
        setIsLoadingData(false);
      }
    })
  }else if (debouncedText.length == 0 && debouncedText.length == ""){
    setPollingStations([]);
    setForce(true)
 }
}

  const handleDeleteIk = async (id) => {

     // Показ предупреждающего окна с кнопками "Отмена" и "Хорошо"
     const userConfirmed = window.confirm("Вы уверены, что хотите удалить ИК? Это действие нельзя отменить и приведет к полной потери данных и пользователей привязанных к этой избирательной комиссии");
    
     if (!userConfirmed) {
         // Если пользователь нажал "Отмена", выходим из функции
         return;
     }

    let payload = {
        "user_initiator":{
            "usr_token": token
        },
        "ik":{
            "ik_id": id
        }
    }

    await deleteIk(payload).then((resp) => {
      if(resp.success){
        setForce(true);
        alert(resp.data.sys_msg)
      } else {
        alert(resp.message)
      }
    })

  }

  const getInfoFromId = async (id) => {

    let payload = {
      "ik":{
        "ik_id":id
      }
    }

    await getInfoIk(payload).then((resp) => {
      if(resp.success){
        setEditPolling(resp.data.ik);
        setOpen(true);
      } else {
        alert(resp.message)
      }
    })
  }

  const updatePollingStation = async (data) => {
    let payload = {
      "user_initiator":{
        "usr_token": token
    },
    "ik":{
      ...data
    }
  }

   await updateIk(payload).then((resp) => {
     if(resp.success){
      setForce(true);
      setOpen(false);
     } else {
       alert(resp.message)
     }
   })
  }

  const addPollingStation = async (newPollingStation) => {
    await createIk({"user_initiator":{
      "usr_token":token
         },
      "ik":newPollingStation
        })
     .then( (response)=>  {
      if(response.success) {
        setForce(true);
        setOpen(false);
      } else {
        alert(response.message)
      }
    }) 
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditPolling(null)
  };

  const handleOpenAddDialog = () => (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" maxWidth="lg" fullWidth>
        <DialogTitle id="alert-dialog-title">{"Укажите информацию о ИК"}</DialogTitle>
        <DialogContent>
          <AddIksForm addPollingStation={addPollingStation} editData={editPolling} handleUpdate={updatePollingStation} />
        </DialogContent>
      </Dialog>
    </div>
  );

  const pageChange = (newPage)=>{
    if(newPage !== page){
      getYiksData(newPage);
    }
  }

  //БЛОК С ИМПОРТОМ

  const [openImport, setOpenImport] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  let exampleImport = 'https://redcontrol.kprf.ru/files/ExamplesImport/testDataIk.xlsx'

  const handleOpenImport = () => {
    setOpenImport(true);
  };

  const handleCloseImport = () => {
    setOpenImport(false);
    setSelectedFile(null);
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  const downloadFile = (url) => {
    // Создаем ссылку на скачивание
    const link = document.createElement('a');
    link.href = url;
    // Устанавливаем атрибуты для скачивания файла
    link.setAttribute('download', '');
    // Добавляем ссылку на страницу (это необходимо для некоторых браузеров)
    document.body.appendChild(link);
    // Нажимаем на ссылку, чтобы начать скачивание файла
    link.click();
    // Удаляем ссылку после скачивания
    document.body.removeChild(link);
};

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith('.xlsx')) {
      setSelectedFile(file);
    } else {
      alert('Please select a valid .xlsx file', { variant: 'error' });
    }
  };

  const handleFileUpload = async () => {
    setIsLoadingImportData(true);
   await importData(selectedFile, token, "ik").then((resp) => {
      if(resp.success){
        alert('Данные успешно импортированы');
      } else {
        alert(`Ошибка при импорте данных: ${resp.message}`)
      }
      setIsLoadingImportData(false);
    })
  };

  const handleOpenDialogImport = () => {
    return (
      <div>
      <Dialog open={openImport} onClose={handleCloseImport}>
        <DialogTitle>Импорт файла</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" justifyContent="center">
          {selectedFile ? (
            <Box display="flex" alignItems="center">
              <Typography>{selectedFile.name}</Typography>
              <Button variant="outlined" color="secondary" onClick={handleFileRemove} style={{ marginLeft: 16 }}>
                Отменить
              </Button>
            </Box>
          ) : (
            <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} component="label">
              Добавить файл
              <input type="file" accept=".xlsx" hidden onChange={handleFileChange} />
            </Button>
          )}
          </Box>
        </DialogContent>
        <DialogActions>
        <Button
             variant="contained"
             style={{ borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D", marginBottom: 10, fontWeight: 'bold' }}
             onClick={handleFileUpload}
            disabled={isLoadingImportData} // Делаем кнопку неактивной во время загрузки
        >
          {isLoadingImportData ? <CircularProgress size={24} color="inherit" /> : 'Загрузить'}
        </Button>
          <>
          <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={() =>downloadFile(exampleImport)}>
             Скачать пример
          </Button>
          <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleCloseImport}>
            Закрыть
          </Button>
          </>
        </DialogActions>
      </Dialog>
    </div>
    )
  }

//БЛОК КНОПОК В ФИЛЬТРЕ
  const buttonsElements = () => {
    return (
      <>

      <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleClickOpen}>
        Добавить ИК
      </Button>

      <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold', marginLeft: 10}}  onClick={handleOpenImport}>
         Импорт
      </Button>
      </>
    )
  }


  const FiltersElements = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', ml: 2, mr: 2, mb: 2, justifyContent: 'center', gap: 2 }}> {/* Добавлен отступ с помощью gap */}
            {/* Фильтр Типа 1: Выбор из множества данных с встроенным поиском */}
            <SimplePicker
                options={regions}
                selectedOption={filters.reg_id}
                setSelectedOption={(value) => setFilters({ ...filters, reg_id: value })}
                label="Фильтр по региону"
            />

           <SimplePicker
                options={districts}
                selectedOption={filters.district_id}
                setSelectedOption={(value) => setFilters({ ...filters, district_id: value })}
                label="Фильтр по району"
            />

            <SimplePicker
                options={typesIk}
                selectedOption={filters.ik_type}
                setSelectedOption={(value) => setFilters({ ...filters, ik_type: value })}
                label="Фильтр по типу ИК"
            />
            
        </Box>
    );
}
  
  return (
    <Box m="20px">
      <Header title="Списки избирательных комиссии" subtitle="Добавьте избирательные комиссии в систему" />
      <Box sx={{mt: 2}}>

            <DataGridFilterCustom  additionalButton={buttonsElements()} searchText={searchText} setSearchText={setSearchText} onClearSearch={onClearSearch} filtersButtons={FiltersElements()} />

          </Box>
      <Box
        m="20px 0 0 0"
        height="80vh"
        sx={dataGridStyles}
      >
        {handleOpenAddDialog()}
        {handleOpenDialogImport()}
        <DataGrid 
        ///checkboxSelection 
        rows={pollingStations}
        columns={columns} 
        //components={{ Toolbar: handleOpenAddDialog }} 
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        loading={isLoadingData}
        paginationMode="server"
        pageSize={100}
        rowCount={totalPages} // Установите общее количество элементов для корректного отображения пагинации
        onPageChange={pageChange}
        />
      </Box>
    </Box>
  );
};

export default addIks;