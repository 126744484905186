import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  useTheme,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress
} from "@mui/material";
import { tokens } from "../../theme";
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
import AddUserForm from "./addUserForm";
import PersonIcon from "@mui/icons-material/Person";
//import DeactivateUserModal from "../../modals/DeactivateUserModal";
import BlockUserModal from "../../modals/BlockUserModal";
// import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import getUsers from "../../Api/Users/getUsers";
//import getIks from "../../Api/HomeIk/getIks";
import { useUserStore } from '../../stores/userStore';
import addUser from "../../Api/Users/addUser";
import setVerification from "../../Api/Users/setVerification";
import blockUser from "../../Api/Users/blockUser";
import updateUser from "../../Api/Users/updateUser";
//import { determineRoleCTYPE } from "../../data/Utils/accessControlConst";
import getStatuses from "../../Api/Statuses/getStatuses";
import getInfoUser from "../../Api/Users/getInfoUser";
import { dataGridStyles } from "../../styleGrid";
import { useNavigate } from "react-router-dom";
import setSuperAdminSystem from "../../Api/AccessEdit/setSuperAdminSystem";
import setSuperAdminRegion from "../../Api/AccessEdit/setSuperAdminRegion";
import setSuperAdminDistrict from "../../Api/AccessEdit/setSuperAdminDistrict";
import DataGridFilterCustom from "../../components/DataGridFilterCustom";
import importData from "../../Api/ImportData/importData";
import SearchEngine from "../../Api/SearchSystem/SearchUniversal";
import MultiSelectWithSearch from "../../shared/CustomFilters/MultiSelectWithSearch";
import SimplePicker from "../../shared/CustomFilters/SimplePicker";
import getIks from "../../Api/HomeIk/getIks";
import enumRegions from "../../Api/Regions/enumRegions";
import enumDistricts from "../../Api/Districts/enumDistricts";

const addUsers = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  //const userInfo = useUserDataStore((state) => state.user);
  const [force, setForce] = useState(true);
  const token = useUserStore((state) => state.token);
  const [isLoadingData, setIsLoadingData]= useState(false);

  const [isLoadingImportData, setIsLoadingImportData]= useState(false);

  //Для фильтров
  const [regions, setRegions]= useState([]);
  const [districts, setDistricts]= useState([])

  // Состояния для диалоговых окон блокировки и деактивации
  const [blockDialogOpen, setBlockDialogOpen] = useState(false);
  //const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false);

  const totalPages = 10000;

      //Поисковые функционал
      const [searchText, setSearchText] = useState('');
      const [debouncedText, setDebouncedText] = useState(''); // текст после задержки
      const [filters, setFilters] = useState({
        ik_id: null,
        reg_id: null,
        district_id: null,
        is_first_checkout: null,
        user_is_checkout: null,
        is_banned: null
      });

       // Опции для первого SimplePicker
    const isBannedOptions = [
      { value: null, label: "Показать всех" },
      { value: true, label: "Заблокированные пользователи" },
  ];

  // Опции для второго SimplePicker
  const checkoutOptions = [
      { value: null, label: "Показать всех" },
      { value: "on_site", label: "Находятся на участке" }, // пользователь находится на участке
      { value: "checked_once", label: "Отмечались 1 раз" }, // пользователь отмечался один раз
  ];

  const columns = [
    { field: "user_id", headerName: "ID", width: 50 },
    {
      field: "usr_name",
      headerName: "ФИО",
      width: 250,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleClick(params.row.user_id)}>
          {params.row.usr_name}
        </div>
      )
    },
    {
      field: "ik",
      headerName: "УИК",
      width: 150,
    },
    {
      field: "status_id",
      headerName: "Статус",
      width: 320,
      renderCell: ({ row: { status_id } }) => {
       let roleName = statuses.find((item)=> item.id === status_id)?.name
        return (
          <Box
            width="90%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              status_id === 1
                ? colors.greenAccent[600]
                : status_id === 2
                ? colors.greenAccent[700]
                : colors.grey[400] // цвет по умолчанию, если роль не 1 и не 2
            }
            borderRadius="4px"
          >
            <PersonIcon />
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {roleName}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: "control",
      headerName: "Управление",
      flex: 1,
      width: '20%',
      renderCell: ({ row }) => {
        //console.log(row);
        return (
          <div>
            <Button
          variant="outlined"
          //style={{color: colors.greenAccent[1000]}}
          //style={`${colors.greenAccent[1000]}`}
          style={{ color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
          //color="primary"
          onClick={() => handleEditUser(row.user_id)}
        >
          Редактировать
        </Button>

            <Button
              variant="outlined"
              color={row.isuserverified ? "error" : "success"}
              style={{ marginLeft: "10px", color: row.isuserverified ? colors.redAccent[1000] : colors.greenAccent[1000], borderColor: row.isuserverified ? colors.redAccent[1000] : colors.greenAccent[1000], fontWeight: '600', fontSize: 13 }}
              onClick={() => toggleUserVerification(row.user_id)}
              //style={{ marginLeft: "10px" }}
            >
              {row.isuserverified ? "Снять верификацию" : "Верифицировать"}
            </Button>

            {row.is_banned ? (
              <Button
                variant="outlined"
                //color="success"
                onClick={() => unblockUser(row.user_id)}
                style={{ marginLeft: "10px", color: colors.greenAccent[1000], borderColor: colors.greenAccent[1000], fontWeight: '600', fontSize: 13  }}
              >
                Разблокировать
              </Button>
            ) : (
              <Button
                variant="outlined"
                //color="error"
                onClick={() => handleBlock(row.user_id)}
                style={{ marginLeft: "10px", color: colors.redAccent[1000], borderColor: colors.redAccent[1000], fontWeight: '600', fontSize: 13  }}
              >
                Блокировать
              </Button>
            )}

          </div>
        );
      },
    },
  ];

  const handleBlock =(userId) => {
    setBlockDialogOpen(true)
    setCurrentUser(userId)
  }

  const handleEditUser = (userId) => {
    let payload = {
      "user_initiator": {
        "usr_token": token
      },
      "user":{
        "user_id":userId
      }
    }
    getInfoUser(payload).then((resp) =>{
      if (resp.success){
        setCurrentUser(resp.data.user);
        setOpen(true); // Open the addUserForm dialog
      } else {
        alert(resp.message)
      }
    })
  };

  const getUsersList = async (newPage = 0) => {
    setIsLoadingData(true)
    let payload = {
      "user_initiator": {
        "usr_token": token
       },
       "user":{
        "home_ik":filters.ik_id,
        "is_first_checkout": filters.is_first_checkout,
        "user_is_checkout": filters.user_is_checkout,
        "is_banned": filters.is_banned
       },
       "region":{
        "reg_id":filters.reg_id
        },
       "district":{
        "district_id":filters.district_id
      },
      "page": newPage
    }
    await getUsers(payload).then((response) => {
      if(response.success){
        //console.log('TEST TEST REPLANZ',response.data.users.length )
        //console.log('TEST TEST REPLANZ page',newPage )
        const dataWithIds = response.data.users.filter(user => user.tag !== true).map((user) => ({
          id: user.user_id,
          usr_name: user.user, 
          ik: user.ik_data.name,
          ...user
        }));
        setUsers(dataWithIds);
        setIsLoadingData(false)
      } else {
        setUsers([]);
        setIsLoadingData(false)
        alert(response.message)
      }
      setForce(false)
    });
  }

   const getStatusList = async () => {
      await getStatuses({ "status_user":{
        "type":"users"
     }}).then((resp) => {
        if (resp.success) {
          setStatuses(resp.data.statuses_user)
        } else {
          setStatuses([])
          alert(resp.message)
        }
      })
   }

   const getRegions = async () => {
   await enumRegions({}).then((resp) => {
      if (resp.success) {
        const dataFormat = resp.data.regions.map((region) => ({
          value: region.region_id,
          label: region.name,
        }));
        setRegions(dataFormat)
      } else {
        setRegions([])
        alert(resp.message)
      }
    })
  }

  const getDistricts = async () => {
    await enumDistricts({
      "region":{
          "region_id":filters.reg_id
      }
    }).then((resp) => {
       if (resp.success) {
         const dataFormat = resp.data.districts.map((region) => ({
           value: region.district_id,
           label: region.district_name,
         }));
         setDistricts(dataFormat)
       } else {
        setDistricts([])
         alert(resp.message)
       }
     })
   }

  useEffect(() => {
    if(force){
      getRegions();
      getStatusList();
      getUsersList();
    }

  }, [force]);

  useEffect(()=> {
    if(filters.reg_id || filters.district_id || filters.ik_id || filters.user_is_checkout || filters.is_first_checkout || filters.is_banned){
      getUsersList();
    } else if (filters.reg_id === null && filters.district_id === null && filters.ik_id === null && 
      filters.user_is_checkout === null && filters.is_first_checkout  === null && filters.is_banned === null ){
      getUsersList();
    }

  },[filters.reg_id, filters.ik_id, filters.district_id, filters.user_is_checkout, filters.is_first_checkout, filters.is_banned ])

  useEffect(() => {
    if(filters.reg_id){
      getDistricts();
    }
  }, [filters.reg_id]);

   // Используем useEffect для debounce
   useEffect(() => {
    // Создаем таймер для debounce
    const handler = setTimeout(() => {
      setDebouncedText(searchText); // Устанавливаем значение после задержки
    }, 300); // Устанавливаем задержку в миллисекундах (например, 300 мс)

    // Очищаем таймер при каждом изменении searchText
    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  useEffect(() => {
    if (debouncedText !== '') {
      handleSearch();
    }
  }, [debouncedText]);


//   useEffect(() => {
//     if (searchText !== '') {
//         handleSearch();
//     } 
// }, [searchText]);


  // Поисковый функционал
  const onClearSearch = () => {
    setSearchText('')
    setUsers([]);
    setForce(true);
}

const handleSearch = async () => {

  if (debouncedText.length > 2){
    setIsLoadingData(true);

  let payload = {
    "user_initiator": {
        "usr_token": token
    },
    "user":{
      "usr_name": debouncedText,
      "is_first_checkout": filters.is_first_checkout,
      "user_is_checkout": filters.user_is_checkout,
      "is_banned": filters.is_banned
    },
    "region":{
       "reg_id": filters.reg_id
    },
    "district":{
      "district_id": filters.district_id
    }
}

    await SearchEngine(payload, "users").then((resp) => {
      if (resp.success){
        const dataWithIds = resp.data.users.filter(user => user.tag !== true).map((user) => ({
          id: user.user_id,
          usr_name: user.user, 
          ik: user.ik_data.name,
          status_id: user.status_id,
          ...user
        }));
        setUsers(dataWithIds);
        setIsLoadingData(false);
      } else {
        alert(resp.message)
        setIsLoadingData(false);
      }
    })
  } else if (debouncedText.length == 0 && debouncedText.length == ""){
    setUsers([]);
    setForce(true)
 }
}
  
  const addNewUser = async (user) => {
    await addUser({"user_initiator":{ "usr_token": token }, "user":user}).then((response) => {
        if (response.success) {
            setForce(true);
            setOpen(false);
        } else {
            console.log('Что-то не так', response)
            alert(response.message)
        }
    });
};

  const toggleUserVerification = async (userId) => {
    const updatedUsers = users.map((user) => {
        if (user.user_id === userId) {
            const updatedUser = { ...user, isuserverified: !user.isuserverified };
            setVerification({
                "user_initiator": { "usr_token": token },
                "user": {
                    "user_id": userId,
                    "isuserverified": updatedUser.isuserverified
                }
            }).then((response) => {
                if (response.success) {
                    setUsers(updatedUsers);
                } else {
                    alert(response.message)
                    console.log('Что-то не так', response);
                }
            });
            return updatedUser;
        } else {
            return user;
        }
    });
};

  // Функция для обработки блокировки пользователя
  const handleBlockUser = async (userId,blockReason, blockTime) => {
    const updatedUsers = users.map((user) => {
        if (user.user_id === userId) {
            const updatedUser = { ...user, is_banned: !user.is_banned };
            blockUser({
                "user_initiator": { "usr_token": token },
                "user": {
                    "user_id": userId,
                    "is_banned": updatedUser.is_banned,
                    "ban_reason": blockReason,
                    "ban_until": blockTime
                }
            }).then((response) => {
                if (response.success) {
                    setUsers(updatedUsers);
                    setBlockDialogOpen(false);
                    setCurrentUser(null);
                } else {
                    alert(response.message)
                    console.log('Что-то не так', response);
                }
            });
            return updatedUser;
        } else {
            return user;
        }
    });
};

  // Функция для разблокировки пользователя
  const unblockUser = async (userId) => {
    const updatedUsers = users.map((user) => {
        if (user.user_id === userId) {
            const updatedUser = { ...user, is_banned: !user.is_banned };
            blockUser({
                "user_initiator": { "usr_token": token },
                "user": {
                    "user_id": userId,
                    "is_banned": updatedUser.is_banned
                }
            }).then((response) => {
                if (response.success) {
                    setUsers(updatedUsers);
                    setBlockDialogOpen(false);
                    setCurrentUser(null);
                } else {
                    alert(response.message)
                    console.log('Что-то не так', response);
                }
            });
            return updatedUser;
        } else {
            return user;
        }
    });
};

const updateUserfunc = async (updatedUser) => {
  await updateUser({"user_initiator":{ "usr_token": token }, "user":updatedUser}).then((response) => {
    if (response.success) {
      if(updatedUser.access_data.access_id === 36){
        let payload = {
         "user_initiator":{
           "usr_token": token
          },
          "user":{
             "user_id":updatedUser.user_id
          }
        }
        setSuperAdminSystem(payload).then((resp) => {
           if (!resp.success){
             alert(resp.message)
           }
        })
     }
     if(!updatedUser.district && updatedUser.region){
       let payload = {
         "user_initiator":{
           "usr_token": token
          },
          "region":{
           "region_id":updatedUser.region
          },
          "user":{
             "user_id":updatedUser.user_id
          }
        }
        setSuperAdminRegion(payload).then((resp) => {
         if (!resp.success){
           alert(resp.message)
         }
        })
     }
     if(updatedUser.district && updatedUser.region){
       let payload = {
         "user_initiator":{
           "usr_token": token
          },
          "district":{
           "district_id":updatedUser.district
          },
          "user":{
             "user_id":updatedUser.user_id
          }
        }
       setSuperAdminDistrict(payload).then((resp) => {
         if (!resp.success){
           alert(resp.message)
         }
        })
     }
        setForce(true);
        setOpen(false);
        setCurrentUser(null) // Clear edit mode
    } else {
        console.log('Что-то не так', response)
        alert(response.message)
    }
});
};

 // ФУНКЦИОНАЛ ДЛЯ ПЕРЕХОДА К ПОЛЬЗОВАТЕЛЮ В ПРОФИЛЬ:

 const handleClick = async (userId) => {
  try {
      // Ваша логика обработки клика
      const resp = await getUserInfoFromId(userId);
      navigate('/me', { state: { userData: resp , nav: 'any'} });
  } catch (error) {
      alert(error);
  }
 };

 //получаем id информацию по id пользователя
 const getUserInfoFromId = async (id) => {
  let payload = {
      "user_initiator": {
          "usr_token": token
      },
      "user":{
          "user_id": id
      }
  };

  // Возвращаем результат выполнения асинхронного запроса
  return getInfoUser(payload).then((resp) => {
      if (resp.success) {
          return resp.data.user; // Возвращаем значение из .then()
      } else {
          throw new Error(resp.message); // Вызываем ошибку, чтобы обработать ее в handleClick
      }
  });
};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentUser(null)
  };

  const handleOpenImport = () => {
    setOpenImport(true);
  };

  let exampleImport = 'https://redcontrol.kprf.ru/files/ExamplesImport/testDataUsers.xlsx'

  const [selectedFile, setSelectedFile] = useState(null);
  
  const downloadFile = (url) => {
    // Создаем ссылку на скачивание
    const link = document.createElement('a');
    link.href = url;
    // Устанавливаем атрибуты для скачивания файла
    link.setAttribute('download', '');
    // Добавляем ссылку на страницу (это необходимо для некоторых браузеров)
    document.body.appendChild(link);
    // Нажимаем на ссылку, чтобы начать скачивание файла
    link.click();
    // Удаляем ссылку после скачивания
    document.body.removeChild(link);
};

  const handleCloseImport = () => {
    setOpenImport(false);
    setSelectedFile(null);
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith('.xlsx')) {
      setSelectedFile(file);
    } else {
      alert('Please select a valid .xlsx file', { variant: 'error' });
    }
  };

  const handleFileUpload = async () => {
   setIsLoadingImportData(true);
   await importData(selectedFile, token, "users").then((resp) => {
      if(resp.success){
        alert('Данные успешно импортированы');
        const fileLink = resp.data.file_link;
        downloadFile(fileLink);
      } else {
        alert(`Ошибка при импорте данных: ${resp.message}`)
      }
      setIsLoadingImportData(false);
    })
  };

  const handleOpenDialogImport = () => {
    return (
      <div>
      <Dialog open={openImport} onClose={handleCloseImport}>
        <DialogTitle>Импорт файла</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" justifyContent="center">
          {selectedFile ? (
            <Box display="flex" alignItems="center">
              <Typography>{selectedFile.name}</Typography>
              <Button variant="outlined" color="secondary" onClick={handleFileRemove} style={{ marginLeft: 16 }}>
                Отменить
              </Button>
            </Box>
          ) : (
            <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} component="label">
              Добавить файл
              <input type="file" accept=".xlsx" hidden onChange={handleFileChange} />
            </Button>
          )}
          </Box>
        </DialogContent>
        <DialogActions>
        <Button
          variant="contained"
          style={{ borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D", marginBottom: 10, fontWeight: 'bold' }}
          onClick={handleFileUpload}
          disabled={isLoadingImportData} // Делаем кнопку неактивной во время загрузки
        >
          {isLoadingImportData ? <CircularProgress size={24} color="inherit" /> : 'Загрузить'}
        </Button>
          <>
          <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={() => downloadFile(exampleImport)}>
             Скачать пример
          </Button>
          <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}} onClick={handleCloseImport}>
            Закрыть
          </Button>
          </>
        </DialogActions>
      </Dialog>
    </div>
    )
  }

  const buttonsElements = () => {
    return (
      <>

       <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold'}}  onClick={handleClickOpen}>
          Добавить пользователя
        </Button>

      <Button variant="contained" style={{borderRadius: 21, backgroundColor: "#D3D1D1", color: "#970D0D" , marginBottom: 10, fontWeight: 'bold', marginLeft: 10}}  onClick={handleOpenImport}>
         Импорт
      </Button>
      </>
    )
  }

  const [multiSelectOptions, setMultiSelectOptions] = useState([]); // Опции для многовыборного фильтра
  const [selectedMultiOptions, setSelectedMultiOptions] = useState(null); // Выбранные опции для многовыборного фильтра

  const handleSearchIk = async (event, newValue, reason) => {

      if (reason === 'reset') {
        setMultiSelectOptions([]);
        setSelectedMultiOptions(null);
        return
      }
    
      if (event?.type === 'click' || event === null || event?.target === null) {
        return;
       }
    
       const searchTerm = event.target.value;
    
      if (searchTerm.length <= 2) {
        return;
      }
    
    //setSearchLoading(true);
    
     //Временное решение проблемы для быстрого поиска ЦИКа
     try {
       // Запрос списка yiks с сервера для текущей страницы
       const response = await getIks({
         "user_initiator": {
           "usr_token": token
         },
         "ik": {
           "name": searchTerm,
         },
         "region":{
            "region_id":filters.reg_id
         },
         "district":{
            "district_id":filters.district_id
         },
         //"page": currentPage
       });
       
       if(response.success){
         const yiks = response.data.iks;
         setMultiSelectOptions(yiks);
         //setSearchLoading(false);
       }
     } catch (error) {
       console.error("Ошибка при получении списка yiks:", error);
       // Обработка ошибки, например, отображение сообщения об ошибке пользователю
       //setSearchLoading(false);
     }
    };

  const multiSetFilters = (newValue) => {
    setSelectedMultiOptions(newValue)
    if(newValue !== null){
      setFilters({ ...filters, ik_id: newValue.ik_id })
    } else {
      setFilters({ ...filters, ik_id: null})
    }
  }


  const FiltersElements = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', ml: 2, mr: 2, mb: 2, justifyContent: 'center', gap: 2 }}> {/* Добавлен отступ с помощью gap */}
            {/* Фильтр Типа 1: Выбор из множества данных с встроенным поиском */}
            <SimplePicker
                options={regions}
                selectedOption={filters.reg_id}
                setSelectedOption={(value) => setFilters({ ...filters, reg_id: value })}
                label="Фильтр по региону"
            />

           <SimplePicker
                options={districts}
                selectedOption={filters.district_id}
                setSelectedOption={(value) => setFilters({ ...filters, district_id: value })}
                label="Фильтр по району"
            />

            <MultiSelectWithSearch
                options={multiSelectOptions}
                selectedOptions={selectedMultiOptions}
                setSelectedOptions={multiSetFilters}
                label="Фильтр по ИК"
                onSearch={handleSearchIk}
            />

            <SimplePicker
                options={isBannedOptions}
                selectedOption={filters.is_banned}
                setSelectedOption={(value) => setFilters({ ...filters, is_banned: value })}
                label="Фильтр по статусу блокировки"
            />

            {/* SimplePicker для фильтрации по статусу отметки */}
            <SimplePicker
                options={checkoutOptions}
                selectedOption={
                    filters.user_is_checkout ? "on_site" :
                    filters.is_first_checkout ? "checked_once" : null
                }
                setSelectedOption={(value) => {
                    if (value === "on_site") {
                        setFilters({ ...filters, user_is_checkout: true, is_first_checkout: null });
                    } else if (value === "checked_once") {
                        setFilters({ ...filters, user_is_checkout: null, is_first_checkout: true });
                    } else {
                        setFilters({ ...filters, user_is_checkout: null, is_first_checkout: null });
                    }
                }}
                label="Фильтр по статусу отметки"
            />
            
        </Box>
    );
}

  const handleOpenAddUserDialog = () => {
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Укажите данные для нового пользователя"}
          </DialogTitle>
          <DialogContent>
            <AddUserForm addUser={addNewUser} currentUser={currentUser} statuses={statuses} updateUser={updateUserfunc} />
          </DialogContent>
        </Dialog>
      </div>
    );
  };

    //Диалоговое окно для блокировки пользователя
    const renderBlockDialog = (
      <Dialog
        open={blockDialogOpen}
        onClose={() => setBlockDialogOpen(false)}
        aria-labelledby="block-dialog-title"
      >
        <DialogTitle id="block-dialog-title">Блокировать пользователя</DialogTitle>
        <DialogContent>
        <BlockUserModal onBlockUser={handleBlockUser} userID={currentUser}/>
        </DialogContent>
      </Dialog>
    );

    const pageChange = (newPage)=>{
      getUsersList(newPage);
    }

  return (
    <Box m="20px">
      <Header
        title="Добавление пользователей"
      />
      <Box sx={{mt: 2}}>
         
         <DataGridFilterCustom additionalButton={buttonsElements()} searchText={searchText} setSearchText={setSearchText} onClearSearch={onClearSearch} filtersButtons={FiltersElements()} />

      </Box>
      <Box
        m="20px 0 0 0"
        height="80vh"
        sx={dataGridStyles}
      >
       {/* {renderDeactivateDialog} */}
        {renderBlockDialog}
        {handleOpenAddUserDialog()}
        {handleOpenDialogImport()}
        <DataGrid
          rows={users}
          columns={columns}
          loading={isLoadingData}
          //components={{ Toolbar: handleOpenAddUserDialog }}
          checkboxSelection
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          pageSize={100}
          paginationMode={'server'}
          rowCount={totalPages}
          onPageChange={pageChange}
        />
      </Box>
    </Box>
  );
};

export default addUsers;
