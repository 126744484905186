import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
//import axios from 'axios';
//import { tokens } from '../../theme';
import { DataGrid, ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
import ColorButton from "../../components/ColorButton";
import { dataGridStyles } from '../../styleGrid';

const UsersWorks = () => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);

  const columns = [
    { field: "id", headerName: "ID" },
    {
        field: "name",
        headerName: "ФИО",
        width: 250,
        cellClassName: "name-column--cell",
    },
    {
      field: "actions",
      headerName: "Управление",
      width: 120,
      renderCell: (params) => (
        <ColorButton
        variant="contained"
        disabled
        style={{ margin: "5px" }}
      >
        {params.row.isWorking ? "Прибыл на УИК" : "Убыл с УИК"}
      </ColorButton>
      ),
    },
  ];

  useEffect(() => {
    // axios.get('http://localhost:3000/users').then((response) => {
    //   setUsers(response.data);
    // });

    alert('В разработке')

    setUsers([])
  }, []);


  return (
    <Box m="20px">
    <Header title="Отслеживание работников на местах" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={dataGridStyles}
      >
       <DataGrid rows={users} columns={columns} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}/>
    </Box>
    </Box>
  );
};

export default UsersWorks;