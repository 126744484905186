import axios from 'axios';
import axiosRetry from 'axios-retry';
import curlirize from 'axios-curlirize';

if (process.env.NODE_ENV !== 'production') {
  curlirize(axios)
}

// Функция для отправки запроса
const ApiCall = async (endpoint, data, method, extraHeaders = {}) => {

    const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
    //const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const prod = window.location.origin
    //const url = `${API_BASE_URL}/${endpoint}`;
    const url = `${prod}/${endpoint}`;
  
    const options = {
      method: method,
      url: url,
      headers: {
        'Access-Key': ACCESS_KEY,
        ...extraHeaders
      },
    };

    const retryDelay = (retryNumber = 0) => {
      const seconds = Math.pow(2, retryNumber) * 1000;
      const randomMs = 1000 * Math.random();
  
      return seconds + randomMs;
  };
  
    axiosRetry(axios, {
      retries: 2,
      retryDelay,
      retryCondition: axiosRetry.isRetryableError
  });
  
    if (data) {
      options.data = data;
    }
      
    console.log('--- AXIOS ---');
    console.log('url', options.url);
    console.log('options', options);
      
    const requestPromise = axios(options);
      
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('Request timed out'));
      }, 60000); // 1 мин
    });
  
    const res = await Promise.race([requestPromise, timeoutPromise])
    .then((res) => {
      console.log('res', res);
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        return error.response
      } else if (error.request) {
        // The request was made but no response was received
        // error.request is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
  
        return {status: "fail" , message: undefined }
        
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
  
        return {status: "fail" ,data: {Code: 503, Error: 'Service Unavailable', Message: 'Service Unavailable'} , message: undefined}
      }
      //console.log(error.config);
    });
      
    console.log(res);
    console.log('--- /AXIOS ---');
    return res;
  };
  
  export default ApiCall;