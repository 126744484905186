import ApiCall from '../Api';

const uploadFiles = async (fileSend, userToken, subfolder) => {
  const endpoint = 'api/v2/file/upload';
  const extraHeaders = {
    "Content-Type":"multipart/form-data"
  };
  const formData = new FormData();
  
  formData.append('token', userToken);
  formData.append('subfolder', subfolder);

  // Преобразование content:// URI в файл
  formData.append('file', fileSend);

  const res = await ApiCall(endpoint, formData, 'POST', extraHeaders)
      if (!res.data.Error && res.status === 200) {
          return { success: true, data: res.data }
      } else if (res.status === 200 && res.data.Error) {
          return {
            success: false,
            data: [],
            message: 'Что-то пошло не так: ' + res.data.Message,
            code: res.data.Code
          };
      } else {
          return { success: false, data: undefined, message: res.data.Message, code: res.data.Code }
      }
};

// Экспортируем функцию отправки запроса
export default uploadFiles;