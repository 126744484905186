import { create } from 'zustand';
import Cookies from 'js-cookie';

const tryParseJSON = (jsonString) => {
  try {
    const parsed = JSON.parse(jsonString);
    return parsed;
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null;
  }
};

export const useUserStore = create((set) => ({
  token: Cookies.get('token'),
  setToken: (token) => {
    if (token === undefined) {
      Cookies.remove('token');
    } else {
      Cookies.set('token', token, { expires: 15 });
    }
    set(() => ({ token }));
  },
}));

export const useUserDataStore = create((set) => ({
  user: tryParseJSON(Cookies.get('user')) || null,
  setUser: (user) => {
    if (user === undefined) {
      Cookies.remove('user');
    } else {
      Cookies.set('user', JSON.stringify(user), { expires: 15 });
    }
    set({ user });
  },
}));