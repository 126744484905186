import { useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import PropTypes from "prop-types";
import getElections from "../../../Api/Elections/getElections";
import getCantidates from "../../../Api/Candidates/getCandidates";
// import getPfields from "../../../Api/Protocols/getPfields";
import enumSub from "../../../Api/Subdivisions/enumSub";
import getParties from "../../../Api/Parties/getParties";
import enumPfields from "../../../Api/Pfields/enumPfields";

const AddProtocolForm = ({ onSubmit }) => {
  AddProtocolForm.propTypes = {
    onSubmit: PropTypes.func,
  };

  const [activeStep, setActiveStep] = useState(0);
  const [protocolData, setProtocolData] = useState({
    protocol_name: "",
    elections_type: null,
    election_system: null,
    election_id: 0,
    election_home: null,
    protocol_type: null,
    is_candidates: true,
    is_parties: false,
    sub_id: null,
    protocolIs: "",
    date: null,
    sender: null,
    dataProtocol: "",
    description: "",
    upperPartFields: [],
    lowerPartFields: [],
  });
  const [elections, setElections] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [parties, setParties] = useState([]);
  const [pfields, setPfields] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);

  // Initialize counters for upper and lower part fields
  const [upperPartFieldCounter, setUpperPartFieldCounter] = useState(0);
  const [lowerPartFieldCounter, setLowerPartFieldCounter] = useState(0);

  // Fetch elections when component mounts
  useEffect(() => {
    getDataElections();
  }, []);

  // Fetch Pfields whenever protocol_type, election_system, or election_home changes
  useEffect(() => {
    if (protocolData.election_system && protocolData.election_home) {
      getDataPfields();
    }
  }, [protocolData.protocol_type, protocolData.election_system, protocolData.election_home]);

  // Fetch subdivisions or candidates based on election_system
  useEffect(() => {
    if (protocolData.election_id && (protocolData.election_system === 2 || protocolData.election_system === 3)) {
      getDataSubdivisions(protocolData.election_id);
    } else if (protocolData.election_system === 1) {
      getDataCandidates(null, protocolData.election_home);
    }
  }, [protocolData.election_id, protocolData.election_system]);

  // Fetch candidates or parties based on sub_id and is_candidates or is_parties state
  useEffect(() => {
    if (protocolData.is_candidates) {
      if (protocolData.sub_id && protocolData.election_home) {
        getDataCandidates(protocolData.sub_id, protocolData.election_home);
      } else if (!protocolData.sub_id && protocolData.election_home) {
        getDataCandidates(null, protocolData.election_home);
      }
    } else if (protocolData.is_parties) {
      getDataParties();
    }
  }, [protocolData.sub_id, protocolData.is_candidates, protocolData.is_parties, protocolData.election_home]);

  // Fetch protocol-related data after the election is selected
  useEffect(() => {
    if (protocolData.election_id) {
      const selectedElection = elections.find(election => election.election_id === protocolData.election_id);
      if (selectedElection) {
        setProtocolData(prevData => ({
          ...prevData,
          elections_type: selectedElection.election_type,
          election_system: selectedElection.election_system,
          election_home: selectedElection.home_ik,
          protocol_type: null, // Reset protocol_type if election changes
          sub_id: null, // Reset sub_id if election changes
        }));
      }
    }
  }, [protocolData.election_id, elections]);

// Automatically populate upperPartFields when pfields change and election_system is 2
useEffect(() => {
  if (protocolData.election_system === 2 && pfields.length > 0) {
    // Кастомная сортировка по числовой и буквенной части
    const sortedPfields = [...pfields].sort((a, b) => {
      // Разделяем number на числовую и буквенную части
      const matchA = a.number.match(/^(\d+)(\D*)$/);
      const matchB = b.number.match(/^(\d+)(\D*)$/);

      if (matchA && matchB) {
        // Сравниваем числовые части
        const numA = parseInt(matchA[1], 10);
        const numB = parseInt(matchB[1], 10);
        if (numA !== numB) {
          return numA - numB;
        }

        // Если числовые части равны, сравниваем буквенные части
        const partA = matchA[2].toLowerCase(); // Приводим к нижнему регистру
        const partB = matchB[2].toLowerCase();

        // Сравниваем латинские и кириллические символы в правильном порядке
        if (partA < partB) return -1;
        if (partA > partB) return 1;
        return 0;
      }

      // Если один из элементов не соответствует шаблону, оставляем в исходном порядке
      return a.number.localeCompare(b.number, undefined, { numeric: true });
    });

    const newUpperPartFields = sortedPfields.map((pfield, index) => ({
      id: upperPartFieldCounter + index + 1,
      Number: pfield.number,
      Title: pfield.name,
      Id: pfield.id,
      Type: "textInput",
    }));

    setUpperPartFieldCounter(upperPartFieldCounter + sortedPfields.length); // Increment counter
    setProtocolData((prevData) => ({
      ...prevData,
      upperPartFields: newUpperPartFields,
    }));
  }
}, [pfields, protocolData.election_system]);


  const getDataElections = async () => {
    const response = await getElections({});
    if (response.success) {
      setElections(response.data.elections);
    } else {
      setElections([]);
      alert(response.message);
    }
  };

  const getDataCandidates = async (id, ik_id) => {
    let payload = { "ik": { "ik_id": ik_id } };
    const response = await getCantidates(payload);
    if (response.success) {
      setCandidates(response.data.candidates);
    } else {
      setCandidates([]);
      alert(response.message);
    }
  };

  const getDataPfields = async () => {
    let payload = {
      "pfield": {
        "type_id": protocolData.protocol_type,
        "election_system": protocolData.election_system,
        "ik_id": protocolData.election_home,
      }
    };
    const response = await enumPfields(payload);
    if (response.success) {
      setPfields(response.data.pfields);
    } else {
      setPfields([]);
      alert(response.message);
    }
  };

  const getDataSubdivisions = async (id) => {
    let payload = { "el_subdivision": { "election_id": id } };
    const response = await enumSub(payload);
    if (response.success) {
      setSubdistricts(response.data.el_subdivisions);
    } else {
      setSubdistricts([]);
      alert(response.message);
    }
  };

  const getDataParties = async () => {
    const response = await getParties({});
    if (response.success) {
      setParties(response.data.parties);
    } else {
      setParties([]);
      alert(response.message);
    }
  };

  const steps = [
    "Данные протокола",
    "Заполнение верхней части протокола",
    "Заполнение нижней части протокола",
    "Итоговый вид протокола",
  ];

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleFinish = () => {
    onSubmit(protocolData);
  };


  const handleAddUpperPartField = () => {
    const newField = {
      id: upperPartFieldCounter + 1,
      Number: "", // Use the counter as a unique identifier
      Title: "",
      Id: null,
      Type: "textInput",
    };
    setUpperPartFieldCounter(upperPartFieldCounter + 1); // Increment the counter
    setProtocolData((prevData) => ({
      ...prevData,
      upperPartFields: [...prevData.upperPartFields, newField],
    }));
  };

  const handleAddLowerPartField = () => {
    const newField = {
      id: lowerPartFieldCounter + 1,
      Number: 0, // Use the counter as a unique identifier
      candidate_id: 0,
      Type: "textInput",
    };
    setLowerPartFieldCounter(lowerPartFieldCounter + 1); // Increment the counter
    setProtocolData((prevData) => ({
      ...prevData,
      lowerPartFields: [...prevData.lowerPartFields, newField],
    }));
  };

  //console.log("TEST PROTOCOL DATA", protocolData)

  const handleChangeQuestion = (event) => {
    const { name, checked } = event.target;
  
    // Обновляем состояние в зависимости от того, какой чекбокс был изменен
    if (name === 'is_parties') {
      setProtocolData({ ...protocolData, is_parties: checked, is_candidates: !checked });
    } else if (name === 'is_candidates') {
      setProtocolData({ ...protocolData, is_candidates: checked, is_parties: !checked });
    }
  };


  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField
              label="Название протокола"
              value={protocolData.protocol_name}
              onChange={(e) =>
                setProtocolData((prevData) => ({
                  ...prevData,
                  protocol_name: e.target.value,
                }))
              }
              fullWidth
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Выберите выборы</InputLabel>
              <Select
                name="election_id"
                value={protocolData.election_id}
                onChange={(e) =>
                  setProtocolData((prevData) => ({
                    ...prevData,
                    election_id: e.target.value,
                  }))
                }
              >
                {elections.map((election) => (
                  <MenuItem key={election.election_id} value={election.election_id}>
                    {election.election_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          {(protocolData.election_system == 2 || protocolData.election_system == 3 ) &&
            <>

          {protocolData.election_system == 3 &&
            <>
            <InputLabel>Кого выбираем?</InputLabel>
            <FormControlLabel
               control={
                  <Checkbox
                      checked={protocolData.is_parties}
                      name='is_parties'
                      onChange={handleChangeQuestion}
                      inputProps={{ "aria-label": "controlled" }}
                  />
                }
              label="Партии"
            />

            <FormControlLabel
               control={
                  <Checkbox
                      checked={protocolData.is_candidates}
                      name='is_candidates'
                      onChange={handleChangeQuestion}
                      inputProps={{ "aria-label": "controlled" }}
                  />
                }
              label="Кандидаты"
            />
            </>
              }

          {subdistricts && protocolData.is_candidates && !protocolData.is_parties &&
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel>Выберите округ</InputLabel>
              <Select
                name="sub_id"
                value={protocolData.sub_id}
                onChange={(e) =>
                  setProtocolData((prevData) => ({
                    ...prevData,
                    sub_id: e.target.value,
                  }))
                }
              >
                {subdistricts.map((sub) => (
                  <MenuItem key={sub.sel_id} value={sub.sel_id}>
                    {sub.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
              }
              </>
            }

            <FormControl fullWidth margin="normal">
              <InputLabel>Выберите ИК протокола</InputLabel>
              <Select
                name="protocol_type"
                value={protocolData.protocol_type}
                onChange={(e) =>
                  setProtocolData((prevData) => ({
                    ...prevData,
                    protocol_type: e.target.value,
                  }))
                }
              >
                  <MenuItem key={1} value={4}>
                    УИК
                  </MenuItem>
                  <MenuItem key={2} value={3}>
                    ТИК
                  </MenuItem>
                  <MenuItem key={3} value={2}>
                    РИК
                  </MenuItem>
                  <MenuItem key={4} value={1}>
                    ЦИК
                  </MenuItem>
              </Select>
            </FormControl>
          </>
        );
      case 1:
        return (
          <div>
            {/* Upper part fields */}
            {protocolData.upperPartFields.map((field) => (
              <div key={field.id}>
                <TextField
                  label={`Номер поля`}
                  value={field.Number}
                  style={{marginBottom: 10, marginTop: 10}}
                  onChange={(e) =>
                    handleUpperFieldChange(
                      field.id,
                      "Number",
                      e.target.value
                    )
                  }
                />
              <FormControl fullWidth margin="normal">
              <InputLabel>Название поля</InputLabel>
              <Select
                name="Id"
                value={field.Id || ''}
                onChange={(e) =>
                  handleUpperFieldChange(
                    field.id,
                    "Id",
                    e.target.value,
                    pfields.find((pField) => pField.id === e.target.value)?.name || ''
                  )
                }
              >
                {pfields.map((pField) => (
                  <MenuItem key={pField.id} value={pField.id}>
                    {pField.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

                <Button
                    variant="outlined"
                    color="error"
                    style={{marginBottom: 10, marginTop: 10}}
                    onClick={() => handleDeleteField(field.id, "upper")}
                  >
                    Удалить поле
                  </Button>
              </div>
            ))}
            <Button variant="outlined" color="success" style={{marginBottom: 10}} onClick={handleAddUpperPartField}>
              Добавить поле
            </Button>
          </div>
        );

      case 2:
        return (
          <div>
            {/* Lower part fields */}
            {protocolData.lowerPartFields.map((field) => (
              <div key={field.id}>
                <TextField
                  label={`Номер поля`}
                  value={field.Number}
                  style={{marginBottom: 5, marginTop: 15}}
                  onChange={(e) =>
                    handleLowerFieldChange(
                      field.id,
                      "Number",
                      e.target.value
                    )
                  }
                  fullWidth
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Выберите кандидата</InputLabel>
                  <Select
                    value={field.candidate_id}
                    onChange={(e) =>
                      handleLowerFieldChange(
                        field.id,
                        "candidate_id",
                        e.target.value,
                      )
                    }
                  >
                    {/* Render the list of candidates */}
                    {protocolData.is_candidates && candidates && candidates.map((candidate) => (
                      <MenuItem key={candidate.candidate_id} value={candidate.candidate_id}>
                        {candidate.candidate_name}
                      </MenuItem>
                    ))}

                   {protocolData.is_parties && parties && parties.map((parti) => (
                      <MenuItem key={parti.id} value={parti.id}>
                        {parti.short_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Button
                    variant="outlined"
                    color='error'
                    style={{marginBottom: 10, marginTop: 10}}
                    onClick={() => handleDeleteField(field.id, "lower")}
                  >
                    Удалить поле
                  </Button>
                </FormControl>
              </div>
            ))}
            <Button variant="outlined" color='success' style={{marginBottom: 10, marginTop: 10}} onClick={handleAddLowerPartField}>
              Добавить поле
            </Button>
          </div>
        );
      case 3:
        return (
          <div>
            {/* Summary */}
            <p>
              Тип выборов:{" "}
              {protocolData.elections_type}
            </p>
            <p>
              Выбранные выборы:{" "}
              {
                elections.find(
                  (election) => election.election_id === protocolData.election_id
                ).election_name
              }
            </p>
            <p>Протокол: {protocolData.protocol_name}</p>
            <p>Верхняя часть протокола:</p>
            {protocolData.upperPartFields.map((field) => (
              <p key={field.id}>
                Номер: {field.Number}, Title: {field.Title}, Type: {field.Type}
              </p>
            ))}
            <p>Нижняя часть протокола:</p>
            {protocolData.lowerPartFields.map((field) => (
              <p key={field.id}>
                Номер: {field.Number}, candidate_id: {field.candidate_id}, Type:{" "}
                {field.Type}
              </p>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  const handleUpperFieldChange = (fieldNumber, fieldName, value, title) => {
    setProtocolData((prevData) => ({
        ...prevData,
        upperPartFields: prevData.upperPartFields.map((field) =>
            field.id === fieldNumber ? { ...field, [fieldName]: value, ...(title && { Title: title }) } : field
        ),
    }));
 };

  const handleLowerFieldChange = (fieldNumber, fieldName, value) => {
    setProtocolData((prevData) => ({
      ...prevData,
      lowerPartFields: prevData.lowerPartFields.map((field) =>
        field.id === fieldNumber ? { ...field, [fieldName]: value } : field
      ),
    }));
  };

  const handleDeleteField = (fieldNumber, fieldType) => {
    const updatedFields =
      fieldType === 'upper'
        ? protocolData.upperPartFields.filter((field) => field.id !== fieldNumber)
        : protocolData.lowerPartFields.filter((field) => field.id !== fieldNumber);
  
    setProtocolData((prevData) => ({
      ...prevData,
      upperPartFields: fieldType === 'upper' ? updatedFields : prevData.upperPartFields,
      lowerPartFields: fieldType === 'lower' ? updatedFields : prevData.lowerPartFields,
    }));
  };

  return (
    <div>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            {renderStepContent(activeStep)}
            <Button variant="contained" color="primary" onClick={handleFinish}>
              Завершить создание протокола
            </Button>
          </div>
        ) : (
          <div>
            {renderStepContent(activeStep)}
            <div>
              <Button color="error" disabled={activeStep === 0} onClick={handleBack}>
                Назад
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Завершить" : "Далее"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddProtocolForm;
