// import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import FilterComponent from '../shared/FilterComponent';

const DataGridFilterCustom = ({ isSearch = true, additionalButton, filtersButtons, searchText, setSearchText, onClearSearch}) => {

    return (
        <Box sx={{ display: 'flex', marginBottom: 2, alignItems: 'center', width: '100%' }}>
          <FilterComponent
            isSearch={isSearch}
            styleSearch={{ width: '30%' }}
            placeholderSearch={'Поиск'}
            onChange={(e) => {
                const value = e.target.value;
                setSearchText(value);
                if (value === '') {
                    onClearSearch(); // Очистка сразу при пустом значении
                }
            }}
            onClickSearchClean={onClearSearch}
            searchString={searchText}
            styleContainer={{ height: 'auto' }}
            filtersButtons={filtersButtons}
            additionalButton={additionalButton}
          >
          </FilterComponent>
        </Box>
      );
};

// Заделка под фильтры
{/* <Box sx={{display: 'flex',width: 150, flexDirection: 'column', mr: 2, mb: 2}}>
                    <InputLabel style={{color: "#970D0D", fontSize: 12, fontWeight: 'bold'}} id="search-column-label">Поиск по</InputLabel>
                    <Select
                        labelId="search-column-label"
                        id="search-column"
                        value={searchColumn}
                        label="Колонка"
                        onChange={handleSearchColumnChange}
                        style={{height: 35, border: '1px solid black'}} 
                        sx={{alignContent: 'center', justifyContent: 'center', fontWeight: '600', fontSize: '14px !important', color: 'black'}} 
                    >
                        {columns.map((column) => (
                            <MenuItem style={{height: 30, width: '100%'}} key={column.field} value={column.field}>{column.headerName}</MenuItem>
                        ))}
                    </Select>
                </Box> */}

DataGridFilterCustom.propTypes = {
    isSearch: PropTypes.bool,
    setSearchText: PropTypes.func,
    onClearSearch: PropTypes.func,
    searchText: PropTypes.string,
    additionalButton: PropTypes.element,
    filtersButtons: PropTypes.element
};

export default DataGridFilterCustom;

 //<GridToolbarContainer sx={{padding: 2}}>
  //              <GridToolbarColumnsButton />
   //             <GridToolbarFilterButton />
    //            <GridToolbarDensitySelector />
    //            <GridToolbarExport />
     //       </GridToolbarContainer> 

     // import { 
//     GridToolbarColumnsButton, 
//     GridToolbarContainer, 
//     GridToolbarDensitySelector, 
//     GridToolbarExport, 
//     GridToolbarFilterButton 
// } from '@mui/x-data-grid';